import { Box, List, ListItem, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { nimbleTheme } from 'theme';

export const Answer = styled(Box)({
  fontSize: nimbleTheme.typography.print.fontSize,
  width: '100%',
});

export const AttachmentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: nimbleTheme.spacing(3),
});

export const BoldText = styled(Typography)({
  fontSize: nimbleTheme.typography.print.fontSize,
  fontWeight: nimbleTheme.typography.fontWeightBold,
});

export const BoldRoleText = styled(Typography)({
  fontSize: nimbleTheme.typography.body3.fontSize,
  fontWeight: nimbleTheme.typography.fontWeightBold,
});

export const CandidateName = styled(Typography)({
  fontWeight: nimbleTheme.typography.fontWeightBold,
});

export const Container = styled(Box)({
  color: nimbleTheme.palette.text.primary,
  maxWidth: '1120px',
  padding: nimbleTheme.spacing(4),
  paddingBottom: 0,
  fontSize: nimbleTheme.typography.print.fontSize,
});

export const DividerBox = styled(Box)({
  marginBottom: nimbleTheme.spacing(3),
  marginTop: nimbleTheme.spacing(3),
});

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderBottom = styled(Box)({
  fontSize: nimbleTheme.typography.print.fontSize,
  marginTop: nimbleTheme.spacing(0.5),
});

export const HeaderTop = styled(Box)({
  alignItems: 'end',
  display: 'flex',
  justifyContent: 'space-between',
});

export const Question = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  '.MuiTypography-root': {
    fontWeight: nimbleTheme.typography.fontWeightBold,
  },
});

export const QuestionText = styled(Typography)({
  fontSize: nimbleTheme.typography.print.fontSize,
});

export const RatingsBox = styled(Box)({
  paddingBottom: nimbleTheme.spacing(3),

  '&:nth-last-of-type(2)': {
    paddingBottom: 0,
  },
});

export const Recommendation = styled(Box)({
  display: 'flex',
});

export const RecommendBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

export const RubricList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  fontSize: nimbleTheme.typography.print.fontSize,
});

export interface RubricListItemProps {
  isSelected: boolean;
}

export const RubricListItem = styled(ListItem)<{ isSelected: boolean }>(({ isSelected }) => ({
  alignItems: 'start',
  border: isSelected ? `1px solid ${nimbleTheme.palette.success.medium}` : null,
  borderRadius: nimbleTheme.spacing(0.5),
  display: 'flex',
  padding: isSelected ? 0 : null,
  paddingLeft: !isSelected ? nimbleTheme.spacing(3) + 1 : null,
  left: isSelected ? `-${nimbleTheme.spacing(1)}` : null,
}));

export const FlexColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const RubricTitle = styled(Typography)({
  fontSize: nimbleTheme.typography.print.fontSize,
  fontWeight: nimbleTheme.typography.fontWeightBold,
});

export const Detail = styled(Box)({
  display: 'flex',
  fontSize: nimbleTheme.typography.print.fontSize,
});

export const StyledCheckIcon = styled(CheckIcon)({
  paddingRight: nimbleTheme.spacing(0.5),
  fill: nimbleTheme.palette.success.medium,
});

export const StyledListItem = styled(ListItem)({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: nimbleTheme.spacing(4),
  padding: 0,
});

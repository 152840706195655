import React, { useEffect } from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'assets/tooltip.svg';
import styled from 'styled-components';

import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { HIRING_SEASONS, YEARS } from 'utils/enums';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { useApplicantVolumeMetrics } from './useApplicantVolumeMetrics';
import { CHART_COLOR_PALETTES, DIVERSITY_CHOICES } from './constants';
import ChartHeaderTooltip, { ChartHeaderMailtoLink } from './components/ChartHeaderTooltip';

const getKeyForYear = year => {
  const yearString = year.toString();
  const entry = Object.entries(YEARS).find(([, value]) => value === yearString);
  return entry ? parseInt(entry[0], 10) : undefined;
};

const ApplicantVolumeWidget = ({ schools, subcategories }) => {
  const toggleState = Toggle.useToggleState();
  const diversityDropdownState = useChoiceDropdown({
    choices: DIVERSITY_CHOICES,
    initialSelectedChoiceIds: DIVERSITY_CHOICES.map(choice => choice.id),
  });

  const currentYear = new Date().getFullYear();
  const getCurrentYearKey = () => getKeyForYear(currentYear);

  const getYearChoicesDropdown = () => {
    const yearChoicesDropdown = Object.entries(YEARS)
      .filter(([key, label]) => parseInt(label) <= currentYear)
      .map(([key, label]) => ({
        id: parseInt(key),
        value: parseInt(key),
        label,
      }));
    return yearChoicesDropdown;
  };

  const yearChoicesDropdown = getYearChoicesDropdown();
  const allYearIds = yearChoicesDropdown.map(choice => choice.id);

  const yearDropdownState = useChoiceDropdown({
    choices: yearChoicesDropdown,
    initialSelectedChoiceIds: allYearIds,
  });

  let sortedYearsToBeShown = yearDropdownState.selectedChoiceIds.sort((a, b) => a - b);

  sortedYearsToBeShown =
    sortedYearsToBeShown.length === 0 ? Object.keys(YEARS) : sortedYearsToBeShown;

  const { data, isLoading } = useApplicantVolumeMetrics({
    hiringSeasons: [],
    years: sortedYearsToBeShown.map(key => YEARS[key]),
    schools,
    subcategories,
    genders: DIVERSITY_CHOICES.filter(choice => choice.group === 'gender')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
    ethnicities: DIVERSITY_CHOICES.filter(choice => choice.group === 'enthnicity')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
  });

  const dataset = toggleState.modeOneIsActive
    ? data.applications_by_month
    : data.candidates_by_month;

  const removeYearsWithNoApps = dataset => {
    return dataset.map(entry => {
      const years = Object.keys(entry).filter(key => !isNaN(key));
      const updatedEntry = { ...entry };

      years.forEach(year => {
        const yearValues = dataset.map(e => e[year]);
        const allZeros = yearValues.every(value => value === 0);

        if (allZeros) {
          delete updatedEntry[year];
        }
      });

      return updatedEntry;
    });
  };

  const dontShowDataInFuturePeriods = processedData => {
    return processedData.map(entry => {
      const entryYears = Object.keys(entry).filter(key => !isNaN(key)); // Get all year keys
      const updatedEntry = { ...entry };

      entryYears.forEach(year => {
        const yearInt = parseInt(year, 10);
        if ((yearInt === currentYear || yearInt > currentYear) && entry[year] === 0) {
          updatedEntry[year] = null;
        }
      });

      return updatedEntry;
    });
  };

  const processedData = dontShowDataInFuturePeriods(removeYearsWithNoApps(dataset));

  const diversityFilterIsOn =
    diversityDropdownState.selectedChoiceIds.length > 0 &&
    diversityDropdownState.selectedChoiceIds.length !== DIVERSITY_CHOICES.length;

  const notEnoughData =
    diversityFilterIsOn &&
    processedData.some(monthData =>
      Object.values(HIRING_SEASONS).some(season => monthData[season] <= 1)
    );

  const message = notEnoughData ? 'Not enough data. Check your filters and try again.' : null;

  const colors =
    CHART_COLOR_PALETTES[
      sortedYearsToBeShown.length <= 4 ? 4 : sortedYearsToBeShown.length <= 6 ? 6 : 8
    ];

  return (
    <DashboardWidget.Container>
      <DashboardWidget.Heading>
        Applicant Volume{' '}
        <ChartHeaderTooltip id="yearDropdownTooltip">
          <>
            This chart displays application activity over time across all hiring seasons.
            <br />
            We recently refreshed the look of this chart, <br />
            reach out to <ChartHeaderMailtoLink /> with any questions.
          </>
        </ChartHeaderTooltip>
      </DashboardWidget.Heading>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown
            label="Demographics"
            choices={DIVERSITY_CHOICES}
            {...diversityDropdownState}
          />
          <ChoiceDropdown label="Years" choices={yearChoicesDropdown} {...yearDropdownState} />
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={toggleState.modeOneIsActive}
                onClick={toggleState.setModeOneIsActive}
              >
                Apps
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={toggleState.modeTwoIsActive}
                onClick={toggleState.setModeTwoIsActive}
              >
                People
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <ResponsiveContainer width="99%" height="100%">
              <LineChart data={processedData} margin={{ right: 25, top: 10 }}>
                <CartesianGrid vertical={false} strokeWidth="0.5" />
                <XAxis dataKey="month_abbr" interval={0} tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip />
                {sortedYearsToBeShown.map((season, i) => {
                  const yearExists = processedData.some(
                    entry => entry[YEARS[season]] !== undefined
                  );
                  if (yearExists) {
                    return (
                      <Line
                        key={season}
                        dataKey={YEARS[season]}
                        name={YEARS[season]}
                        stroke={colors[i % sortedYearsToBeShown.length]}
                        strokeWidth={2}
                        dot={false}
                        type="monotone"
                        activeDot={{ strokeWidth: 3, r: 6 }}
                        connectNulls={false}
                      />
                    );
                  }
                })}
                <Legend />
              </LineChart>
            </ResponsiveContainer>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

export default ApplicantVolumeWidget;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

import { useState } from 'react';

import { ActionItemsContainer, FlexContainer, SwitchContainer, Count } from './styles';
import { Box } from '@mui/material';
import { ErrorSnackbar } from 'sharedComponents/Snackbar/ErrorSnackbar';
import { ItemsHeaderSectionProps } from './types';
import { PrimaryButton, SecondaryButton, TextButton } from 'sharedComponents/Buttons';
import { SearchBar } from 'sharedComponents/SearchBar/SearchBar';
import { SuccessSnackbar } from 'sharedComponents/Snackbar/SuccessSnackbar';
import auth from 'utils/auth';
import CreateJobOrTemplateModal from 'components/CreateJobOrTemplateModal';
import { useHistory } from 'react-router-dom';
import { ToggleButtons } from 'sharedComponents/Buttons/ToggleButtons';
import { ATSJobsListDataTestIds, ATSTemplatesListDataTestIds } from '../../data-testids/ATS';

export const ItemsHeaderSection: React.FC<ItemsHeaderSectionProps> = ({
  handleSetQuery,
  onSearch,
  onToggle,
  resetItems,
  totalCount,
  value,
  variant,
}) => {
  const [shouldShowJobModal, setShouldShowJobModal] = useState(false);
  const [shouldShowNetworkErrorAlert, setShouldShowNetworkErrorAlert] = useState(null);
  const [shouldShowSuccessAlert, setShouldShowSuccessAlert] = useState(null);
  const [shouldShowTemplatesModal, setShouldShowTemplatesModal] = useState(false);
  const history = useHistory();
  const isDistrictUser = auth.isDistrictUserOnly();
  const isSchoolAdmin = auth.isSchoolAdmin();
  const canPostJobs = auth.isDistrictAdmin() || auth.isSuperAdminUser();
  const canRequestJobs = isSchoolAdmin || isDistrictUser;

  const handleTemplatesModalOpen = () => setShouldShowTemplatesModal(true);
  const handleTemplatesModalClose = () => setShouldShowTemplatesModal(false);

  const handleSetNetworkErrorAlert = () => setShouldShowNetworkErrorAlert(true);
  const handleSetWasSuccessful = () => setShouldShowSuccessAlert(true);

  const handleJobModalOpen = () => setShouldShowJobModal(true);
  const handleJobModalClose = () => setShouldShowJobModal(false);

  const handleErrorClose = reason => {
    if (reason === 'clickaway') {
      return;
    }

    setShouldShowNetworkErrorAlert(false);
  };

  const handleSuccessClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShouldShowSuccessAlert(false);
  };

  const urlPrefix = auth.isSchoolUser() ? 'school' : isDistrictUser ? 'districtuser' : 'district';

  const handlePrimaryButtonClick = () => {
    if (auth.isDistrictAdmin() || auth.isSuperAdminUser()) {
      handleJobModalOpen();
    } else {
      history.push(`/${urlPrefix}/jobcreate`);
    }
  };

  const countText =
    variant === 'templates'
      ? `${totalCount} Template${totalCount > 1 ? 's' : ''}`
      : `${totalCount} Job${totalCount > 1 ? 's' : ''}`;

  return (
    <>
      <FlexContainer>
        <SwitchContainer>
          {canPostJobs && (
            <ToggleButtons
              onToggle={onToggle}
              options={[
                { title: 'Jobs', href: '/district/jobslist' },
                { title: 'Templates', href: '/district/templates' },
              ]}
              currentlySelected={variant === 'templates' ? 'Templates' : 'Jobs'}
            />
          )}
          <Count
            data-testid={
              variant === 'templates' ? 'templates-count' : ATSJobsListDataTestIds.COUNT_TEXT
            }
          >
            {countText}
          </Count>
        </SwitchContainer>
        <ActionItemsContainer>
          <TextButton onClick={resetItems} size="large">
            Clear All Filters
          </TextButton>
          {canPostJobs && (
            <SecondaryButton size="large" onClick={handleTemplatesModalOpen}>
              + New Template
            </SecondaryButton>
          )}
          <PrimaryButton
            // Used in intercom product tours
            onClick={handlePrimaryButtonClick}
            size="large"
            dataTestId={ATSJobsListDataTestIds.NEW_JOB_BUTTON}
          >
            {canRequestJobs ? '+ Request Job' : '+ New Job'}
          </PrimaryButton>
        </ActionItemsContainer>

        {shouldShowTemplatesModal && (
          <CreateJobOrTemplateModal
            handleModalClose={handleTemplatesModalClose}
            handleSetNetworkErrorAlert={handleSetNetworkErrorAlert}
            handleSetWasSuccessful={handleSetWasSuccessful}
            shouldShowModal={shouldShowTemplatesModal}
            variant="template"
          />
        )}
        {shouldShowJobModal && (
          <CreateJobOrTemplateModal
            handleModalClose={handleJobModalClose}
            handleSetNetworkErrorAlert={handleSetNetworkErrorAlert}
            handleSetWasSuccessful={handleSetWasSuccessful}
            shouldShowModal={shouldShowJobModal}
            variant="job"
          />
        )}
        <SuccessSnackbar
          message="Successfully created"
          onClose={handleSuccessClose}
          open={shouldShowSuccessAlert}
        />
        <ErrorSnackbar
          message="Something went wrong"
          onClose={handleErrorClose}
          open={shouldShowNetworkErrorAlert}
        />
      </FlexContainer>

      <Box sx={{ marginTop: '22px', marginBottom: '35px' }}>
        <SearchBar
          onInputChange={handleSetQuery}
          placeholder={variant === 'templates' ? 'Search Templates' : 'Search Jobs'}
          value={value}
          onSearch={onSearch}
          dataTestId={
            variant === 'jobs'
              ? ATSJobsListDataTestIds.SEARCH_INPUT
              : ATSTemplatesListDataTestIds.SEARCH_INPUT
          }
        />
      </Box>
    </>
  );
};

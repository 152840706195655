import './components/browsermodal.css';

import axios from 'axios';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Notifications from 'react-notify-toast';
import { theme } from 'ui-kit';
import BrowserModal from './components/browsermodal';
import Footer from './components/footer';
import Navbar from './components/NavBar/navbar';
import { NavbarV2 } from './components/NavBar/NavbarV2';
import { initSegment } from './utils/segment';
// import utils and check for IE
import { ConnectAlertsBanner } from 'features/MarketplaceV2/components/ConnectAlertsBanner/ConnectAlertsBanner';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import { useRouteMatch } from 'react-router-dom';
import { startRecurringAuthCheck } from './utils/authutils';
import { getInternetExplorerVersion, isChrome } from './utils/util';
interface LayoutProps {
  children: React.ReactNode;
}

type SetNotFoundContextValue = () => () => void;

const SetNotFoundContext = createContext<SetNotFoundContextValue>(() => () => {});

export const useSetNotFound = () => useContext(SetNotFoundContext);

declare global {
  interface Analytics {
    page: () => void;
  }

  interface Window {
    analytics: Analytics;
  }
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [showBrowserModal, setShowBrowserModal] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const { userType, hasApplications } = useCurrentUserPermissions();

  const handleSetNotFound = useCallback(() => {
    setNotFound(true);

    return () => {
      setNotFound(false);
    };
  }, []);

  const jobMatch = useRouteMatch('/jobs/');
  const onLoginPage = useRouteMatch('/login');
  const shouldRenderNavBar = !jobMatch && !notFound && !onLoginPage;

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const saveToLocalStorage = () => {
      window.localStorage.setItem('nimble_seen_modal', 'true');
    };

    const checkForLocalStorageFormData = () => {
      if (!window.localStorage.getItem('nimble_seen_modal')) {
        setShowBrowserModal(true);
        saveToLocalStorage();
      }
    };

    const setHelloSignVariable = value => {
      window.localStorage.setItem('hellosign_setting', value);
    };

    // Check for IE and add class
    if (getInternetExplorerVersion() !== -1) {
      document.body.classList.add('ie');
    }
    if (!isChrome()) {
      checkForLocalStorageFormData();
    }

    let isUnmounted = false;

    axios.get('/api/config/').then(r => {
      if (!isUnmounted && r.data.SEGMENT_KEY) {
        initSegment(r.data.SEGMENT_KEY);
      }
      setHelloSignVariable(r.data.HELLOSIGN_SETTING);
    });

    startRecurringAuthCheck();

    return () => {
      isUnmounted = true;
    };
  }, []);

  const atConnectRoute = useRouteMatch('/connect');
  const atDistrictJobsList = useRouteMatch('/district/jobslist');
  const atSchoolJobsList = useRouteMatch('/school/jobslist');
  const jobsDiscoveryConnect = useRouteMatch({ path: '/connect', exact: true });
  const shouldNotShowFooter = atDistrictJobsList || atSchoolJobsList;

  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      {jobsDiscoveryConnect && <ConnectAlertsBanner />}
      {atConnectRoute && <NavbarV2 />}
      {!atConnectRoute && (
        <Navbar
          show={shouldRenderNavBar}
          onProspectList={window.location.href.indexOf('prospectlist') !== -1}
          onProspectEntry={window.location.href.indexOf('prospectentry') !== -1}
          userType={userType}
          hasApplications={hasApplications}
        />
      )}

      <SetNotFoundContext.Provider value={handleSetNotFound}>
        {children}
      </SetNotFoundContext.Provider>
      <BrowserModal show={showBrowserModal} onHide={() => setShowBrowserModal(false)} />
      {!shouldNotShowFooter && <Footer />}
    </ThemeProvider>
  );
};

export default Layout;

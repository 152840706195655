import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import HelpSection from 'components/Login/HelpSection/index';
import LoginForm from 'components/Login/LoginForm';
import auth from 'utils/auth';
import { user_type } from 'utils/enums';

import { NimbleLogoDarkText } from 'ui-kit/icons';

import FeatureContext from 'context/featureContext';
import { useHistory, useLocation } from 'react-router-dom';
import { nimbleConnectUrlFlag } from 'utils/constants';
import { useSetUser } from './CurrentUserPermissionsProvider';
import { AccountErrors } from 'sharedComponents/Error/constants';

const USERTYPE = user_type().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

function LoginContainer(props) {
  /**
   * Whether or not the underlying component has initiated a send. In the case of a production bug,
   * e.g. https://goo.gl/WPKFZX, the underlying form may lose it's submission handler and leak
   * all the info (PW included!) to the query string. So its up to us to vanish the form before
   * that might happen.
   */
  const [redirected, setRedirected] = useState(false);
  const featureContext = useContext(FeatureContext);
  const history = useHistory();
  const { search, state } = useLocation();
  const searchParams = new URLSearchParams(search);
  const returnTo = searchParams.get('return_to');
  const internal = searchParams.get('internal');
  const nimbleConnectSchoolId = searchParams.get('school');
  const vacancyId = searchParams.get('vacancy');
  const setUser = useSetUser();

  useEffect(() => {
    document.body.className += ` login-page`;
    document.body.className += ` auth-pages`;

    return () => {
      // Revert to prior classes
      document.body.classList.remove('login-page');
      document.body.classList.remove('auth-pages');
    };
  }, []);

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      auth.login(email, password).then(user => {
        if (!auth.loggedIn()) {
          reject(AccountErrors.LOGIN_ERROR);
        }
        if (auth.loggedIn()) {
          resolve('Authenticated.');
          setUser(user);
          redirect(user);
        }
      });
    });
  };

  const register = (fname, lname, district, email, password) => {
    return new Promise((resolve, reject) => {
      const isNimbleConnectSignup = !!returnTo?.includes(nimbleConnectUrlFlag);
      // if registering from this endpoint, agree TOS will be implicit in "Sign Up" event (implemented 02/2023)
      const agreeTOS = true;
      // if registering from this screen, usertype will be set to 'candidate';
      auth
        .register(
          fname,
          lname,
          district,
          email,
          password,
          USERTYPE.candidate,
          agreeTOS,
          isNimbleConnectSignup
        )
        .then(user => {
          if (!auth.loggedIn()) {
            reject(AccountErrors.ACCOUNT_ALREADY_EXISTS);
          }
          if (auth.loggedIn()) {
            setUser(user);
            resolve('User created.');
            redirect(user);
          }
        })
        .catch(error => {
          if (error.response?.data?.password) {
            let password_error_messages = error.response.data.password
              .join(' ')
              .replace(/username/gi, 'email');

            reject(password_error_messages);
          } else if (error.response?.data === 'Invalid registration') {
            reject(AccountErrors.ACCOUNT_ALREADY_EXISTS);
          } else {
            reject(AccountErrors.SIGNUP_ERROR);
          }
        });
    });
  };

  const googleSignin = id_token => {
    return new Promise((resolve, reject) => {
      auth.logInWithGoogle(id_token).then(user => {
        if (!auth.loggedIn()) {
          reject(AccountErrors.ACCOUNT_ALREADY_EXISTS);
        }
        if (auth.loggedIn()) {
          setUser(user);
          resolve('User created.');
          redirect(user);
        }
      });
    });
  };

  const azureLogin = e => {
    e && e.preventDefault();

    window.location = `${window.location.protocol}//${
      window.location.host
    }${'/api/auth/azure/login/'}`;
  };

  const redirect = user => {
    if (user?.profile === undefined) {
      return;
    }

    if (user && Array.isArray(user.enabled_features)) {
      featureContext.setFeatures([...(user?.enabled_features || [])]);
    }

    if (state?.previousLocation) {
      const previousLocation = state.previousLocation;
      history.push(previousLocation);
      return;
    }

    let homepage = '/';
    switch (user.profile.user_type) {
      case USERTYPE.candidate:
        if (user.has_applications) {
          homepage = '/candidate-dashboard';
        } else {
          homepage = '/preferences';
        }
        break;
      case USERTYPE.school_admin:
      case USERTYPE.district_user:
        if (!user.is_legacy_user && user.checklist && !user.checklist.completed) {
          // redirect new school admins to getting started checklist
          homepage = '/getting-started';
        } else {
          homepage = '/school/home';
        }
        break;
      case USERTYPE.district_admin:
      case USERTYPE.super_admin:
        if (!user.is_legacy_user && user.checklist && !user.checklist.completed) {
          // redirect new district admins to getting started checklist
          homepage = '/getting-started';
        } else if (user.profile.district.posting_only) {
          // admins in posting_only districts should be redirected to jobs page on login
          homepage = '/district/jobslist';
        } else {
          homepage = '/district/home';
        }
        break;
      default:
        homepage = '/';
        break;
    }

    setRedirected(true);

    if (internal) {
      // ensure internal candidates are redirected to the internal jobview
      const separator = returnTo.includes('?') ? '&' : '?';
      const updatedReturnTo = returnTo + separator + 'internal=' + encodeURIComponent(internal);
      history.push(updatedReturnTo);
    } else {
      if (returnTo === '/candidate-dashboard' && homepage === '/preferences') {
        history.push(homepage);
      } else if (returnTo && nimbleConnectSchoolId) {
        let returnToUrl = returnTo + '?school=' + nimbleConnectSchoolId;

        if (vacancyId) {
          returnToUrl += '&vacancy=' + vacancyId;
        }
        history.push(returnToUrl);
      } else {
        history.push(returnTo || homepage);
      }
    }
  };

  return (
    !redirected && (
      <div className="App-container">
        <Container>
          <StyledLoginContainer className="login-container">
            <div className="login-page-nimble-logo-container">
              <NimbleLogoDarkText width="165px" height="77px" />
            </div>
            <div className="App-container__content">
              <LoginForm
                onLogin={login}
                onRegister={register}
                googleSignin={googleSignin}
                azureLogin={azureLogin}
              />
            </div>
            <CopyrightText>&copy; 2021 Nimble Hiring, PBC, All Rights Reserved</CopyrightText>
          </StyledLoginContainer>
        </Container>
        <HelpSection />
      </div>
    )
  );
}

export default LoginContainer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoginContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
`;

const CopyrightText = styled.div`
  display: none;
  position: absolute;
  bottom: -28px;
  font-size: 11px;
  line-height: 23px;
  text-align: center;

  color: rgba(24, 26, 33, 0.5);

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

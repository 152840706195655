import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Typography } from '@mui/material';
import { calculateStatusColor, getStatusText } from 'components/CreateJobOrTemplateModal/utils';
import { ComboBox } from 'sharedComponents/ComboBox';
import { constructSchoolroleFromMergedRole } from 'utils/roleutils';
import {
  CloseButton,
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from 'sharedComponents/Buttons';
import { ErrorText, Right, Title } from 'components/CreateJobOrTemplateModal';
import { MergeRoleModalProps, RoleMergingInto, SearchResults } from './types';
import { Modal } from 'sharedComponents/Modal';
import { sortJobSearchResults } from 'components/CreateJobOrTemplateModal/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RolesAPI from 'api/rolesAPI';
import roleSearchAPI from 'api/roleSearchAPI';
import SchoolrolesAPI from 'api/schoolrolesAPI';
import schoolsAPI from 'api/schoolsAPI';
import { ATSNewMergeJobModalTestIds } from 'data-testids/ATS';

export const MergeRoleModal: React.FC<MergeRoleModalProps> = ({
  onHide,
  shouldShow,
  roleBeingMerged,
}) => {
  const [searchResults, setSearchResults] = useState<SearchResults>({
    state: 'idle',
    errorMessage: null,
    loading: false,
    data: [],
  });
  const [inputValue, setInputValue] = useState('');
  const [isMergeInProgress, setIsMergeInProgress] = useState(false);
  const [isOnSuccessPage, setIsOnSuccessPage] = useState(false);
  const [roleMergingInto, setRoleMergingInto] = useState<RoleMergingInto>({ title: null });
  const history = useHistory();

  useEffect(() => {
    let isUnmounted = false;

    if (inputValue) {
      const timeoutId = setTimeout(() => {
        setSearchResults({
          state: 'loading',
          errorMessage: null,
          loading: true,
          data: [],
        });

        setIsMergeInProgress(false);

        roleSearchAPI
          .searchDistrictRoles(inputValue)
          .then(roles => {
            if (isUnmounted) return;
            const sorted = sortJobSearchResults(roles.results);

            setSearchResults({
              state: 'loaded',
              errorMessage: null,
              loading: false,
              data: sorted,
            });
          })
          .catch(error => {
            if (isUnmounted) return;

            console.log(error);
            setSearchResults({
              state: 'error',
              errorMessage: 'Something went wrong. Search did not work.',
              loading: false,
              data: [],
            });
          });
      }, 250);

      return () => clearTimeout(timeoutId);
    }

    return () => {
      isUnmounted = true;
    };
  }, [inputValue]);

  const handleRoleSelection = value => {
    if (value === null) return;

    const roleMergingInto: RoleMergingInto = searchResults.data.find(
      result => result.id === value.id
    );

    if (roleMergingInto) {
      setRoleMergingInto(roleMergingInto);
    } else {
      setRoleMergingInto({ title: null });
    }

    setSearchResults({
      state: 'loading',
      errorMessage: null,
      loading: true,
      data: [],
    });
  };

  const handleErrorMergingRoles = errorResponse => {
    if (errorResponse.data?.detail) {
      setSearchResults({
        state: 'error',
        errorMessage: errorResponse.data.detail,
        loading: false,
        data: [],
      });
    } else {
      setSearchResults({
        state: 'error',
        errorMessage: 'Something went wrong. Role was unable to be merged.',
        loading: false,
        data: [],
      });
    }
  };

  const onSaveClick = () => {
    if (!roleMergingInto.title) {
      setSearchResults({
        state: 'error',
        errorMessage: 'Please select a job.',
        loading: false,
        data: [],
      });
      return;
    }

    setIsMergeInProgress(true);

    const schoolId = roleBeingMerged?.school;
    const updatedRoleBeingMerged = { ...roleBeingMerged };

    if (typeof schoolId === 'number') {
      schoolsAPI.fetchSchool(schoolId).then(response => {
        updatedRoleBeingMerged.school = response;
      });
    }

    RolesAPI.mergeRole(updatedRoleBeingMerged, roleMergingInto)
      .then(response => {
        if (response !== 'Role merged.') {
          const newSchoolRole = constructSchoolroleFromMergedRole(response, updatedRoleBeingMerged);

          SchoolrolesAPI.createSchoolrole(response.id, newSchoolRole)
            .then(() => {
              setIsMergeInProgress(false);
              setIsOnSuccessPage(true);
            })
            .catch(() => {
              setSearchResults({
                state: 'error',
                errorMessage:
                  'Something went wrong when creating a school role. School role was unable to be created.',
                loading: false,
                data: [],
              });

              return;
            });
        }

        setIsMergeInProgress(false);
        setIsOnSuccessPage(true);
      })
      .catch(error => {
        handleErrorMergingRoles(error.response);
      });
  };

  const handleJobEditClick = () => {
    onHide();
    history.push(`/district/jobedit/${roleMergingInto.id}`);
  };

  const handleBackClick = () => {
    onHide();
    history.push(`/district/jobslist`);
  };

  const handleSetSearchInputValue = text => {
    setInputValue(text);

    setSearchResults({
      state: 'loading',
      errorMessage: null,
      loading: true,
      data: [],
    });
  };

  const errorMessage = searchResults.state === 'error' ? searchResults.errorMessage : null;

  const getCTAButtons = () => {
    if (!isOnSuccessPage) {
      return (
        <CTAContainer>
          <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>

          {isMergeInProgress && !errorMessage ? (
            <LoadingButton sx={{ height: '20px !important', width: '20px !important' }}>
              Merge Job Request
            </LoadingButton>
          ) : (
            <PrimaryButton disabled={Boolean(errorMessage)} onClick={onSaveClick}>
              Merge Job Request
            </PrimaryButton>
          )}
        </CTAContainer>
      );
    } else {
      return (
        <CTAContainer>
          <SecondaryButton sx={{ marginRight: '8px' }} onClick={handleBackClick}>
            Back to job listings
          </SecondaryButton>
          <PrimaryButton onClick={handleJobEditClick}>
            Continue to edit {roleMergingInto.title}
          </PrimaryButton>
        </CTAContainer>
      );
    }
  };

  const handleClickOutside = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason) => {
    if (reason === 'backdropClick' && isOnSuccessPage) return;

    onHide();
  };

  return (
    <Modal
      closeButton={<>{!isOnSuccessPage && <CloseButton onClick={onHide} />}</>}
      ctaButtons={getCTAButtons()}
      onClose={handleClickOutside}
      open={shouldShow}
      title={isOnSuccessPage ? '' : 'Merge Job Request'}
    >
      {isOnSuccessPage ? (
        <SuccessContainer>
          <SuccessMessaging>
            <CheckCircleIcon color="primary" />
            <Typography variant="h5" sx={{ paddingLeft: '8px' }}>
              Success!
            </Typography>
          </SuccessMessaging>
          <Box style={{ paddingTop: '8px' }}>
            {roleBeingMerged?.title} has been merged into {roleMergingInto?.title}.
          </Box>
        </SuccessContainer>
      ) : (
        <ComboBox
          dataTestId={ATSNewMergeJobModalTestIds.COMBO_BOX}
          getOptionLabel={option => option.title || ''}
          handleSetSearchInputValue={handleSetSearchInputValue}
          inputValue={inputValue}
          loading={searchResults.state === 'loading'}
          onChange={handleRoleSelection}
          options={searchResults.data}
          placeholder="Type or select job to merge this request into"
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              sx={{
                '.status': {
                  color: calculateStatusColor(option.status, option.active_status),
                },
                display: 'flex',
              }}
              key={option.id}
            >
              <Title>{option.title}</Title>
              <Right className="status">{getStatusText(option.status, option.active_status)}</Right>
            </Box>
          )}
        />
      )}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      {!isOnSuccessPage && (
        <HelpText>
          Note: This job request will take on characteristics of the job it is being merged into.
          Only internal notes will be saved.
        </HelpText>
      )}
    </Modal>
  );
};

const CTAContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '40px',
});

const HelpText = styled(Box)({
  paddingTop: '24px',
});

const SuccessContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SuccessMessaging = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

import React, { useEffect } from 'react';
import useSWR from 'swr';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import {
  BoldText,
  BoldRoleText,
  CandidateName,
  Container,
  DividerBox,
  Header,
  HeaderBottom,
  HeaderTop,
  Recommendation,
  RecommendBox,
  Detail,
} from './styles';
import { useTheme } from '@mui/material';
import { finalRecommendation } from 'utils/enums';
import applicationsAPI from 'api/applicationsAPI';
import Divider from '@mui/material/Divider';
import LoadingSpinner from 'components/loadingSpinner';
import scorecardAPI from 'api/scorecardAPI';
import { Typography } from '@mui/material';
import usersAPI from 'api/usersAPI';
import { ReviewerQuestionsAndAnswers } from './ReviewerQuestionsAndAnswers';

export const PrintDivider = () => {
  return (
    <DividerBox>
      <Divider sx={{ borderColor: 'black !important' }} />
    </DividerBox>
  );
};

export default function ScorecardPrintView(props) {
  const routeMatch = useRouteMatch('/view-scorecard/:id');
  const scorecardId = routeMatch?.params.id;
  const theme = useTheme();

  const SCORECARDS_KEY = `/api/scorecards/${scorecardId}`;
  const { data: scorecardData } = useSWR(SCORECARDS_KEY, () => scorecardAPI.fetch(scorecardId));

  const APPLICATION_KEY = `/api/applications/${scorecardData?.application}/`;
  const { data: jobData } = useSWR(scorecardData ? APPLICATION_KEY : null, () =>
    applicationsAPI.getApplication(scorecardData?.application)
  );

  const USER_KEY = `/api/user/${jobData?.candidate}`;
  const { data: candidateData } = useSWR(jobData ? USER_KEY : null, () =>
    usersAPI.fetchUser(jobData?.candidate)
  );

  const dataIsLoading =
    scorecardData === undefined || candidateData === undefined || jobData === undefined;

  useEffect(() => {
    if (!dataIsLoading) {
      // setTimeout ensures the content is fully rendered before opening
      // the print window
      setTimeout(window.print, 100);
    }
  }, [dataIsLoading]);

  if (dataIsLoading) {
    return <LoadingSpinner />;
  }

  if (!scorecardId) {
    throw new Error('Expected a scorecard but was not given one');
  }

  const finalRecommendationToUse = finalRecommendation.find(
    recommendation => recommendation.value === scorecardData.final_recommendation
  );
  const shouldShowCumulativeScore =
    scorecardData.include_cumulative_score && scorecardData.cumulative_score;

  const shouldShowRecommendationBox = finalRecommendationToUse || shouldShowCumulativeScore;

  return (
    <Container>
      <Header>
        <HeaderTop>
          <CandidateName variant="body1"> {candidateData?.name}</CandidateName>
          {shouldShowRecommendationBox && (
            <Recommendation>
              {finalRecommendationToUse?.label && (
                <RecommendBox sx={{ paddingRight: theme.spacing(2) }}>
                  Recommend?: <BoldText>{finalRecommendationToUse?.label}</BoldText>
                </RecommendBox>
              )}
              {shouldShowCumulativeScore && (
                <RecommendBox>
                  Cumulative score:<BoldText>{scorecardData.cumulative_score}</BoldText>
                </RecommendBox>
              )}
            </Recommendation>
          )}
        </HeaderTop>

        <HeaderBottom>
          <Detail>
            <BoldRoleText>Role:</BoldRoleText> &nbsp;{' '}
            <Typography sx={{ fontSize: theme.typography.body3.fontSize }}>
              {jobData?.role.title}
            </Typography>
          </Detail>

          <Detail>
            <BoldText>Reviewed by</BoldText>: {scorecardData.submitted_by_name},&nbsp;
            {moment(scorecardData.created).format('L')}
          </Detail>

          <Detail>
            <BoldText>Scorecard template</BoldText>: {scorecardData.template_name}
          </Detail>
        </HeaderBottom>
      </Header>

      <PrintDivider />

      <ReviewerQuestionsAndAnswers data={scorecardData} />
    </Container>
  );
}

let intervalId = 0;

// Check that user is authenticated every 10 minutes. If not, send to '/login'.
export function startRecurringAuthCheck() {
  intervalId = setInterval(authCheck, 600000);
}

export function endRecurringAuthCheck() {
  clearInterval(intervalId);
}

function authCheck() {
  if (currentPageRequiresAuth()) {
    let authUserPromise = new Promise(resolve => {
      const asyncCheck = async () => {
        const { default: auth } = await import('./auth');
        resolve(auth.checkAuthenticated());
      };
      asyncCheck();
    });
    authUserPromise.then(user => {
      if (isLoggedOut(user)) {
        redirectToLogin();
        endRecurringAuthCheck();
      }
    });
  }
}

function currentPageRequiresAuth() {
  // If user is filling out a reference or scorecard, or is on external jobslist,
  // jobview page, or login page, auth is not required.
  return (
    window.location.href.indexOf('/scorecard') === -1 &&
    window.location.href.indexOf('/reference') === -1 &&
    window.location.href.indexOf('/jobs/') === -1 &&
    window.location.href.indexOf('/jobboard/') === -1 &&
    window.location.href.indexOf('/jobview/') === -1 &&
    window.location.href.indexOf('/j/') === -1 &&
    window.location.href.indexOf('/login') === -1 &&
    window.location.href.indexOf('/password_reset') === -1 &&
    window.location.href.indexOf('/connect') === -1
  );
}

function isLoggedOut(user) {
  return !isLoggedIn(user);
}

function isLoggedIn(user) {
  return user.hasOwnProperty('id');
}

function redirectToLogin() {
  window.location.href = '/login';
}

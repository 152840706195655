import _ from 'lodash';
import PendingIcon from '../assets/icons/ReferenceIcons/yellow_clock.svg';
import NotStartedIcon from '../assets/icons/ReferenceIcons/red_x.svg';
import CompletedIcon from '../assets/icons/ReferenceIcons/green_check.svg';

import theme from '../ui-kit/theme'; // Needs to be imported directly
import { nimbleTheme } from 'theme';

import {
  DirectionTextIcon,
  RubricIcon,
  TextIcon,
  RatingIcon,
  YesNoIcon,
  NimbleLogoBlack,
  MultipleChoiceIcon,
  AttachmentIcon,
  CumulativeScoreIcon,
} from 'ui-kit/icons';
import { useTheme } from '@mui/material';

export {
  userTypeToId,
  roleStatusesForJobViewV2,
  roleStatusByValue,
  activeValueByActiveStatus,
} from './typedEnums';

export const finalRecommendation = [
  { label: 'Yes', value: 'final_recommendation/yes', color: '#00B88D' },
  { label: 'Maybe', value: 'final_recommendation/maybe', color: '#FFA601' },
  { label: 'No', value: 'final_recommendation/no', color: '#EF5675' },
];

// enums/reference_contact_type.py
export const refContactType = {
  none: 0,
  phone: 1,
  email: 2,
};
export const refContactTypeLabel = {
  0: 'None',
  1: 'Phone',
  2: 'Email',
};

// events/models/enums/eventtype.py
export const eventType = () => [
  { value: 0, key: 'profile_created', label: 'Profile Created' },
  { value: 1, key: 'application_created', label: 'Application Created' },
  { value: 3, key: 'status_update', label: 'Status Update' },
  { value: 4, key: 'interview_scheduled', label: 'Interview Scheduled' },
  { value: 5, key: 'scorecard_updated', label: 'Scorecard Updated' },
  { value: 6, key: 'candidate_emailed', label: 'Candidate Emailed' },
  { value: 7, key: 'profile_internal_external', label: 'Profile Internal/External' },
  { value: 8, key: 'task_completed', label: 'Task Completed' },
  { value: 9, key: 'calendar_integration', label: 'Calendar Integration' },
  { value: 10, key: 'prospect_added', label: 'Prospect Added' },
  {
    value: 11,
    key: 'application_attachment_added',
    label: 'Application Attachment Added',
  },
  { value: 12, key: 'internal_note_added', label: 'Internal Note Added' },
  { value: 13, key: 'task_assigned', label: 'Task Assigned' },
  { value: 14, key: 'application_copied', label: 'Application Copied' },
  {
    value: 15,
    key: 'profile_updated_by_candidate',
    label: 'Profile Updated by Candidate',
  },
  { value: 16, key: 'hellosign_form_sent', label: 'Form Sent' },
  { value: 17, key: 'school_application_status_update', label: 'School Application Update' },
  { value: 18, key: 'reference_request', label: 'Reference Request' },
  { value: 19, key: 'reference_completed', label: 'Reference Complete' },
  { value: 20, key: 'school_visibility_changed', label: 'School Visibility Update' },
  { value: 25, key: 'source_change', label: 'Source Change' },
  { value: 26, key: 'application_attachment_deleted', label: 'Candidate Removed' },
  {
    value: 27,
    key: 'application_attachment_replaced',
    label: 'Candidate replaced a required document',
  },
];

export const eventTypeMapping = () => [
  { value: 0, key: 'applications', label: 'Applications', eventTypes: [0, 2, 7, 11, 15, 26, 27] },
  { value: 1, key: 'emails', label: 'Emails', eventTypes: [6, 7, 8] },
  { value: 2, key: 'events', label: 'Events', eventTypes: [6] },
  { value: 3, key: 'notes', label: 'Notes', eventTypes: [12] },
  { value: 4, key: 'references', label: 'References', eventTypes: [18, 19, 23, 24] },
  { value: 5, key: 'statuses', label: 'Statuses', eventTypes: [1, 3, 10, 14, 17, 20] },
  { value: 6, key: 'tasks', label: 'Tasks', eventTypes: [8, 13] },
  { value: 7, key: 'forms', label: 'Forms', eventTypes: [16] },
  { value: 8, key: 'scorecards', label: 'Scorecards', eventTypes: [5] },
  { value: 9, key: 'interviews', label: 'Interviews', eventTypes: [4, 9] },
  { value: 10, key: 'source', label: 'Candidate Source', eventTypes: [25] },
];

// enums/degrees.py
export const degreeField = () => [
  { value: 0, label: 'No Degree' },
  { value: 1, label: 'High School' },
  { value: 2, label: 'Associates (AA/AS)' },
  { value: 3, label: 'Bachelors (BA/BS)' },
  { value: 4, label: 'Masters (MA/MS/MED)' },
  { value: 5, label: 'EdS/EdD/PhD' },
  // {value: 6, label: 'Credential'},
  { value: 7, label: 'Certificate' },
  { value: 8, label: 'Other' },
];

// enums/grades.py
export const grades = () => [
  { value: 0, label: 'Early Childhood', key: 'early_childhood' },
  { value: 1, label: 'First Grade', key: 'first_grade' },
  { value: 2, label: 'Second Grade', key: 'second_grade' },
  { value: 3, label: 'Third Grade', key: 'third_grade' },
  { value: 4, label: 'Fourth Grade', key: 'fourth_grade' },
  { value: 5, label: 'Fifth Grade', key: 'fifth_grade' },
  { value: 6, label: 'Sixth Grade', key: 'sixth_grade' },
  { value: 7, label: 'Seventh Grade', key: 'seventh_grade' },
  { value: 8, label: 'Eighth Grade', key: 'eighth_grade' },
  { value: 9, label: 'Ninth Grade', key: 'ninth_grade' },
  { value: 10, label: 'Tenth Grade', key: 'tenth_grade' },
  { value: 11, label: 'Eleventh Grade', key: 'eleventh_grade' },
  { value: 12, label: 'Twelfth Grade', key: 'twelfth_grade' },
  { value: 13, label: 'Kindergarten', key: 'kindergarten' },
];

export const early_childhood_grades = () => [
  { value: 0, label: 'Early Childhood', key: 'early_childhood' },
];

export const gradeMapping = () => {
  return {
    early_childhood: [0],
    elementary: [13, 1, 2, 3, 4, 5],
    middle_school: [6, 7, 8],
    high_school: [9, 10, 11, 12],
  };
};

export const childhood_grades = () => [
  { value: 13, label: 'Kindergarten', key: 'kindergarten' },
  { value: 1, label: 'First Grade', key: 'first_grade' },
  { value: 2, label: 'Second Grade', key: 'second_grade' },
  { value: 3, label: 'Third Grade', key: 'third_grade' },
  { value: 4, label: 'Fourth Grade', key: 'fourth_grade' },
  { value: 5, label: 'Fifth Grade', key: 'fifth_grade' },
];

export const middle_grades = () => [
  { value: 6, label: 'Sixth Grade', key: 'sixth_grade' },
  { value: 7, label: 'Seventh Grade', key: 'seventh_grade' },
  { value: 8, label: 'Eighth Grade', key: 'eighth_grade' },
];

export const secondary_grades = () => [
  { value: 9, label: 'Ninth Grade', key: 'ninth_grade' },
  { value: 10, label: 'Tenth Grade', key: 'tenth_grade' },
  { value: 11, label: 'Eleventh Grade', key: 'eleventh_grade' },
  { value: 12, label: 'Twelfth Grade', key: 'twelfth_grade' },
];

export const gradeLevels = () => [
  { value: 0, label: 'Early Childhood Education', key: 'early_childhood' },
  { value: 1, label: 'Elementary Education', key: 'childhood' },
  { value: 2, label: 'Middle School Education', key: 'middle_childhood' },
  { value: 3, label: 'Secondary Education', key: 'secondary' },
];

// gradeLevels used for the state data dashboard, match GradeMapping()
export const gradeLevelsUpdated = () => [
  { value: 0, label: 'Early Childhood', key: 'early_childhood' },
  { value: 1, label: 'Elementary', key: 'elementary' },
  { value: 2, label: 'Middle School', key: 'middle_school' },
  { value: 3, label: 'High School', key: 'high_school' },
];

/* Deprecated - credential Subjects are now state-specific! */
export const credentialSubjects = () => [
  { value: 0, key: 'admin_home_office', label: 'Admin - Home Office' },
  { value: 49, key: 'admin_school', label: 'Admin - School ' },
  { value: 1, key: 'art', label: 'Art' },
  { value: 24, key: 'computer_science', label: 'Computer Science' },
  { value: 25, key: 'dance', label: 'Dance' },
  { value: 26, key: 'drama_theater', label: 'Drama/Theater' },
  { value: 48, key: 'economics', label: 'Economics' },
  { value: 5, key: 'english', label: 'English' },
  { value: 46, key: 'geography', label: 'Geography' },
  { value: 47, key: 'government', label: 'Government' },
  { value: 45, key: 'history', label: 'History' },
  {
    value: 11,
    key: 'industrial_technology_education',
    label: 'Industrial and Technology Education',
  },
  { value: 34, key: 'journalism', label: 'Journalism' },
  { value: 50, key: 'maintenance_custodial', label: 'Maintenance / Custodial' },
  { value: 12, key: 'math', label: 'Mathematics' },
  { value: 13, key: 'music', label: 'Music' },
  { value: 14, key: 'physical_education', label: 'Physical Education' },
  { value: 15, key: 'physics_specialized', label: 'Physics (Specialized)*' },
  { value: 16, key: 'biological_sciences', label: 'Science: Biological Sciences' },
  { value: 17, key: 'chemistry', label: 'Science: Chemistry' },
  { value: 18, key: 'geosciences', label: 'Science: Geosciences' },
  { value: 23, key: 'life_sciences', label: 'Science: Life Sciences' },
  { value: 19, key: 'physics', label: 'Science: Physics' },
  { value: 51, key: 'secretarial_clerical', label: 'Secretarial / Clerical' },
  { value: 20, key: 'social_science', label: 'Social Science' },
  { value: 36, key: 'special_education', label: 'Special Education' },
  { value: 52, key: 'specialist', label: 'Specialist' },
  { value: 53, key: 'substitute', label: 'Substitute' },
  { value: 54, key: 'support_staff', label: 'Support Staff' },
  { value: 27, key: 'french', label: 'World Language: French' },
  { value: 28, key: 'german', label: 'World Language: German' },
  { value: 29, key: 'italian', label: 'World Language: Italian' },
  { value: 30, key: 'japanese', label: 'World Language: Japanese' },
  { value: 31, key: 'russian', label: 'World Language: Russian' },
  { value: 32, key: 'spanish', label: 'World Language: Spanish' },
  { value: 998, key: 'multiple', label: 'Multiple Subjects' },
  { value: 999, key: 'other', label: 'Other' },
];

export const teachingSubjects = () => [
  { value: 1, key: 'art', label: 'Art' },
  { value: 24, key: 'computer_science', label: 'Computer Science' },
  // { value: 25, key: 'dance', label: 'Dance' },
  // { value: 26, key: 'drama_theater', label: 'Drama/Theater' },
  { value: 48, key: 'economics', label: 'Economics' },
  { value: 5, key: 'english', label: 'English' },
  { value: 46, key: 'geography', label: 'Geography' },
  { value: 47, key: 'government', label: 'Government' },
  { value: 45, key: 'history', label: 'History' },
  // {
  //   value: 11,
  //   key: 'industrial_technology_education',
  //   label: 'Industrial and Technology Education'
  // },
  // { value: 34, key: 'journalism', label: 'Journalism' },
  { value: 12, key: 'math', label: 'Mathematics' },
  { value: 13, key: 'music', label: 'Music' },
  { value: 14, key: 'physical_education', label: 'Physical Education' },
  // { value: 15, key: 'physics_specialized', label: 'Physics (Specialized)*' },
  { value: 16, key: 'biological_sciences', label: 'Science: Biological Sciences' },
  { value: 17, key: 'chemistry', label: 'Science: Chemistry' },
  { value: 18, key: 'geosciences', label: 'Science: Geosciences' },
  { value: 23, key: 'life_sciences', label: 'Science: Life Sciences' },
  // { value: 19, key: 'physics', label: 'Science: Physics' },
  // { value: 20, key: 'social_science', label: 'Social Science' },
  { value: 36, key: 'special_education', label: 'Special Education' },
  { value: 52, key: 'specialist', label: 'Specialist' },
  { value: 53, key: 'substitute', label: 'Substitute' },
  { value: 27, key: 'french', label: 'World Language: French' },
  { value: 28, key: 'german', label: 'World Language: German' },
  { value: 29, key: 'italian', label: 'World Language: Italian' },
  { value: 30, key: 'japanese', label: 'World Language: Japanese' },
  { value: 31, key: 'russian', label: 'World Language: Russian' },
  { value: 32, key: 'spanish', label: 'World Language: Spanish' },
  { value: 998, key: 'multiple', label: 'Multiple Subjects' },
  { value: 999, key: 'other', label: 'Other' },
];

export const leadAndSupportSubjects = () => [
  { value: 0, key: 'admin_home_office', label: 'Admin - Home Office' },
  { value: 49, key: 'admin_school', label: 'Admin - School ' },
  { value: 50, key: 'maintenance_custodial', label: 'Maintenance / Custodial' },
  { value: 51, key: 'secretarial_clerical', label: 'Secretarial / Clerical' },
  { value: 52, key: 'specialist', label: 'Specialist' },
  { value: 54, key: 'support_staff', label: 'Support Staff' },
];

// Changed March, 2022 to be 1-indexed to map onto States table
// TODO: remove all instances of 'value' as it is a bit redudant
export const USStates = () => [
  { value: 1, id: 1, label: 'AL', title: 'Alabama' },
  { value: 2, id: 2, label: 'AK', title: 'Alaska' },
  { value: 3, id: 3, label: 'AZ', title: 'Arizona' },
  { value: 4, id: 4, label: 'AR', title: 'Arkansas' },
  { value: 5, id: 5, label: 'CA', title: 'California' },
  { value: 6, id: 6, label: 'CO', title: 'Colorado' },
  { value: 7, id: 7, label: 'CT', title: 'Connecticut' },
  { value: 8, id: 8, label: 'DE', title: 'Delaware' },
  { value: 9, id: 9, label: 'FL', title: 'Florida' },
  { value: 10, id: 10, label: 'GA', title: 'Georgia' },
  { value: 11, id: 11, label: 'HI', title: 'Hawaii' },
  { value: 12, id: 12, label: 'ID', title: 'Idaho' },
  { value: 13, id: 13, label: 'IL', title: 'Illinois' },
  { value: 14, id: 14, label: 'IN', title: 'Indiana' },
  { value: 15, id: 15, label: 'IA', title: 'Iowa' },
  { value: 16, id: 16, label: 'KS', title: 'Kansas' },
  { value: 17, id: 17, label: 'KY', title: 'Kentucky' },
  { value: 18, id: 18, label: 'LA', title: 'Louisiana' },
  { value: 19, id: 19, label: 'ME', title: 'Maine' },
  { value: 20, id: 20, label: 'MD', title: 'Maryland' },
  { value: 21, id: 21, label: 'MA', title: 'Massachusetts' },
  { value: 22, id: 22, label: 'MI', title: 'Michigan' },
  { value: 23, id: 23, label: 'MN', title: 'Minnesota' },
  { value: 24, id: 24, label: 'MS', title: 'Mississippi' },
  { value: 25, id: 25, label: 'MO', title: 'Missouri' },
  { value: 26, id: 26, label: 'MT', title: 'Montana' },
  { value: 27, id: 27, label: 'NE', title: 'Nebraska' },
  { value: 28, id: 28, label: 'NV', title: 'Nevada' },
  { value: 29, id: 29, label: 'NH', title: 'New Hampshire' },
  { value: 30, id: 30, label: 'NJ', title: 'New Jersey' },
  { value: 31, id: 31, label: 'NM', title: 'New Mexico' },
  { value: 32, id: 32, label: 'NY', title: 'New York' },
  { value: 33, id: 33, label: 'NC', title: 'North Carolina' },
  { value: 34, id: 34, label: 'ND', title: 'North Dakota' },
  { value: 35, id: 35, label: 'OH', title: 'Ohio' },
  { value: 36, id: 36, label: 'OK', title: 'Oklahoma' },
  { value: 37, id: 37, label: 'OR', title: 'Oregon' },
  { value: 38, id: 38, label: 'PA', title: 'Pennsylvania' },
  { value: 39, id: 39, label: 'RI', title: 'Rhode Island' },
  { value: 40, id: 40, label: 'SC', title: 'South Carolina' },
  { value: 41, id: 41, label: 'SD', title: 'South Dakota' },
  { value: 42, id: 42, label: 'TN', title: 'Tennessee' },
  { value: 43, id: 43, label: 'TX', title: 'Texas' },
  { value: 44, id: 44, label: 'UT', title: 'Utah' },
  { value: 45, id: 45, label: 'VT', title: 'Vermont' },
  { value: 46, id: 46, label: 'VA', title: 'Virginia' },
  { value: 47, id: 47, label: 'WA', title: 'Washington' },
  { value: 48, id: 48, label: 'WV', title: 'West Virginia' },
  { value: 49, id: 49, label: 'WI', title: 'Wisconsin' },
  { value: 50, id: 50, label: 'WY', title: 'Wyoming' },
  { value: 51, id: 51, label: 'GU', title: 'Guam' },
  { value: 52, id: 52, label: 'PR', title: 'Puerto Rico' },
  { value: 53, id: 53, label: 'VI', title: 'Virgin Islands' },
  { value: 54, id: 54, label: 'DC', title: 'District of Columbia (Washington, D.C.)' },
];

export const credentialNewJerseyTeachingCertificate = () => [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No, I hold a certificate from another state' },
  { value: 3, label: 'No, but I am finishing a teacher prep program' },
  { value: 4, label: 'No, but I have submitted an application' },
  { value: 5, label: 'No' },
];

export const internalList = () => [
  { value: 1, key: 'internal', label: 'Internal candidates' },
  { value: 2, key: 'external', label: 'External candidates' },
];

export const languageList = () => [
  { value: 1, readable: 'english', label: 'English' },
  { value: 2, readable: 'spanish', label: 'Spanish' },
  { value: 3, readable: 'russian', label: 'Russian' },
  { value: 4, readable: 'japanese', label: 'Japanese' },
  { value: 5, readable: 'italian', label: 'Italian' },
  { value: 6, readable: 'french', label: 'French' },
  { value: 7, readable: 'chinese', label: 'Chinese' },
  { value: 8, readable: 'american_sign', label: 'American Sign Language' },
  { value: 9, readable: 'latin', label: 'Latin' },
  { value: 10, readable: 'german', label: 'German' },
  { value: 11, readable: 'korean', label: 'Korean' },
  { value: 12, readable: 'mandarin', label: 'Mandarin' },
  { value: 13, readable: 'punjabi', label: 'Punjabi' },
  { value: 14, readable: 'vietnamese', label: 'Vietnamese' },
  { value: 15, readable: 'arabic', label: 'Arabic' },
  { value: 16, readable: 'portuguese', label: 'Portuguese' },
  { value: 18, readable: 'hmong', label: 'Hmong' },
  { value: 19, readable: 'lao', label: 'Lao' },
  { value: 20, readable: 'armenian', label: 'Armenian' },
  { value: 21, readable: 'farsi', label: 'Farsi' },
  { value: 999, readable: 'other', label: 'Other' },
];

export const languageProficiency = () => [
  { value: 0, label: 'Elementary proficiency' },
  { value: 1, label: 'Limited working proficiency' },
  { value: 2, label: 'Professional working proficiency' },
  { value: 3, label: 'Full professional proficiency' },
  { value: 4, label: 'Native or bilingual proficiency' },
];

export const languageProficiencyFilterMap = () => ({
  a: 0,
  b: 1,
  c: 2,
  d: 3,
  e: 4,
});

export const salarytype = () => [
  { value: 1, label: 'per hour' },
  { value: 2, label: 'per week' },
  { value: 3, label: 'per month' },
  { value: 4, label: 'per year' },
  { value: 5, label: 'per day' },
];

export const salarytypeByLabel = {
  'per hour': 1,
  'per week': 2,
  'per month': 3,
  'per year': 4,
  'per day': 5,
};

export const employmentTypeValues = () => [
  { value: 0.5, label: 'Part-Time' },
  { value: 0.75, label: 'Full and Part-Time' },
  { value: 1, label: 'Full-Time' },
];

export const employmentTypePreferences = () => [
  { value: 1, key: 'no_preference', label: 'No preference' },
  { value: 2, key: 'full_time', label: 'Full time' },
  { value: 3, key: 'part_time', label: 'Part time' },
];

/** similar to above, but without part time because that filter is evaluated
 * based on hours_per_week.
 */
export const employmentTypeFilters = () => [
  { value: 1, key: 'no_preference', label: 'No preference' },
  { value: 2, key: 'full_time', label: 'Full time' },
];

/** Note that 'N/A' is also an option for the candidate side but not as
 * a filter option on the admin side, so it's not included here.
 */
export const hoursPerWeekOptions = () => ['0-10', '10-15', '15-25', '25-30', '30-35'];

/** since employment_type and hours_per_week are combined as one filter in the UI,
 * use this combined enum for the filter field.
 */
export const employmentTypeAndHours = () => [
  { value: 1, key: 'no_preference' },
  { value: 2, key: 'full_time' },
  { value: '0-10', key: '0-10' },
  { value: '10-15', key: '10-15' },
  { value: '15-25', key: '15-25' },
  { value: '25-30', key: '25-30' },
  { value: '30-35', key: '30-35' },
];

export const preferenceOptions = () => [
  { value: 1, key: 'no_preference', label: 'No preference' },
  { value: 2, key: 'yes', label: 'Yes' },
  { value: 3, key: 'no', label: 'No' },
];

export const schoolStatusType = {
  active: 1,
  archived: 2,
};

export const schoolStatus = () => [
  { key: schoolStatusType.active, value: 'active', label: 'Active' },
  { key: schoolStatusType.archived, value: 'archived', label: 'Archived' },
];

export const vacancyTypes = () => [
  { key: 0, value: 'pending', label: 'Pending', color: 'yellow' },
  { key: 1, value: 'approved', label: 'Approved', color: 'green' },
];

export const vacancyTypeByValue = vacancyTypes().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

export const vacancyTypeColor = vacancyTypes().reduce((obj, item) => {
  obj[item.key] = item.color;
  return obj;
}, {});

export const roleStatuses = () => [
  { key: 0, value: 'draft', label: 'Draft', color: theme.uiColors.gray },
  { key: 10, value: 'submitted', label: 'Pending approval', color: theme.uiColors.blues.full },
  { key: 20, value: 'active', label: 'Active' },
  { key: 30, value: 'archived', label: 'Archived', color: theme.uiColors.reds.full },
];

export const activeStatusByValue = {
  activeClosed: 0,
  activeOpen: 1,
};

export const roleErrorMessages = {
  title: 'Job Post Title is a required field',
  job_description: 'Job Description is a required field',
  role_type: 'Please select a role type from the dropdown',
  link_to_ats: 'There was a problem with the ATS link or email address',
  duplicate_title: 'A template already exists with that name. Template names must be unique.',
};

export const roleStatusesForSearchFilters = () => [
  { value: 0, key: 'draft', label: 'Draft' },
  { value: 1, key: 'active_open', label: 'Active, Open' },
  { value: 2, key: 'active_closed', label: 'Active, Closed' },
  { value: 3, key: 'archived', label: 'Archived' },
];

export const roleStatusIdsByName = {
  draft: 0,
  pending: 10,
  active: 20,
  archived: 30,
};

export const roleStatusesForCandidatesList = {
  0: 'Draft',
  10: 'Submitted',
  20: 'Active',
  30: 'Archived',
};

// Parent Organizations (State level aggregating)
export const parentOrganizations = {
  oecosl: 'OECOSL',
  idoe: 'IDOE',
};

// Demographics
export const gender = () => [
  { value: 1, label: 'Female' },
  { value: 2, label: 'Male' },
  { value: 4, label: 'Non-binary' },
  { value: 3, label: 'Other' },
  { value: 0, label: 'Prefer Not to Say' },
];

export const race_ethnicity = () => [
  { value: 1, label: 'American Indian or Alaska Native' },
  { value: 2, label: 'Asian' },
  { value: 3, label: 'Black or of African Descent' },
  { value: 4, label: 'Pacific islander or Native Hawaiian' },
  { value: 5, label: 'White' },
  { value: 6, label: 'Hispanic or Latino' },
  { value: 7, label: 'Two or more races' },
  { value: 0, label: 'Prefer Not to Say' },
];

export const user_type = () => [
  { key: 0, value: 'candidate', label: 'Candidate' },
  { key: 10, value: 'school_user', label: 'School User' },
  { key: 20, value: 'school_admin', label: 'School Admin' },
  { key: 30, value: 'district_user', label: 'District User' },
  { key: 40, value: 'district_admin', label: 'District Admin' },
  { key: 50, value: 'super_admin', label: 'Super Admin' },
];

export const employment_type = () => [
  { value: 0, label: 'Part-time' },
  { value: 1, label: 'Full & Part-time' },
  { value: 2, label: 'Full-time' },
];
export const exams = () => [
  { value: 10, label: 'CBEST' },
  { value: 20, label: 'MSAT' },
  { value: 30, label: 'RICA' },
  { value: 40, label: 'PRAXIS/SSAT' },
  { value: 50, label: 'CSET' },
  { value: 60, label: 'TFE' },
];

export const certificates = () => [
  { value: 10, label: 'BCC' },
  { value: 20, label: 'BCLAD' },
  { value: 30, label: 'CLAD' },
  { value: 40, label: 'LDS' },
  { value: 50, label: 'ELD/SDAIE' },
  { value: 60, label: 'TPSL' },
  { value: 70, label: 'Other' },
];

/* status indicators */

export function getJobStatusColor(status) {
  const statuses = _.keyBy(roleStatuses(), 'value');
  switch (status) {
    case statuses.draft.key:
      return 'yellow';
    case statuses.submitted.key:
      return 'blue';
    case statuses.active.key:
      return 'green';
    default:
      return 'red';
  }
}

export function getJobStatusColorForJobViewV2(status, activeStatus) {
  const statuses = _.keyBy(roleStatuses(), 'value');

  switch (status) {
    case statuses.draft.key:
      return {
        textColor: nimbleTheme.palette.text.tertiary,
        backgroundColor: nimbleTheme.palette.white.darker,
        borderColor: nimbleTheme.palette.grey.light,
      };
    case statuses.submitted.key:
      return {
        borderColor: nimbleTheme.palette.secondary.light,
        backgroundColor: nimbleTheme.palette.secondary.fill,
        textColor: nimbleTheme.palette.secondary.dark,
      };
    case statuses.archived.key:
      return {
        textColor: nimbleTheme.palette.error.dark,
        backgroundColor: nimbleTheme.palette.error.fill,
        borderColor: nimbleTheme.palette.error.main,
      };
    case statuses.active.key:
      return activeStatus === activeStatusByValue.activeOpen
        ? {
            textColor: nimbleTheme.palette.primary.dark,
            backgroundColor: nimbleTheme.palette.primary.fill,
            borderColor: nimbleTheme.palette.primary.main,
          }
        : {
            textColor: nimbleTheme.palette.warning.dark,
            backgroundColor: nimbleTheme.palette.warning.fill,
            borderColor: nimbleTheme.palette.warning.main,
          };
    default:
      return {
        textColor: nimbleTheme.palette.text.tertiary,
        backgroundColor: nimbleTheme.palette.white.darker,
        borderColor: nimbleTheme.palette.grey.light,
      };
  }
}

export const appStatusColors = () => [
  { key: 1, value: 'red' },
  { key: 2, value: 'green' },
  { key: 3, value: 'blue' },
  { key: 4, value: 'yellow' },
];

export const colorMapping = {
  1: 'red',
  2: 'green',
  3: 'blue',
  4: 'yellow',
};

export const applicationStatusMapping = {
  1: nimbleTheme.palette.error.dark,
  2: nimbleTheme.palette.primary.dark,
  3: nimbleTheme.palette.blue.main,
  4: nimbleTheme.palette.yellow.main,
};

export const applicationStatusColorToStatusMapping = {
  red: 1,
  green: 2,
  blue: 3,
  yellow: 4,
};

export const appStatusType = {
  draft: 0,
  pre_hiring_pool: 1,
  hiring_pool: 2,
  post_hiring_pool: 3,
  hired: 4,
  archived: 5,
  // Deprecated. Use archived instead.
  offer_declined: 6,
  onboarding: 7,
};

export const questionType = {
  direction_text: 'question_type/direction_text',
  open_response: 'question_type/open_response',
  yes_no: 'question_type/yes_no',
  multiple_choice: 'question_type/multiple_choice',
  nimble: 'question_type/nimble',
  attachment: 'question_type/attachment',
  videoLink: 'question_type/video_link',
  schoolPreferences: 'question_type/school_preferences',
  statementCheckbox: 'question_type/statement_checkbox',
  // Legacy nimble question
  text_question_model: 'question_type/legacy_text_question',
};

export const questionTypeModelMap = {
  nimble_rolequestion_set: ['question_type/nimble'],
  requiredapplicationattachment_set: ['question_type/attachment', 'question_type/video_link'],
  custom_questions: [
    'question_type/statement_checkbox',
    'question_type/multiple_choice',
    'question_type/yes_no',
    'question_type/open_response',
    'question_type/direction_text',
  ],
  school_preferences_question: ['question_type/school_preferences'],
};

export const questionSource = {
  nimble: 0,
  custom: 1,
};

export const CANDIDATE_SOURCE_INTERNAL = {
  none: 'No Source',
  google_search: 'Google search',
  social_media: 'Social media',
  linkedin: 'Linkedin',
  recruitment_event: 'Recruitment event',
  referral: 'Referral',
  university_partnership_or_job_board: 'University partnership or job board',
  alternative_certification_partner: 'Alternative certification partner',
  edjoin: 'Edjoin',
  indeed: 'Indeed',
  ziprecruiter: 'ZipRecruiter',
  glassdoor: 'Glassdoor',
  facebook: 'Facebook',
  other: 'Other',
  indiana_educator_job_board: 'Indiana Educator Job Board',
  talent_marketplace: 'Talent Marketplace',
};

// Options admins see on quick view / full profile dropdowns.
// Source will eventually become a customizable model
export const candidateSourcesInternal = () => [
  { value: 'none', label: '' },
  { value: 'google_search', label: CANDIDATE_SOURCE_INTERNAL.google_search },
  { value: 'social_media', label: CANDIDATE_SOURCE_INTERNAL.social_media },
  { value: 'linkedin', label: CANDIDATE_SOURCE_INTERNAL.linkedin },
  { value: 'recruitment_event', label: CANDIDATE_SOURCE_INTERNAL.recruitment_event },
  { value: 'referral', label: CANDIDATE_SOURCE_INTERNAL.referral },
  {
    value: 'university_partnership_or_job_board',
    label: CANDIDATE_SOURCE_INTERNAL.university_partnership_or_job_board,
  },
  {
    value: 'alternative_certification_partner',
    label: CANDIDATE_SOURCE_INTERNAL.alternative_certification_partner,
  },
  { value: 'edjoin', label: CANDIDATE_SOURCE_INTERNAL.edjoin },
  { value: 'indeed', label: CANDIDATE_SOURCE_INTERNAL.indeed },
  { value: 'ziprecruiter', label: CANDIDATE_SOURCE_INTERNAL.ziprecruiter },
  { value: 'glassdoor', label: CANDIDATE_SOURCE_INTERNAL.glassdoor },
  { value: 'facebook', label: CANDIDATE_SOURCE_INTERNAL.facebook },
  { value: 'other', label: CANDIDATE_SOURCE_INTERNAL.other },
  {
    value: 'indiana_educator_job_board',
    label: CANDIDATE_SOURCE_INTERNAL.indiana_educator_job_board,
  },
  { value: 'talent_marketplace', label: CANDIDATE_SOURCE_INTERNAL.talent_marketplace },
];

// Options candidates see on application dropdown.
// Source will eventually become a customizable model
export const candidateSourcesExternal = () => [
  { value: 'none', label: 'How did you hear about us?' },
  { value: 'edjoin', label: 'Edjoin' },
  { value: 'google_search', label: 'Google search' },
  { value: 'social_media', label: 'Social media' },
  { value: 'recruitment_event', label: 'Job fair' },
  { value: 'university_partnership_or_job_board', label: 'University job board' },
  { value: 'linkedin', label: 'Linkedin' },
  { value: 'glassdoor', label: 'Glassdoor' },
  { value: 'facebook', label: CANDIDATE_SOURCE_INTERNAL.facebook },
  { value: 'referral', label: 'Referred by current employee' },
  { value: 'other', label: 'Other' },
  { value: 'indiana_educator_job_board', label: 'Indiana Educator Job Board' },
];

// Options admins see on prospect quick view dropdown.
// Source will eventually become a customizable model
export const prospectSources = () => [
  { value: 'none', label: 'N/A' },
  { value: 'google_search', label: 'Google search' },
  { value: 'social_media', label: 'Social media' },
  { value: 'linkedin', label: 'Linkedin' },
  { value: 'recruitment_event', label: 'Recruitment event' },
  { value: 'referral', label: 'Referral' },
  { value: 'university_partnership_or_job_board', label: 'University partnership or job board' },
  { value: 'alternative_certification_partner', label: 'Alternative certification partner' },
  { value: 'glassdoor', label: 'Glassdoor' },
  { value: 'facebook', label: CANDIDATE_SOURCE_INTERNAL.facebook },
  { value: 'other', label: 'Other' },
  { value: 'indiana_educator_job_board', label: 'Indiana Educator Job Board' },
  { value: 'talent_marketplace', label: 'Talent Marketplace' },
];

export const stateDashboardChartTypes = {
  pie: 0,
  line: 1,
  bar: 2,
};

export const stateDashboardSegmentTypes = {
  none: { value: 0, label: 'Select option' },
  grade: { value: 1, label: 'Grade Level' },
  epp: { value: 2, label: 'Educator Prep Programs' },
  gender: { value: 3, label: 'Gender' },
  subcategory: { value: 4, label: 'Job Category' },
  raceEthnicity: { value: 5, label: 'Race/Ethnicity' },
  credential: { value: 6, label: 'Certification Area' },
  employmentType: { value: 7, label: 'Employment Type' },
  nces: { value: 8, label: 'NCES Locale' },
  credential_status: { value: 9, label: 'Certification Type' },
};

export const searchBarPlaceholders = {
  [stateDashboardSegmentTypes.credential_status.value]: 'Search certification type',
  [stateDashboardSegmentTypes.credential.value]: 'Search certification area',
  [stateDashboardSegmentTypes.gender.value]: 'Search gender',
  [stateDashboardSegmentTypes.raceEthnicity.value]: 'Search race/ethnicity',
  [stateDashboardSegmentTypes.subcategory.value]: 'Search job category',
  [stateDashboardSegmentTypes.employmentType.value]: 'Search employment type',
};

export const stateDashboardSegmentMap = {
  0: { key: 'none', label: 'Select option' },
  1: { key: 'grade', label: 'Grade level' },
  2: { key: 'epp', label: 'Educator Prep Programs' },
  3: { key: 'gender', label: 'Gender' },
  4: { key: 'subcategory', label: 'Job Category' },
  5: { key: 'race_ethnicity', label: 'Race/Ethnicity' },
  6: { key: 'certification_area', label: 'Certification Area' },
  7: { key: 'employment_type', label: 'Employment Type' },
  8: { key: 'nces', label: 'NCES Locale' },
  9: { key: 'credential_status', label: 'Certification Type' },
};

export const vacancyStatus = {
  unfilled: 0,
  filled: 1,
};

export const vacancyStatusOptions = {
  [vacancyStatus.unfilled]: 'Open positions',
  [vacancyStatus.filled]: 'Hires made',
};

export const scorecardQType = {
  rating: 0,
  // Deprecated. All existing yes_no questions will be changed to multiple_choice.
  yes_no: 1,
  text: 2,
  nimble: 3,
  multiple_choice: 4,
  direction_text: 5,
  rubric: 6,
  final_recommendation: 7,
  cumulative_score: 8,
  attachment: 9,
};

export const referenceQuestionOptions = () => [
  {
    key: 'direction_text',
    value: 5,
    label: 'Direction Text',
    icon: DirectionTextIcon,
    info: 'Gives the reviewer clear directions on how to fill out this scorecard',
  },
  {
    key: 'text',
    value: 2,
    label: 'Text Question',
    icon: TextIcon,
    info: 'Reviewer can respond in free form text',
  },
  {
    key: 'multiple_choice',
    value: 4,
    label: 'Multiple Choice',
    icon: MultipleChoiceIcon,
    info: 'Candidate can select one option',
    info2: 'Candidate can select multiple options',
  },
  {
    key: 'nimble',
    value: 3,
    label: 'Nimble Question',
    icon: NimbleLogoBlack,
    info: 'Candidate can respond in free form text',
  },
  {
    key: 'rubric',
    value: 6,
    label: 'Rubric Item',
    icon: RubricIcon,
    info: 'Asks the reviewer to rate the candidate on this dimension',
  },
  {
    key: 'rating',
    value: 0,
    label: 'Rating',
    icon: RatingIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'attachment',
    value: 9,
    label: 'Attachment',
    icon: AttachmentIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'final_recommendation',
    value: 7,
    label: 'Final Recommendation',
    icon: YesNoIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'cumulative_score',
    value: 8,
    label: 'Cumulative Score',
    icon: CumulativeScoreIcon,
    info: 'Reviewer gives a rating on a scale',
  },
];

export const scorecardQuestionOptions = () => [
  {
    key: 'direction_text',
    value: 5,
    label: 'Direction Text',
    icon: DirectionTextIcon,
    info: 'Gives the reviewer clear directions on how to fill out this scorecard',
  },
  {
    key: 'text',
    value: 2,
    label: 'Text Question',
    icon: TextIcon,
    info: 'Reviewer can respond in free form text',
  },
  {
    key: 'multiple_choice',
    value: 4,
    label: 'Multiple Choice',
    icon: MultipleChoiceIcon,
    info: 'Candidate can select one option',
    info2: 'Candidate can select multiple options',
  },
  {
    key: 'nimble',
    value: 3,
    label: 'Nimble Question',
    icon: NimbleLogoBlack,
    info: 'Candidate can respond in free form text',
  },
  {
    key: 'rubric',
    value: 6,
    label: 'Rubric Item',
    icon: RubricIcon,
    info: 'Asks the reviewer to rate the candidate on this dimension',
  },
  {
    key: 'rating',
    value: 0,
    label: 'Rating',
    icon: RatingIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'attachment',
    value: 9,
    label: 'Attachment',
    icon: AttachmentIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'final_recommendation',
    value: 7,
    label: 'Final Recommendation',
    icon: YesNoIcon,
    info: 'Reviewer gives a rating on a scale',
  },
  {
    key: 'cumulative_score',
    value: 8,
    label: 'Cumulative Score',
    icon: CumulativeScoreIcon,
    info: 'Reviewer gives a rating on a scale',
  },
];

export const scorecardQuestionMapping = scorecardQuestionOptions().reduce((obj, item) => {
  obj[item.value] = { ...item };
  return obj;
}, {});

export const scorecardType = {
  /** not be confused with scorecardQType, which describes
   * a scorecards questions. This describes whether it is
   * a true scorecard or a reference, since they will use
   * the same modal moving forward.
   */
  scorecard: 0,
  reference: 1,
};

// ****** reference section *******
export const referenceStatus = () => {
  return {
    0: { icon: NotStartedIcon, label: 'Unstarted', button: 'Complete' },
    1: { icon: PendingIcon, label: 'Pending', button: 'View' },
    2: { icon: CompletedIcon, label: 'Complete', button: 'View' },
    3: { icon: PendingIcon, label: 'Draft', button: 'Draft' },
  };
};

// shorter version
export const refStatus = {
  not_started: 0,
  pending: 1,
  complete: 2,
  draft: 3,
};
// ****** end *******

export const salutation = () => [
  { value: 0, label: 'Mrs.' },
  { value: 1, label: 'Mr.' },
  { value: 2, label: 'Miss' },
  { value: 3, label: 'Ms.' },
  { value: 4, label: 'Dr.' },
  { value: 5, label: 'Rev.' },
];

// ***** task section *****
export const taskStatus = {
  incomplete: 0,
  complete: 1,
};

export const customFieldPermission = () => [
  { value: 0, label: 'None' },
  { value: 1, label: 'View only' },
  { value: 2, label: 'View & edit' },
];

export const customFieldTypeData = () => [
  { value: 0, label: 'CSV upload' },
  { value: 1, label: 'Paste values' },
];

export const customTagPermissions = () => [
  { key: 0, value: 'super_admins', label: 'Super Admin' },
  { key: 1, value: 'district_admins', label: 'District Admin' },
  { key: 2, value: 'district_users', label: 'District Users' },
  { key: 3, value: 'school_admins', label: 'School Admins' },
];

export const visibleTo = () => [
  { key: 0, value: 'me_only', label: 'Me only (private)' },
  { key: 1, value: 'district_admins', label: 'District admins / users' },
  { key: 2, value: 'school_admins', label: 'School admins' },
  { key: 3, value: 'everyone', label: 'Everyone' },
];

export const scorecardPermissionsSuper = [
  { value: 'me_only', label: 'Me only (private)' },
  { value: 'district_admins', label: 'District admins / users' },
  { value: 'school_admins', label: 'School admins / users' },
  { value: 'everyone', label: 'Everyone' },
];

export const scorecardPermissionsSchool = [
  { value: 'me_only', label: 'Me only (private)' },
  { value: 'users_at_my_school', label: 'Users at my school' },
];

/**
 * Object containing a mapping between value and label for all scorecard permissions
 *
 * {
 * 'me_only': 'Me only (private)',
 * ...
 * }
 * */
export const scorecardPermissionMap = [
  ...scorecardPermissionsSchool,
  ...scorecardPermissionsSuper,
].reduce((obj, currentItem) => {
  obj[currentItem.value] = currentItem.label;
  return obj;
}, {});

export const referencePermissionsSuper = [
  { value: 'me_only', label: 'Me only (private)' },
  { value: 'everyone', label: 'Everyone' },
];

/**
 * Object containing a mapping between value and label for reference permissions
 *
 * {
 * 'me_only': 'Me only (private)',
 * ...
 * }
 * */
export const referencePermissionMap = referencePermissionsSuper.reduce((obj, currentItem) => {
  obj[currentItem.value] = currentItem.label;
  return obj;
}, {});

export const startDateType = {
  'Not specified': 1,
  Immediate: 2,
  'Next school year': 3,
  'Specific date': 4,
};

export const startDateLabels = {
  1: 'Not specified',
  2: 'Immediate',
  3: 'Next school year',
  4: 'Specific date',
};

// backend enum: PreferenceCategories (preferences.py)
export const preferenceCategories = () => [
  { value: 1, label: 'Assistant principal' },
  { value: 2, label: 'Principal' },
  { value: 3, label: 'Art' },
  { value: 4, label: 'Computer science' },
  { value: 5, label: 'Economics' },
  { value: 6, label: 'English' },
  { value: 7, label: 'Geography' },
  { value: 8, label: 'Government' },
  { value: 9, label: 'History' },
  { value: 10, label: 'Humanities' },
  { value: 11, label: 'Mathematics' },
  { value: 12, label: 'Multiple subjects' },
  { value: 13, label: 'Music' },
  { value: 14, label: 'Physical education' },
  { value: 15, label: 'Science' },
  { value: 16, label: 'Social science' },
  { value: 17, label: 'Special education' },
  { value: 18, label: 'Foreign language' },
  { value: 19, label: 'Other' },
  { value: 20, label: 'Paraprofessional' },
  { value: 21, label: 'Substitute teacher' },
  { value: 22, label: 'Speech language pathologist' },
  { value: 23, label: 'School nurse' },
  { value: 24, label: 'Occupational therapist' },
  { value: 25, label: 'Social worker' },
  { value: 26, label: 'School counselor' },
  { value: 27, label: 'Secretary / clerical' },
  { value: 28, label: 'Transportation' },
  { value: 29, label: 'Maintenance' },
  { value: 30, label: 'Custodial' },
  { value: 31, label: 'Food services' },
  { value: 32, label: 'Information technology' },
  { value: 33, label: 'Budget and finance' },
  { value: 34, label: 'Human resources' },
  { value: 35, label: 'Academic support' },
  { value: 36, label: 'Business & Marketing' },
  { value: 37, label: 'Career & Technical Education' },
  { value: 38, label: 'Early Childhood' },
  { value: 39, label: 'English Language Learner (ELL)' },
  { value: 40, label: 'Library' },
  { value: 41, label: 'Reading & Literacy' },
];

// Backend enum: degreesubjects.py
export const degreeSubjects = () => [
  { value: -1, label: 'Other' },
  { value: 0, label: 'Accounting' },
  { value: 1, label: 'Actuarial Science' },
  { value: 2, label: 'Advertising' },
  { value: 3, label: 'Aerospace Engineering' },
  { value: 4, label: 'African Languages, Literatures, and Linguistics' },
  { value: 5, label: 'African Studies' },
  { value: 6, label: 'African-American Studies' },
  { value: 7, label: 'Agricultural Business and Management' },
  { value: 8, label: 'Agricultural Economics' },
  { value: 9, label: 'Agricultural Education' },
  { value: 10, label: 'Agricultural Journalism' },
  { value: 11, label: 'Agricultural Mechanization' },
  { value: 12, label: 'Agricultural Technology Management' },
  { value: 13, label: 'Agricultural/Biological Engineering and Bioengineering' },
  { value: 14, label: 'Agriculture' },
  { value: 15, label: 'Agronomy and Crop Science' },
  { value: 16, label: 'Air Traffic Control' },
  { value: 17, label: 'American History' },
  { value: 18, label: 'American Literature' },
  { value: 19, label: 'American Sign Language' },
  { value: 20, label: 'American Studies' },
  { value: 21, label: 'Anatomy' },
  { value: 22, label: 'Ancient Studies' },
  { value: 23, label: 'Animal Behavior and Ethology' },
  { value: 24, label: 'Animal Science' },
  { value: 25, label: 'Animation and Special Effects' },
  { value: 26, label: 'Anthropology' },
  { value: 27, label: 'Applied Mathematics' },
  { value: 28, label: 'Aquaculture' },
  { value: 29, label: 'Aquatic Biology' },
  { value: 30, label: 'Arabic' },
  { value: 31, label: 'Archeology' },
  { value: 32, label: 'Architectural Engineering' },
  { value: 33, label: 'Architectural History' },
  { value: 34, label: 'Architecture' },
  { value: 35, label: 'Art' },
  { value: 36, label: 'Art Education' },
  { value: 37, label: 'Art History' },
  { value: 38, label: 'Art Therapy' },
  { value: 39, label: 'Artificial Intelligence and Robotics' },
  { value: 40, label: 'Asian-American Studies' },
  { value: 41, label: 'Astronomy' },
  { value: 42, label: 'Astrophysics' },
  { value: 43, label: 'Athletic Training' },
  { value: 44, label: 'Atmospheric Science' },
  { value: 45, label: 'Automotive Engineering' },
  { value: 46, label: 'Aviation' },
  { value: 47, label: 'Bakery Science' },
  { value: 48, label: 'Biblical Studies' },
  { value: 49, label: 'Biochemistry' },
  { value: 50, label: 'Bioethics' },
  { value: 51, label: 'Biology' },
  { value: 52, label: 'Biomedical Engineering' },
  { value: 53, label: 'Biomedical Science' },
  { value: 54, label: 'Biopsychology' },
  { value: 55, label: 'Biotechnology' },
  { value: 56, label: 'Botany/Plant Biology' },
  { value: 57, label: 'Business Administration/Management' },
  { value: 58, label: 'Business Communications' },
  { value: 59, label: 'Business Education' },
  { value: 60, label: 'Canadian Studies' },
  { value: 61, label: 'Caribbean Studies' },
  { value: 62, label: 'Cell Biology' },
  { value: 63, label: 'Ceramic Engineering' },
  { value: 64, label: 'Ceramics' },
  { value: 65, label: 'Chemical Engineering' },
  { value: 66, label: 'Chemical Physics' },
  { value: 67, label: 'Chemistry' },
  { value: 68, label: 'Child Care' },
  { value: 69, label: 'Child Development' },
  { value: 70, label: 'Chinese' },
  { value: 71, label: 'Chiropractic' },
  { value: 72, label: 'Church Music' },
  { value: 73, label: 'Cinematography and Film/Video Production' },
  { value: 74, label: 'Circulation Technology' },
  { value: 75, label: 'Civil Engineering' },
  { value: 76, label: 'Classics' },
  { value: 77, label: 'Clinical Psychology' },
  { value: 78, label: 'Cognitive Psychology' },
  { value: 79, label: 'Communication Disorders' },
  { value: 80, label: 'Communications Studies/Speech Communication and Rhetoric' },
  { value: 81, label: 'Comparative Literature' },
  { value: 82, label: 'Computer and Information Science' },
  { value: 83, label: 'Computer Engineering' },
  { value: 84, label: 'Computer Graphics' },
  { value: 85, label: 'Computer Systems Analysis' },
  { value: 86, label: 'Construction Management' },
  { value: 87, label: 'Counseling' },
  { value: 88, label: 'Crafts' },
  { value: 89, label: 'Creative Writing' },
  { value: 90, label: 'Criminal Science' },
  { value: 91, label: 'Criminology' },
  { value: 92, label: 'Culinary Arts' },
  { value: 93, label: 'Dance' },
  { value: 94, label: 'Data Processing' },
  { value: 95, label: 'Dental Hygiene' },
  { value: 96, label: 'Developmental Psychology' },
  { value: 97, label: 'Diagnostic Medical Sonography' },
  { value: 98, label: 'Dietetics' },
  { value: 99, label: 'Digital Communications and Media/Multimedia' },
  { value: 100, label: 'Drawing' },
  { value: 101, label: 'Early Childhood Education' },
  { value: 102, label: 'East Asian Studies' },
  { value: 103, label: 'East European Studies' },
  { value: 104, label: 'Ecology' },
  { value: 105, label: 'Economics' },
  { value: 106, label: 'Education' },
  { value: 107, label: 'Education Administration' },
  { value: 108, label: 'Education of the Deaf' },
  { value: 109, label: 'Educational Psychology' },
  { value: 110, label: 'Electrical Engineering' },
  { value: 111, label: 'Elementary Education' },
  { value: 112, label: 'Engineering Mechanics' },
  { value: 113, label: 'Engineering Physics' },
  { value: 114, label: 'English' },
  { value: 115, label: 'English Composition' },
  { value: 116, label: 'English Literature' },
  { value: 117, label: 'Entomology' },
  { value: 118, label: 'Entrepreneurship' },
  { value: 119, label: 'Environmental Design/Architecture' },
  { value: 120, label: 'Environmental Science' },
  { value: 121, label: 'Environmental/Environmental Health Engineering' },
  { value: 122, label: 'Epidemiology' },
  { value: 123, label: 'Equine Studies' },
  { value: 124, label: 'Ethnic Studies' },
  { value: 125, label: 'European History' },
  { value: 126, label: 'Experimental Pathology' },
  { value: 127, label: 'Experimental Psychology' },
  { value: 128, label: 'Fashion Design' },
  { value: 129, label: 'Fashion Merchandising' },
  { value: 130, label: 'Feed Science' },
  { value: 131, label: 'Fiber, Textiles, and Weaving Arts' },
  { value: 132, label: 'Film' },
  { value: 133, label: 'Finance' },
  { value: 134, label: 'Floriculture' },
  { value: 135, label: 'Food Science' },
  { value: 136, label: 'Forensic Science' },
  { value: 137, label: 'Forestry' },
  { value: 138, label: 'French' },
  { value: 139, label: 'Furniture Design' },
  { value: 140, label: 'Game Design' },
  { value: 141, label: 'Gay and Lesbian Studies' },
  { value: 142, label: 'Genetics' },
  { value: 143, label: 'Geography' },
  { value: 144, label: 'Geological Engineering' },
  { value: 145, label: 'Geology' },
  { value: 146, label: 'Geophysics' },
  { value: 147, label: 'German' },
  { value: 148, label: 'Gerontology' },
  { value: 149, label: 'Government' },
  { value: 150, label: 'Graphic Design' },
  { value: 151, label: 'Health Administration' },
  { value: 152, label: 'Hebrew' },
  { value: 153, label: 'Hispanic-American, Puerto Rican, and Chicano Studies' },
  { value: 154, label: 'Historic Preservation' },
  { value: 155, label: 'History' },
  { value: 156, label: 'Home Economics' },
  { value: 157, label: 'Horticulture' },
  { value: 158, label: 'Hospitality' },
  { value: 159, label: 'Human Development' },
  { value: 160, label: 'Human Resources Management' },
  { value: 161, label: 'Illustration' },
  { value: 162, label: 'Industrial Design' },
  { value: 163, label: 'Industrial Engineering' },
  { value: 164, label: 'Industrial Management' },
  { value: 165, label: 'Industrial Psychology' },
  { value: 166, label: 'Information Technology' },
  { value: 167, label: 'Interior Architecture' },
  { value: 168, label: 'Interior Design' },
  { value: 169, label: 'International Agriculture' },
  { value: 170, label: 'International Business' },
  { value: 171, label: 'International Relations' },
  { value: 172, label: 'International Studies' },
  { value: 173, label: 'Islamic Studies' },
  { value: 174, label: 'Italian' },
  { value: 175, label: 'Japanese' },
  { value: 176, label: 'Jazz Studies' },
  { value: 177, label: 'Jewelry and Metalsmithing' },
  { value: 178, label: 'Jewish Studies' },
  { value: 179, label: 'Journalism' },
  { value: 180, label: 'Kinesiology' },
  { value: 181, label: 'Korean' },
  { value: 182, label: 'Land Use Planning and Management' },
  { value: 183, label: 'Landscape Architecture' },
  { value: 184, label: 'Landscape Horticulture' },
  { value: 185, label: 'Latin American Studies' },
  { value: 186, label: 'Library Science' },
  { value: 187, label: 'Linguistics' },
  { value: 188, label: 'Logistics Management' },
  { value: 189, label: 'Management Information Systems' },
  { value: 190, label: 'Managerial Economics' },
  { value: 191, label: 'Marine Biology' },
  { value: 192, label: 'Marine Science' },
  { value: 193, label: 'Marketing' },
  { value: 194, label: 'Mass Communication' },
  { value: 195, label: 'Massage Therapy' },
  { value: 196, label: 'Materials Science' },
  { value: 197, label: 'Mathematics' },
  { value: 198, label: 'Mechanical Engineering' },
  { value: 199, label: 'Medical Technology' },
  { value: 200, label: 'Medieval and Renaissance Studies' },
  { value: 201, label: 'Mental Health Services' },
  { value: 202, label: 'Merchandising and Buying Operations' },
  { value: 203, label: 'Metallurgical Engineering' },
  { value: 204, label: 'Microbiology' },
  { value: 205, label: 'Middle Eastern Studies' },
  { value: 206, label: 'Military Science' },
  { value: 207, label: 'Mineral Engineering' },
  { value: 208, label: 'Missions' },
  { value: 209, label: 'Modern Greek' },
  { value: 210, label: 'Molecular Biology' },
  { value: 211, label: 'Molecular Genetics' },
  { value: 212, label: 'Mortuary Science' },
  { value: 213, label: 'Museum Studies' },
  { value: 214, label: 'Music' },
  { value: 215, label: 'Music Education' },
  { value: 216, label: 'Music History' },
  { value: 217, label: 'Music Management' },
  { value: 218, label: 'Music Therapy' },
  { value: 219, label: 'Music Theater' },
  { value: 220, label: 'Native American Studies' },
  { value: 221, label: 'Natural Resources Conservation' },
  { value: 222, label: 'Naval Architecture' },
  { value: 223, label: 'Neurobiology' },
  { value: 224, label: 'Neuroscience' },
  { value: 225, label: 'Nuclear Engineering' },
  { value: 226, label: 'Nursing' },
  { value: 227, label: 'Nutrition' },
  { value: 228, label: 'Occupational Therapy' },
  { value: 229, label: 'Ocean Engineering' },
  { value: 230, label: 'Oceanography' },
  { value: 231, label: 'Operations Management' },
  { value: 232, label: 'Organizational Behavior Studies' },
  { value: 233, label: 'Painting' },
  { value: 234, label: 'Paleontology' },
  { value: 235, label: 'Pastoral Studies' },
  { value: 236, label: 'Peace Studies' },
  { value: 237, label: 'Petroleum Engineering' },
  { value: 238, label: 'Pharmacology' },
  { value: 239, label: 'Pharmacy' },
  { value: 240, label: 'Philosophy' },
  { value: 241, label: 'Photography' },
  { value: 242, label: 'Photojournalism' },
  { value: 243, label: 'Physical Education' },
  { value: 244, label: 'Physical Therapy' },
  { value: 245, label: 'Physician Assistant' },
  { value: 246, label: 'Physics' },
  { value: 247, label: 'Physiological Psychology' },
  { value: 248, label: 'Piano' },
  { value: 249, label: 'Planetary Science' },
  { value: 250, label: 'Plant Pathology' },
  { value: 251, label: 'Playwriting and Screenwriting' },
  { value: 252, label: 'Political Communication' },
  { value: 253, label: 'Political Science' },
  { value: 254, label: 'Portuguese' },
  { value: 255, label: 'Pre-Dentistry' },
  { value: 256, label: 'Pre-Law' },
  { value: 257, label: 'Pre-Medicine' },
  { value: 258, label: 'Pre-Optometry' },
  { value: 259, label: 'Pre-Seminary' },
  { value: 260, label: 'Pre-Veterinary Medicine' },
  { value: 261, label: 'Printmaking' },
  { value: 262, label: 'Psychology' },
  { value: 263, label: 'Public Administration' },
  { value: 264, label: 'Public Health' },
  { value: 265, label: 'Public Policy Analysis' },
  { value: 266, label: 'Public Relations' },
  { value: 267, label: 'Radio and Television' },
  { value: 268, label: 'Radiologic Technology' },
  { value: 269, label: 'Range Science and Management' },
  { value: 270, label: 'Real Estate' },
  { value: 271, label: 'Recording Arts Technology' },
  { value: 272, label: 'Recreation Management' },
  { value: 273, label: 'Rehabilitation Services' },
  { value: 274, label: 'Religious Studies' },
  { value: 275, label: 'Respiratory Therapy' },
  { value: 276, label: 'Risk Management' },
  { value: 277, label: 'Rural Sociology' },
  { value: 278, label: 'Russian' },
  { value: 279, label: 'Scandinavian Studies' },
  { value: 280, label: 'Sculpture' },
  { value: 281, label: 'Slavic Languages and Literatures' },
  { value: 282, label: 'Social Psychology' },
  { value: 283, label: 'Social Work' },
  { value: 284, label: 'Sociology' },
  { value: 285, label: 'Soil Science' },
  { value: 286, label: 'Sound Engineering' },
  { value: 287, label: 'South Asian Studies' },
  { value: 288, label: 'Southeast Asia Studies' },
  { value: 289, label: 'Spanish' },
  { value: 290, label: 'Special Education' },
  { value: 291, label: 'Speech Pathology' },
  { value: 292, label: 'Sport and Leisure Studies' },
  { value: 293, label: 'Sports Management' },
  { value: 294, label: 'Statistics' },
  { value: 295, label: 'Surveying' },
  { value: 296, label: 'Sustainable Resource Management' },
  { value: 297, label: 'Teacher Education' },
  { value: 298, label: 'Teaching English as a Second Language' },
  { value: 299, label: 'Technical Writing' },
  { value: 300, label: 'Technology Education' },
  { value: 301, label: 'Textile Engineering' },
  { value: 302, label: 'Theatre' },
  { value: 303, label: 'Theology' },
  { value: 304, label: 'Tourism' },
  { value: 305, label: 'Toxicology' },
  { value: 306, label: 'Turfgrass Science' },
  { value: 307, label: 'Urban Planning' },
  { value: 308, label: 'Urban Studies' },
  { value: 309, label: 'Visual Communication' },
  { value: 310, label: 'Voice' },
  { value: 311, label: 'Web Design' },
  { value: 312, label: 'Webmaster and Web Management' },
  { value: 313, label: 'Welding Engineering' },
  { value: 314, label: 'Wildlife Management' },
  { value: 315, label: "Women's Studies" },
  { value: 316, label: 'Youth Ministries' },
  { value: 317, label: 'Zoology' },
];

// Backend enum: universities.py
export const universities = _.memoize(() => [
  { value: -1, label: 'Other' },
  { value: 0, label: 'Abilene Christian University (acu.edu)' },
  { value: 1, label: 'Adelphi University (adelphi.edu)' },
  { value: 2, label: 'Agnes Scott College (scottlan.edu)' },
  { value: 3, label: 'Air Force Institute of Technology (afit.af.mil)' },
  { value: 4, label: 'Alabama A&M University (aamu.edu)' },
  { value: 5, label: 'Alabama State University (alasu.edu)' },
  { value: 6, label: 'Alaska Pacific University' },
  { value: 7, label: 'Albertson College of Idaho (acofi.edu)' },
  { value: 8, label: 'Albion College (albion.edu)' },
  { value: 9, label: 'Alderson-Broaddus College' },
  { value: 10, label: 'Alfred University (alfred.edu)' },
  { value: 11, label: 'Allegheny College (alleg.edu)' },
  { value: 12, label: 'Allentown College of Saint Francis de Sales (allencol.edu)' },
  { value: 13, label: 'Alma College (alma.edu)' },
  { value: 14, label: 'Alverno College (alverno.edu)' },
  { value: 15, label: 'Ambassador University (ambassador.edu)' },
  { value: 16, label: 'American Coastline University (amercoastuniv.edu)' },
  { value: 17, label: 'American Graduate School of International Management (t-bird.edu)' },
  { value: 18, label: 'American International College (aic.edu)' },
  { value: 19, label: 'American University (american.edu)' },
  { value: 20, label: 'Amherst College (amherst.edu)' },
  { value: 21, label: 'Andrews University (andrews.edu)' },
  { value: 22, label: 'Angelo State University (angelo.edu)' },
  { value: 23, label: 'Antioch College (college.antioch.edu)' },
  { value: 24, label: 'Antioch New England (antiochne.edu)' },
  { value: 25, label: 'Antioch University-Los Angeles (antiochla.edu)' },
  { value: 26, label: 'Antioch University-Seattle (seattleantioch.edu)' },
  { value: 27, label: 'Appalachian State University (appstate.edu)' },
  { value: 28, label: 'Aquinas College (aquinas.edu)' },
  { value: 29, label: 'Arizona State University (asu.edu)' },
  { value: 30, label: 'Arizona State University East' },
  { value: 31, label: 'Arizona State University West (west.asu.edu)' },
  { value: 32, label: 'Arizona Western College (awc.cc.az.us)' },
  { value: 33, label: 'Arkansas State University, Jonesboro (astate.edu)' },
  { value: 34, label: 'Arkansas Tech University (atu.edu)' },
  { value: 35, label: 'Armstrong State College (armstrong.edu)' },
  { value: 36, label: 'Ashland University (ashland.edu)' },
  { value: 37, label: 'Assumption College (assumption.edu)' },
  { value: 38, label: 'Athens State College' },
  { value: 39, label: 'Auburn University (auburn.edu)' },
  { value: 40, label: 'Auburn University - Montgomery (aum.edu)' },
  { value: 41, label: 'Augsburg College (augsburg.edu)' },
  { value: 42, label: 'Augustana College (IL) (augustana.edu)' },
  { value: 43, label: 'Augustana College (SD) (augie.edu)' },
  { value: 44, label: 'Aurora University (aurora.edu)' },
  { value: 45, label: 'Austin College (austinc.edu)' },
  { value: 46, label: 'Austin Peay State University (apsu.edu)' },
  { value: 47, label: 'Averett College (averett.edu)' },
  { value: 48, label: 'Avila College (avila.edu)' },
  { value: 49, label: 'Azusa Pacific University (apu.edu)B' },
  { value: 50, label: 'Babson College (babson.edu)' },
  { value: 51, label: 'Baldwin-Wallace College (baldwinw.edu)' },
  { value: 52, label: 'Ball State University (bsu.edu)' },
  { value: 53, label: 'Baker University (bakeru.edu)' },
  { value: 54, label: 'Baptist Bible College (bbc.edu)' },
  { value: 55, label: 'Bard College (bard.edu)' },
  { value: 56, label: 'Barry University (barry.edu)' },
  { value: 57, label: 'Bastyr University (bastyr.edu)' },
  { value: 58, label: 'Bates College (bates.edu)' },
  { value: 59, label: 'Baylor College of Medicine (bcm.tmc.edu)' },
  { value: 60, label: 'Baylor University (baylor.edu)' },
  { value: 61, label: 'Beaver College (beaver.edu)' },
  { value: 62, label: 'Belmont University (belmont.edu)' },
  { value: 63, label: 'Beloit College (beloit.edu)' },
  { value: 64, label: 'Bemidji State University (bemidji.msus.edu)' },
  { value: 65, label: 'Benedictine College (benedictine.edu)' },
  { value: 66, label: 'Bennington College (bennington.edu)' },
  { value: 67, label: 'Bentley College (bentley.edu)' },
  { value: 68, label: 'Berea College (berea.edu)' },
  { value: 69, label: 'Berklee College of Music (berklee.edu)' },
  { value: 70, label: 'Bethany College (CA) (bethany.edu)' },
  { value: 71, label: 'Bethany College (WV) (bethany.wvnet.edu)' },
  { value: 72, label: 'Bethel College (KS) (bethelks.edu)' },
  { value: 73, label: 'Bethel College and Seminary (MN) (bethel.edu)' },
  { value: 74, label: 'Biola University (biola.edu)' },
  { value: 75, label: 'Birmingham-Southern College (bsc.edu)' },
  { value: 76, label: 'Black Hills State University (bhsu.edu)' },
  { value: 77, label: 'Bloomsburg University of Pennsylvania (bloomu.edu)' },
  { value: 78, label: 'Bluffton College (bluffton.edu)' },
  { value: 79, label: 'Bob Jones University (bju.edu)' },
  { value: 80, label: 'Boise State University (idbsu.edu)' },
  { value: 81, label: 'Boston College (bc.edu)' },
  { value: 82, label: 'Boston Graduate School of Psychoanalysis (bgsp.edu)' },
  { value: 83, label: 'Boston University (bu.edu)' },
  { value: 84, label: 'Bowdoin College (bowdoin.edu)' },
  { value: 85, label: 'Bowie State University (bsu.umd.edu)' },
  { value: 86, label: 'Bowling Green State University (bgsu.edu)' },
  { value: 87, label: 'Bradley University (bradley.edu)' },
  { value: 88, label: 'Brandeis University (brandeis.edu)' },
  { value: 89, label: 'Brenau University (brenau.edu)' },
  { value: 90, label: 'Briar Cliff College (briar-cliff.edu)' },
  { value: 91, label: 'Bridgewater College (bridgewater.edu)' },
  { value: 92, label: 'Brigham Young University (byu.edu)' },
  { value: 93, label: 'Brigham Young University Hawaii (byuh.edu)' },
  { value: 94, label: 'Brown University (brown.edu)' },
  { value: 95, label: 'Bryant College (bryant.edu)' },
  { value: 96, label: 'Bryn Mawr College (brynmawr.edu)' },
  { value: 97, label: 'Bucknell University (bucknell.edu)' },
  { value: 98, label: 'Buena Vista University (bvu.edu)' },
  { value: 99, label: 'Butler University (butler.edu)C' },
  { value: 100, label: 'California Coast University (calcoastuniv.edu)' },
  { value: 101, label: 'California Institute of Technology (caltech.edu)' },
  { value: 102, label: 'California Lutheran University (callutheran.edu)' },
  { value: 103, label: 'California Maritime Academy (csum.edu)' },
  { value: 104, label: 'California National University (cnuas.edu)' },
  { value: 105, label: 'California Pacific University' },
  { value: 106, label: 'California Polytechnic State University, San Luis Obispo (calpoly.edu)' },
  { value: 107, label: 'California School of Professional Psychology' },
  { value: 108, label: 'California State Polytechnic University, Pomona (csupomona.edu)' },
  { value: 109, label: 'California State University System (calstate.edu)' },
  { value: 110, label: 'California State University, Bakersfield (csubak.edu)' },
  { value: 111, label: 'California State University, Chico (csuchico.edu)' },
  { value: 112, label: 'California State University, Dominguez Hills (csudh.edu)' },
  { value: 113, label: 'California State University, Fresno (csufresno.edu)' },
  { value: 114, label: 'California State University, Fullerton (fullerton.edu)' },
  { value: 115, label: 'California State University, Hayward (csuhayward.edu)' },
  { value: 116, label: 'California State University, Long Beach (csulb.edu)' },
  { value: 117, label: 'California State University, Los Angeles (calstatela.edu)' },
  { value: 118, label: 'California State University, Monterey Bay (monterey.edu)' },
  { value: 119, label: 'California State University, Northridge (csun.edu)' },
  { value: 121, label: 'California State University, San Bernardino (csusb.edu)' },
  { value: 122, label: 'California State University, San Jose (sjsu.edu)' },
  { value: 123, label: 'California State University, San Marcos (csusm.edu)' },
  { value: 124, label: 'California State University, Sacramento (csus.edu)' },
  { value: 125, label: 'California State University, Stanislaus (csustan.edu)' },
  { value: 126, label: 'California University of Pennsylvania (cup.edu)' },
  { value: 127, label: 'Calvin College (calvin.edu)' },
  { value: 128, label: 'Campbell University (campbell.edu)' },
  { value: 129, label: 'Campbellsville College (campbellsvil.edu)' },
  { value: 130, label: 'Cameron University (cameron.edu)' },
  { value: 131, label: 'Canisius College (canisius.edu)' },
  { value: 132, label: 'Carleton College (carleton.edu)' },
  { value: 133, label: 'Carlow College (carlow.edu)' },
  { value: 134, label: 'Carnegie Mellon University (cmu.edu)' },
  { value: 135, label: 'Carroll College (MT) (carroll.edu)' },
  { value: 136, label: 'Carroll College (WI) (cc.edu)' },
  { value: 137, label: 'Carson-Newman College (cn.edu)' },
  { value: 138, label: 'Carthage College (carthage.edu)' },
  { value: 139, label: 'Case Western Reserve University (cwru.edu)' },
  { value: 140, label: 'Castleton State University (csc.vsc.edu)' },
  { value: 141, label: 'The Catholic University of America (cua.edu)' },
  { value: 142, label: 'Cedarville College (cedarville.edu)' },
  { value: 143, label: 'Centenary College of Louisiana (centenary.edu)' },
  { value: 144, label: 'Central College (central.edu)' },
  { value: 145, label: 'Central Connecticut State University (ccsu.ctstateu.edu)' },
  { value: 146, label: 'Central Methodist College (cmc.edu)' },
  { value: 147, label: 'Central Michigan University (cmich.edu)' },
  { value: 148, label: 'Central Missouri State University (cmsu.edu)' },
  { value: 149, label: 'Central Washington University (cwu.edu)' },
  { value: 150, label: 'Centre College (centre.edu)' },
  { value: 151, label: 'Chadron State College (csc.edu)' },
  { value: 152, label: 'Champlain College (champlain.edu)' },
  { value: 153, label: 'Chapman University (chapman.edu)' },
  { value: 154, label: 'Chatham College (chatham.edu)' },
  { value: 155, label: 'Chesapeake College (chesapeake.edu)' },
  { value: 156, label: 'Cheyney University (cheyney.edu)' },
  { value: 157, label: 'The Chicago School of Professional Psychology (csopp.edu)' },
  { value: 158, label: 'Christian Brothers University (cbu.edu)' },
  { value: 159, label: 'Christian Theological Seminary (cts.edu)' },
  { value: 160, label: 'Christopher Newport University (cnu.edu)' },
  { value: 161, label: 'The Citadel (citadel.edu)' },
  { value: 162, label: 'City University (cityu.edu)' },
  { value: 163, label: 'City University of New York (cuny.edu)' },
  { value: 164, label: 'Claremont Graduate School (cgs.edu)' },
  { value: 165, label: 'Claremont McKenna College (mckenna.edu)' },
  { value: 166, label: 'Clarion University of Pennsylvania (clarion.edu)' },
  { value: 167, label: 'Clark University (clarku.edu)' },
  { value: 168, label: 'Clarke College (clarke.edu)' },
  { value: 169, label: 'Clarkson University (clarkson.edu)' },
  { value: 170, label: 'Clayton State College (csc.peachnet.edu)' },
  { value: 171, label: 'Clemson University (clemson.edu)' },
  { value: 172, label: 'Cleveland State University (csuohio.edu)' },
  { value: 173, label: 'Clinch Valley College (clinch.edu)' },
  { value: 174, label: 'Coastal Carolina University (coastal.edu)' },
  { value: 175, label: 'Coe College (coe.edu)' },
  { value: 176, label: 'Coker College (coker.edu)' },
  { value: 177, label: 'Colby College (colby.edu)' },
  { value: 178, label: 'Colgate University (colgate.edu)' },
  { value: 179, label: 'College of the Atlantic (coa.edu)' },
  { value: 180, label: 'College of Charleston (cofc.edu)' },
  { value: 181, label: 'College of Eastern Utah (ceu.edu)' },
  { value: 182, label: 'College of the Holy Cross (holycross.edu)' },
  { value: 183, label: 'College of Saint Benedict (csbsju.edu)' },
  { value: 184, label: 'College of Saint Catherine (stkate.edu)' },
  { value: 185, label: 'College of St. Francis (stfrancis.edu)' },
  { value: 186, label: 'College of Saint Rose (strose.edu)' },
  { value: 187, label: 'College of St. Scholastica (css.edu)' },
  { value: 188, label: 'College of William and Mary (wm.edu)' },
  { value: 189, label: 'The College of Wooster (wooster.edu)' },
  { value: 190, label: 'Colorado Christian University (ccu.edu)' },
  { value: 191, label: 'Colorado College (cc.colorado.edu)' },
  { value: 192, label: 'Colorado School of Mines (mines.colorado.edu)' },
  { value: 193, label: 'Colorado State University (colostate.edu)' },
  { value: 194, label: 'Columbia College Chicago (colum.edu)' },
  { value: 195, label: 'Columbia Southern University (colsouth.edu)' },
  { value: 196, label: 'Columbia Union College (cuc.edu)' },
  { value: 197, label: 'Columbia University (columbia.edu)' },
  { value: 198, label: 'Concordia College-Ann Arbor (ccaa.edu)' },
  { value: 199, label: 'Concordia College-Moorhead (cord.edu)' },
  { value: 200, label: 'Concordia College-St. Paul (csp.edu)' },
  { value: 201, label: 'Concordia College-Seward (ccsn.edu)' },
  { value: 202, label: 'Concordia University River Forest, Illinois (cuis.edu)' },
  { value: 203, label: 'Connecticut College (conncoll.edu)' },
  { value: 204, label: 'The Cooper Union for the Advancement of Science and Art (cooper.edu)' },
  { value: 205, label: 'Coppin State College (coppin.umd.edu)' },
  { value: 206, label: 'Cornell College (cornell-iowa.edu)' },
  { value: 207, label: 'Cornell University (cornell.edu)' },
  { value: 208, label: 'Cornerstone College' },
  { value: 209, label: 'Creighton University (creighton.edu)' },
  { value: 210, label: 'Curry College (curry.edu)D' },
  { value: 211, label: 'Daemen College (daemen.edu)' },
  { value: 212, label: 'Dakota State University (dsu.edu)' },
  { value: 213, label: 'Dakota Wesleyan University (dwu.edu)' },
  { value: 214, label: 'Dallas Baptist University (dbu.edu)' },
  { value: 215, label: 'Dana College (dana.edu)' },
  { value: 216, label: 'Daniel Webster College (dwc.edu)' },
  { value: 217, label: 'Dartmouth College (dartmouth.edu)' },
  { value: 218, label: 'Davenport College Detroit College of Business (davenport.edu)' },
  { value: 219, label: 'Davidson College (davidson.edu)' },
  { value: 220, label: 'Davis & Elkins College (dne.wvnet.edu)' },
  { value: 221, label: 'Delaware State University (dsc.edu)' },
  { value: 222, label: 'Delta State University (deltast.edu)' },
  { value: 223, label: 'Denison University (denison.edu)' },
  { value: 224, label: 'DePaul University (depaul.edu)' },
  { value: 225, label: 'DePauw University (depauw.edu)' },
  { value: 226, label: 'DeVry Institute of Technology (devrycols.edu)' },
  { value: 227, label: 'DeVry Institute of Technology-Dallas (devry.edu)' },
  { value: 228, label: 'DeVry Institute of Technology-Phoenix (devry-phx.edu)' },
  { value: 229, label: 'Dickinson College (dickinson.edu)' },
  { value: 230, label: 'Dickinson State University (dsu.nodak.edu)' },
  { value: 231, label: 'Dillard University (dillard.edu)' },
  { value: 232, label: 'Dominican College (dominican.edu)' },
  { value: 233, label: 'Dordt College (Dordt.edu)' },
  { value: 234, label: 'Dowling College (dowling.edu)' },
  { value: 235, label: 'Drake University (drake.edu)' },
  { value: 236, label: 'Drew University (drew.edu)' },
  { value: 237, label: 'Drexel University (drexel.edu)' },
  { value: 238, label: 'Drury College (drury.edu)' },
  { value: 239, label: 'Duke University (duke.edu)' },
  { value: 240, label: 'Duquesne University (duq.edu)E' },
  { value: 241, label: 'Earlham College (earlham.edu)' },
  { value: 242, label: 'East Carolina University (ecu.edu)' },
  { value: 243, label: 'East Central University (ecok.edu)' },
  { value: 244, label: 'East Stroudsburg State University of Pennsylvania (esu.edu)' },
  { value: 245, label: 'East Tennessee State University (etsu-tn.edu)' },
  { value: 246, label: 'East Texas State University (etsu.edu)' },
  { value: 247, label: 'Eastern Connecticut State University (ecsu.ctstateu.edu)' },
  { value: 248, label: 'Eastern Illinois University (eiu.edu)' },
  { value: 249, label: 'Eastern Kentucky University (eku.edu)' },
  { value: 250, label: 'Eastern Mennonite University (emu.edu)' },
  { value: 251, label: 'Eastern Michigan University (emich.edu)' },
  { value: 252, label: 'Eastern Nazarene College' },
  { value: 253, label: 'Eastern New Mexico University (enmu.edu)' },
  { value: 254, label: 'Eastern Oregon State College (eosc.osshe.edu)' },
  { value: 255, label: 'Eastern Washington University (ewu.edu)' },
  { value: 256, label: 'Edgewood College (edgewood.edu)' },
  { value: 257, label: 'Edinboro University of Pennsylvania (edinboro.edu)' },
  { value: 258, label: 'Elizabeth City State University (ecsu.edu)' },
  { value: 259, label: 'Elizabethtown College (etown.edu)' },
  { value: 260, label: 'Elmhurst College (elmhurst.edu)' },
  { value: 261, label: 'Elon College (elon.edu)' },
  { value: 262, label: 'Embry-Riddle Aeronautical University, Arizona (erau.edu)' },
  { value: 263, label: 'Embry-Riddle Aeronautical University, Florida (erau.edu)' },
  { value: 264, label: 'Emerson College (emerson.edu)' },
  { value: 265, label: 'Emmanuel College (emmanuel.edu)' },
  { value: 266, label: 'Emmaus Bible College' },
  { value: 267, label: 'Emporia State University (emporia.edu)' },
  { value: 268, label: 'Emory & Henry College' },
  { value: 269, label: 'Emory University (emory.edu)' },
  { value: 270, label: 'Evergreen State College (evergreen.edu)F' },
  { value: 271, label: 'Fairfield University' },
  { value: 272, label: 'Fairleigh Dickinson University (fdu.edu)' },
  { value: 273, label: 'Fairmont State College (fairmont.wvnet.edu)' },
  { value: 274, label: 'Fayetteville State University (fsufay.edu)' },
  { value: 275, label: 'Ferris State University (ferris.edu)' },
  { value: 276, label: 'Fielding Institute (fielding.edu)' },
  { value: 277, label: 'Fisk University (fisk.edu)' },
  { value: 278, label: 'Fitchburg State College (fsc.edu)' },
  { value: 279, label: 'Florida Agricultural and Mechanical University (famu.edu)' },
  { value: 280, label: 'Florida Atlantic University (fau.edu)' },
  { value: 281, label: 'Florida Gulf Coast University (fgcu.edu)' },
  { value: 282, label: 'Florida Institute of Technology (fit.edu)' },
  { value: 283, label: 'Florida International University (fiu.edu)' },
  { value: 284, label: 'Florida State University (fsu.edu)' },
  { value: 285, label: 'Fontbonne College (fontbonne.edu)' },
  { value: 286, label: 'Fordham University (fordham.edu)' },
  { value: 287, label: 'Fort Hays State University (fhsu.edu)' },
  { value: 288, label: 'Fort Lewis College (fortlewis.edu)' },
  { value: 289, label: 'Franciscan University' },
  { value: 290, label: 'Franklin and Marshall College (fandm.edu)' },
  { value: 291, label: 'Franklin Pierce Law Center (fplc.edu)' },
  { value: 292, label: 'Franklin University (franklin.edu)' },
  { value: 293, label: 'Fresno Pacific University (fresno.edu)' },
  { value: 294, label: 'Friends University (friends.edu)' },
  { value: 295, label: 'Frostburg State University (fsu.umd.edu)' },
  { value: 296, label: 'Fuller Theological Seminary (fuller.edu)' },
  { value: 297, label: 'Furman University (furman.edu)G' },
  { value: 298, label: 'Gallaudet University (gallaudet.edu)' },
  { value: 299, label: 'Gannon University (gannon.edu)' },
  { value: 300, label: 'Geneva College (geneva.edu)' },
  { value: 301, label: 'George Fox College (gfc.edu)' },
  { value: 302, label: 'George Mason University (gmu.edu)' },
  { value: 303, label: 'George Washington University (gwu.edu)' },
  { value: 304, label: 'Georgetown University (georgetown.edu)' },
  { value: 305, label: 'Georgia College (gac.peachnet.edu)' },
  { value: 306, label: 'Georgia Institute of Technology (gatech.edu)' },
  { value: 307, label: 'Georgia Southern University (gasou.edu)' },
  { value: 308, label: 'Georgia Southwestern College (gsw.peachnet.edu)' },
  { value: 309, label: 'Georgia State University (gsu.edu)' },
  { value: 310, label: 'Georgian Court College (georgian.edu)' },
  { value: 311, label: 'Gettysburg College (gettysburg.edu)' },
  { value: 312, label: 'GMI Engineering and Management Institute (gmi.edu)' },
  { value: 313, label: 'Golden Gate University (ggu.edu)' },
  { value: 314, label: 'Goldey-Beacom College (gbc.edu)' },
  { value: 315, label: 'Gonzaga University (gonzaga.edu)' },
  { value: 316, label: 'Goshen College (goshen.edu)' },
  { value: 317, label: 'Goucher College (goucher.edu)' },
  { value: 318, label: 'Governors State University (bgu.edu)' },
  { value: 319, label: 'Grace College (grace.edu)' },
  { value: 320, label: 'Graceland College (graceland.edu)' },
  { value: 321, label: 'Grand Valley State University (gvsu.edu)' },
  { value: 322, label: 'Greenleaf University (greenleaf.edu)' },
  { value: 323, label: 'Grinnell College (grin.edu)' },
  { value: 324, label: 'Guilford College (guilford.edu)' },
  { value: 325, label: 'Gustavus Adolphus College (gac.edu)' },
  { value: 326, label: 'Gutenberg CollegeH' },
  { value: 327, label: 'Hamilton College (hamilton.edu)' },
  { value: 328, label: 'Hamline University (hamline.edu)' },
  { value: 329, label: 'Hampden-Sydney College (hsc.edu)' },
  { value: 330, label: 'Hampshire College (hampshire.edu)' },
  { value: 331, label: 'Hampton University (hamptonu.edu)' },
  { value: 332, label: 'Hanover College (hanover.edu)' },
  { value: 333, label: 'Harding University (harding.edu)' },
  { value: 334, label: 'Hartwick College (hartwick.edu)' },
  { value: 335, label: 'Harvard University (harvard.edu)' },
  { value: 336, label: 'Harvey Mudd College (hmc.edu)' },
  { value: 337, label: 'Haskell Indian Nations University (haskell.edu)' },
  { value: 338, label: 'Hastings College (hastings.edu)' },
  { value: 339, label: 'Haverford College in Pennsylvania (haverford.edu)' },
  { value: 340, label: 'Hawaii Pacific University (hpu.edu)' },
  { value: 341, label: 'Heidelberg College (heidelberg.edu)' },
  { value: 342, label: 'Hendrix College (hendrix.edu)' },
  { value: 343, label: 'Hesston College (hesston.edu)' },
  { value: 344, label: 'High Point University (highpoint.edu)' },
  { value: 345, label: 'Hillsdale College (hillsdale.edu)' },
  { value: 346, label: 'Hiram College (hiram.edu)' },
  { value: 347, label: 'Hobart and William Smith Colleges (hws.edu)' },
  { value: 348, label: 'Hofstra University (hofstra.edu)' },
  { value: 349, label: 'Hollins College (hollins.edu)' },
  { value: 350, label: 'Holy Cross College (holycross.edu)' },
  { value: 351, label: 'Hood College (hood.edu)' },
  { value: 352, label: 'Hope College (hope.edu)' },
  { value: 353, label: 'Howard University (howard.edu)' },
  { value: 354, label: 'Humboldt State University (humboldt.edu)' },
  { value: 355, label: 'Hunter College (hunter.cuny.edu)' },
  { value: 356, label: 'Huntingdon College (huntingdon.edu)' },
  { value: 357, label: 'Huntington College (huntcol.edu)I' },
  { value: 358, label: 'ICI University (ici.edu)' },
  { value: 359, label: 'Idaho State University (isu.edu)' },
  { value: 360, label: 'Illinois Benedictine College (ibc.edu)' },
  { value: 361, label: 'Illinois Institute of Technology (iit.edu)' },
  { value: 362, label: 'Illinois State University (ilstu.edu)' },
  { value: 363, label: 'Incarnate Word College (iwctx.edu)' },
  { value: 364, label: 'Indiana Institute of Technology (indtech.edu)' },
  { value: 365, label: 'Indiana State University (indstate.edu)' },
  { value: 366, label: 'Indiana University System (indiana.edu)' },
  { value: 367, label: 'Indiana University/Purdue University at Columbus (columbus.iupui.edu)' },
  { value: 368, label: 'Indiana University/Purdue University at Fort Wayne (ipfw.indiana.edu)' },
  { value: 369, label: 'Indiana University/Purdue University at Indianapolis (iupui.edu)' },
  { value: 370, label: 'Indiana University at Bloomington (indiana.edu)' },
  { value: 371, label: 'Indiana University at South Bend (iusb.indiana.edu)' },
  { value: 372, label: 'Indiana University of Pennsylvania (iup.edu)' },
  { value: 373, label: 'Indiana University Southeast at New Albany (ius.indiana.edu)' },
  { value: 374, label: 'Indiana Wesleyan University, Marion (indwes.edu)' },
  {
    value: 375,
    label: 'Inter American University of Puerto Rico Metropolitan Campus (metro.inter.edu)',
  },
  { value: 376, label: 'Iona College (iona.edu)' },
  { value: 377, label: 'Iowa State University (iastate.edu)' },
  { value: 378, label: 'Ithaca College (ithaca.edu)J' },
  { value: 379, label: 'Jackson State University (jsums.edu)' },
  { value: 380, label: 'Jacksonville University (ju.edu)' },
  { value: 381, label: 'Jacksonville State University (jsu.edu)' },
  { value: 382, label: 'James Madison University (jmu.edu)' },
  { value: 383, label: 'Jamestown College (jc.edu)' },
  { value: 384, label: 'The Jewish Theological Seminary (jtsa.edu)' },
  { value: 385, label: 'John Brown University (jbu.edu)' },
  { value: 386, label: 'John F. Kennedy University (jfku.edu)' },
  { value: 387, label: 'Johns Hopkins University (jhu.edu)' },
  { value: 388, label: 'Johnson Bible College (jbc.edu)' },
  { value: 389, label: 'Johnson C. Smith University (jcsu.edu)' },
  { value: 390, label: 'Johnson & Wales University (jwu.edu)' },
  { value: 391, label: 'Johnson & Wales University-Charleston' },
  { value: 392, label: 'Jones College (jones.edu)' },
  { value: 393, label: 'Judson College (judson.edu)' },
  { value: 394, label: 'Juniata College (juniata.edu)K' },
  { value: 395, label: 'Kalamazoo College (kzoo.edu)' },
  { value: 396, label: 'Kansas State University (ksu.edu)' },
  { value: 397, label: 'Kansas Wesleyan University (kwu.edu)' },
  { value: 398, label: 'Kean College (kean.edu)' },
  { value: 399, label: 'Keene State College (keene.edu)' },
  { value: 400, label: 'Kent State University (kent.edu)' },
  { value: 401, label: 'Kenyon College (kenyon.edu)' },
  { value: 402, label: "King's College (kings.edu)" },
  { value: 403, label: 'Knox College (knox.edu)' },
  { value: 404, label: 'Kutztown University of Pennsylvania (kutztown.edu)L' },
  { value: 405, label: 'La Sierra University (lasierra.edu)' },
  { value: 406, label: 'LaGrange College (lgc.peachnet.edu)' },
  { value: 407, label: 'Lafayette College (lafayette.edu)' },
  { value: 408, label: 'Lake Forest College (lfc.edu)' },
  { value: 409, label: 'Lake Superior State University (lssu.edu)' },
  { value: 410, label: 'Lamar University (lamar.edu)' },
  { value: 411, label: 'Langston University (lunet.edu)' },
  { value: 412, label: 'LaSalle University (lasalle.edu)' },
  { value: 413, label: 'Lawrence University (lawrence.edu)' },
  { value: 414, label: 'Lawrence Technological University (ltu.edu)' },
  { value: 415, label: 'Lebanon Valley College (lvc.edu)' },
  { value: 416, label: 'Lehigh Univervsity (lehigh.edu)' },
  { value: 417, label: 'Le Moyne College (lemoyne.edu)' },
  { value: 418, label: 'Lenoir-Rhyne College (lrc.edu)' },
  { value: 419, label: 'LeTourneau University (letu.edu)' },
  { value: 420, label: 'Lewis & Clark College (lclark.edu)' },
  { value: 421, label: 'Lewis-Clark State College (lcsc.edu)' },
  { value: 422, label: 'Lewis University (lewisu.edu)' },
  { value: 423, label: 'Liberty University (liberty.edu)' },
  { value: 424, label: 'Lincoln University (lincoln.edu)' },
  { value: 425, label: 'Linfield College (linfield.edu)' },
  { value: 426, label: 'Lock Haven University of Pennsylvania (lhup.edu)' },
  { value: 427, label: 'Loma Linda University (llu.edu)' },
  { value: 428, label: 'Long Island University (liunet.edu)' },
  { value: 429, label: 'Longwood College (lwc.edu)' },
  { value: 430, label: 'Loras College (loras.edu)' },
  { value: 431, label: 'Louisiana College (lacollege.edu)' },
  { value: 432, label: 'Louisiana State University (lsu.edu)' },
  { value: 433, label: 'Louisiana State University at Alexandria (lsua.edu)' },
  { value: 434, label: 'Louisiana State University at Shreveport' },
  { value: 435, label: 'Louisiana Tech University (latech.edu)' },
  { value: 436, label: 'Loyola College (loyola.edu)' },
  { value: 437, label: 'Loyola Marymount University (lmu.edu)' },
  { value: 438, label: 'Loyola University Chicago (luc.edu)' },
  { value: 439, label: 'Luther College (luther.edu)' },
  { value: 440, label: 'Luther Seminary (luthersem.edu)' },
  { value: 441, label: 'Lycoming College (lycoming.edu)' },
  { value: 442, label: 'Lynchburg College (lynchburg.edu)' },
  { value: 443, label: 'Lyndon State College (lsc.vsc.edu)' },
  { value: 444, label: 'Lyon College (lyon.edu)M' },
  { value: 445, label: 'Macalester College (macalstr.edu)' },
  { value: 446, label: 'Maharishi University of Management (mum.edu)' },
  { value: 447, label: 'Maine Maritime Academy' },
  { value: 448, label: 'Malone College (malone.edu)' },
  { value: 449, label: 'Manhattan College (mancol.edu)' },
  { value: 450, label: 'Mankato State University (mankato.msus.edu)' },
  { value: 451, label: 'Mansfield University of Pennsylvania (mnsfld.edu)' },
  { value: 452, label: 'Marietta College (marietta.edu)' },
  { value: 453, label: 'Marist College (marist.edu)' },
  { value: 454, label: 'Marlboro College (marlboro.edu)' },
  { value: 455, label: 'Marquette University (mu.edu)' },
  { value: 456, label: 'Marshall University (marshall.edu)' },
  { value: 457, label: 'Mary Baldwin College (mbc.edu)' },
  { value: 458, label: 'Marymount College (marymt.edu)' },
  { value: 459, label: 'Marymount University (marymount.edu)' },
  { value: 460, label: 'Mary Washington College (mwc.edu)' },
  { value: 461, label: 'Massachusetts Institute of Technology (mit.edu)' },
  { value: 462, label: 'McMurry University (mcm.acu.edu)' },
  { value: 463, label: 'McNeese State University (mcneese.edu)' },
  { value: 464, label: 'Medical College of Georgia (mcg.edu)' },
  { value: 465, label: 'Medical College of Wisconsin (mcw.edu)' },
  { value: 466, label: 'Mercer University (mercer.peachnet.edu)' },
  { value: 467, label: 'Mercyhurst College (mercy.edu)' },
  { value: 468, label: 'Meredith College (meredith.edu)' },
  { value: 469, label: 'Messiah College (messiah.edu)' },
  { value: 470, label: 'Metropolitan State College of Denver (mscd.edu)' },
  { value: 471, label: 'Metropolitan State University (metro.msus.edu)' },
  {
    value: 472,
    label: 'Miami Christian University (mcu.edu) Miami University of Ohio (muohio.edu)',
  },
  { value: 473, label: 'Michigan State University (msu.edu)' },
  { value: 474, label: 'Michigan Technological University (mtu.edu)' },
  { value: 475, label: 'Mid-America Nazarene College (manc.edu)' },
  { value: 476, label: 'Middlebury College (middlebury.edu)' },
  { value: 477, label: 'Middle Georgia College (mgc.peachnet.edu)' },
  { value: 478, label: 'Middle Tennessee State University (mtsu.edu)' },
  { value: 479, label: 'Midwestern State University (mwsu.edu)' },
  { value: 480, label: 'Millersville University of Pennsylvania (millersv.edu)' },
  { value: 481, label: 'Milligan College (milligan-college.tn.us)' },
  { value: 482, label: 'Millikin University (millikin.edu)' },
  { value: 483, label: 'Millsaps College (millsaps.edu)' },
  { value: 484, label: 'Milwaukee School of Engineering (msoe.edu)' },
  { value: 485, label: 'Minot State University (misu.nodak.edu)' },
  { value: 486, label: 'Minneapolis College of Art and Design (mcad.edu)' },
  { value: 487, label: 'Mississippi College (mc.edu)' },
  { value: 488, label: 'Mississippi State University (msstate.edu)' },
  { value: 489, label: 'Mississippi University for Women (muw.edu)' },
  { value: 490, label: 'Missouri Southern State College (mssc.edu)' },
  { value: 491, label: 'Missouri Western State College (mwsc.edu)' },
  { value: 492, label: 'Molloy College (molloy.edu)' },
  { value: 493, label: 'Monmouth College (monm.edu)' },
  { value: 494, label: 'Monmouth University (monmouth.edu)' },
  { value: 495, label: 'Montana State University-Billings (msubillings.edu)' },
  { value: 496, label: 'Montana State University-Bozeman (montana.edu)' },
  { value: 497, label: 'Montana State University-Northern (nmclites.edu)' },
  { value: 498, label: 'Montana Tech (mtech.edu)' },
  { value: 499, label: 'Montclair State University (montclair.edu)' },
  { value: 500, label: 'Montreat College (montreat.edu)' },
  { value: 501, label: 'Moravian College (moravian.edu)' },
  { value: 502, label: 'Moorhead State University (moorhead.msus.edu)' },
  { value: 503, label: 'Morehouse College' },
  { value: 504, label: 'Morgan State University (morgan.edu)' },
  { value: 505, label: 'Mount Senario College (mscfs.edu)' },
  { value: 506, label: 'Mount Holyoke College (mtholyoke.edu)' },
  { value: 507, label: 'Mount Saint Joseph College (msjnet.edu)' },
  { value: 508, label: 'Mount Saint Mary College (msmc.edu)' },
  { value: 509, label: 'Mount Union College (muc.edu)' },
  { value: 510, label: 'Murray State University (mursuky.edu)' },
  { value: 511, label: 'Muskingum College (muskingum.edu)N' },
  { value: 512, label: 'National Defense University (ndu.edu)' },
  { value: 513, label: 'National-Louis University (nl.edu)' },
  { value: 514, label: 'National Technological University (ntu.edu)' },
  { value: 515, label: 'National University (nu.edu)' },
  { value: 516, label: 'Naval Postgraduate School (nps.navy.mil)' },
  { value: 517, label: 'Nazareth College (naz.edu)' },
  { value: 518, label: 'Newberry College (newberry.edu)' },
  { value: 519, label: 'New England Institute of Technology' },
  { value: 520, label: 'New College of California (newcollege.edu)' },
  { value: 521, label: 'New Hampshire College (nhc.edu)' },
  { value: 522, label: 'New Jersey Institute of Technology (njit.edu)' },
  { value: 523, label: 'New Mexico Highlands University (nmhu.edu)' },
  { value: 524, label: 'New Mexico Institute of Mining & Technology (nmt.edu)' },
  { value: 525, label: 'New Mexico State University (nmsu.edu)' },
  { value: 526, label: 'New York Institute of Technology (nyit.edu)' },
  { value: 527, label: 'New York University (nyu.edu)' },
  { value: 528, label: 'Niagara University (niagara.edu)' },
  { value: 529, label: 'Nicholls State University (nich.edu)' },
  { value: 530, label: 'Norfolk State University (nsu.edu)' },
  { value: 531, label: 'North Adams State College (nasc.mass.edu)' },
  { value: 532, label: 'North Carolina Central University (NCCU.edu)' },
  { value: 533, label: 'North Carolina A&T State University (ncat.edu)' },
  { value: 534, label: 'North Carolina State University (ncsu.edu)' },
  { value: 535, label: 'North Carolina Wesleyan College (ncwc.edu)' },
  { value: 536, label: 'North Central Bible College (ncbc.edu)' },
  { value: 537, label: 'North Dakota State University (ndsu.nodak.edu)' },
  { value: 538, label: 'Northland College (northland.edu)' },
  { value: 539, label: 'North Park College and Theological Seminary (npcts.edu)' },
  { value: 540, label: 'Northeastern Illinois University' },
  { value: 541, label: 'Northeastern Louisiana University (nlu.edu)' },
  { value: 542, label: 'Northeastern State University (nsuok.edu)' },
  { value: 543, label: 'Northeastern University (northeastern.edu)' },
  { value: 544, label: 'Northern Arizona University (nau.edu)' },
  { value: 545, label: 'Northern Illinois University (niu.edu)' },
  { value: 546, label: 'Northern Kentucky University (nku.edu)' },
  { value: 547, label: 'Northern Michigan University (nmu.edu)' },
  { value: 548, label: 'Northern State University (northern.edu)' },
  { value: 549, label: 'Northwest Missouri State University (nwmissouri.edu)' },
  { value: 550, label: 'Northwest Nazarene College (nnc.edu)' },
  { value: 551, label: 'Northwestern College of Iowa (nwciowa.edu)' },
  { value: 552, label: 'Northwestern State University (nsula.edu)' },
  { value: 553, label: 'Northwestern University (nwu.edu)' },
  { value: 554, label: 'Norwich University (norwich.edu)' },
  { value: 555, label: 'Nova Southeastern University (nova.edu)O' },
  { value: 556, label: 'Oakland University (oakland.edu)' },
  { value: 557, label: 'Oberlin College (oberlin.edu)' },
  { value: 558, label: 'Occidental College (oxy.edu)' },
  { value: 559, label: 'Ohio Dominican College (odc.edu)' },
  { value: 560, label: 'Ohio Northern University (onu.edu)' },
  { value: 561, label: 'Ohio State University, Columbus (ohio-state.edu)' },
  { value: 562, label: 'Ohio State University, Marion (marion.ohio-state.edu)' },
  { value: 563, label: 'Ohio Wesleyan University (owu.edu)' },
  { value: 564, label: 'Ohio University, Athens (ohiou.edu)' },
  { value: 565, label: 'Oklahoma Baptist University (okbu.edu)' },
  { value: 566, label: 'Oklahoma City University (okcu.edu)' },
  { value: 567, label: 'Oklahoma State University (okstate.edu)' },
  { value: 568, label: 'Old Dominion University (odu.edu)' },
  { value: 569, label: 'Olivet Nazarene University (olivet.edu)' },
  { value: 570, label: 'Oral Roberts University (oru.edu)' },
  { value: 571, label: 'Oregon Graduate Institute of Science and Technology (ogi.edu)' },
  { value: 572, label: 'Oregon Health Sciences University (ohsu.edu)' },
  { value: 573, label: 'Oregon Institute of Technology (oit.osshe.edu)' },
  { value: 574, label: 'Oregon State University (orst.edu)' },
  { value: 575, label: 'Otterbein College (otterbein.edu)' },
  { value: 576, label: 'Our Lady of the Lake University (ollusa.edu)P' },
  { value: 577, label: 'Pace University (pace.edu)' },
  { value: 578, label: 'Pacific Lutheran University (plu.edu)' },
  { value: 579, label: 'Pacific Union College (puc.edu)' },
  { value: 580, label: 'Pacific University (pacificu.edu)' },
  { value: 581, label: 'Pacific Western University (pwu.com)' },
  { value: 582, label: 'Palm Beach Atlantic College (pbac.edu)' },
  { value: 583, label: 'Peace College (Peace.edu)' },
  { value: 584, label: 'Pembroke State University (pembroke.edu)' },
  { value: 585, label: 'Pennsylvania State System of Higher Education (sshechan.edu)' },
  { value: 586, label: 'Pennsylvania State University (psu.edu)' },
  { value: 587, label: 'Pennsylvania State University-Schuylkill Campus (sl.psu.edu)' },
  { value: 588, label: 'Pensacola Christian College (pcci.edu)' },
  { value: 589, label: 'Pepperdine University (pepperdine.edu)' },
  { value: 590, label: 'Peru State College (peru.edu)' },
  { value: 591, label: 'Philadelphia College of Textiles and Science (philacol.edu)' },
  { value: 592, label: 'Phillips University (phillips.edu)' },
  { value: 593, label: 'Pittsburg State University (pittstate.edu)' },
  { value: 594, label: 'Pitzer College (pitzer.edu)' },
  { value: 595, label: 'Platt College (platt.edu)' },
  { value: 596, label: 'Plymouth State College (plymouth.edu)' },
  { value: 597, label: 'Point Loma Nazarene College' },
  { value: 598, label: 'Polytechnic University of New York (poly.edu)' },
  { value: 599, label: 'Polytechnic University of Puerto Rico (pupr.edu)' },
  { value: 600, label: 'Pomona College (pomona.edu)' },
  { value: 601, label: 'Portland State University (pdx.edu)' },
  { value: 602, label: 'Prairie View A&M University (pvamu.edu)' },
  { value: 603, label: 'Pratt Institute (pratt.edu)' },
  { value: 604, label: 'Prescott College (asu.edu)' },
  { value: 605, label: 'Princeton University (princeton.edu)' },
  { value: 606, label: 'Presbyterian College (presby.edu)' },
  { value: 607, label: 'Providence College (providence.edu)' },
  { value: 608, label: 'Purdue University (purdue.edu)' },
  { value: 609, label: 'Purdue University Calumet (purdue.edu)' },
  { value: 610, label: 'Purdue University North Central (purduenc.edu)Q' },
  { value: 611, label: 'Quincy University (quincy.edu)' },
  { value: 612, label: 'Quinnipiac College (quinnipiac.edu)R' },
  { value: 613, label: 'Radford University (runet.edu)' },
  { value: 614, label: 'Ramapo College (ramapo.edu)' },
  { value: 615, label: 'Randolph-Macon College (rmc.edu)' },
  { value: 616, label: "Randolph-Macon Woman's College (rmwc.edu)" },
  { value: 617, label: 'Reed College (reed.edu)' },
  { value: 618, label: 'Regent University (regent.edu)' },
  { value: 619, label: 'Regis University (regis.edu)' },
  { value: 620, label: 'Rensselaer Polytechnic Institute (rpi.edu)' },
  { value: 621, label: 'Rhode Island College (ric.edu)' },
  { value: 622, label: 'Rhodes College (rhodes.edu)' },
  { value: 623, label: 'Rice University (rice.edu)' },
  { value: 624, label: 'Richard Stockton College of New Jersey (stockton.edu)' },
  { value: 625, label: 'Rider University (rider.edu)' },
  { value: 626, label: 'Ripon College (Ripon.EDU)' },
  { value: 627, label: 'Rivier College (riv.edu)' },
  { value: 628, label: 'Roanoke College (roanoke.edu)' },
  { value: 629, label: 'Rochester Institute of Technology (rit.edu)' },
  { value: 630, label: 'The Rockefeller University (rockefeller.edu)' },
  { value: 631, label: 'Rockford College (rockford.edu)' },
  { value: 632, label: 'Rockhurst College (rockhurst.edu)' },
  { value: 633, label: 'Rocky Mountain College (rocky.edu)' },
  { value: 634, label: 'Roger Williams University (rwu.edu)' },
  { value: 635, label: 'Rollins College (rollins.edu)' },
  { value: 636, label: 'Rosary College (rosary.edu)' },
  { value: 637, label: 'Rose-Hulman Institute of Technology (rose-hulman.edu)' },
  { value: 638, label: 'Rowan College (rowan.edu)' },
  { value: 639, label: 'Rutgers University (rutgers.edu)' },
  { value: 640, label: 'Rutgers University, Camden (rutgers.edu)' },
  { value: 641, label: 'Rutgers University, Newark (rutgers.edu)S' },
  { value: 642, label: 'The Sage Colleges (sage.edu)' },
  { value: 643, label: 'Sacred Heart University (CT) (sacredheart.edu)' },
  { value: 644, label: 'Sacred Heart University (PR) (usc.clu.edu)' },
  { value: 645, label: 'Saginaw Valley State University (svsu.edu)' },
  { value: 646, label: 'St. Ambrose University (sau.edu)' },
  { value: 647, label: 'St. Andrews Presbyterian College (sapc.edu)' },
  { value: 648, label: 'Saint Anselm College (anselm.edu)' },
  { value: 649, label: 'St. Bonaventure University (sbu.edu)' },
  { value: 650, label: 'Saint Cloud State University (stcloud.msus.edu)' },
  { value: 651, label: "Saint Edward's University (stedwards.edu)" },
  { value: 652, label: 'Saint Francis College (sfcpa.edu)' },
  { value: 653, label: "St. John's College-Annapolis (sjca.edu)" },
  { value: 654, label: "St. John's College-Santa Fe (sjcsf.edu)" },
  { value: 655, label: "Saint John's University (MN) (csbsju.edu)" },
  { value: 656, label: "Saint John's University (NY) (stjohns.edu)" },
  { value: 657, label: 'St. Joseph College (CT) (sjc.edu)' },
  { value: 658, label: "Saint Joseph's College (IN) (saintjoe.edu)" },
  { value: 659, label: "St. Joseph's College (ME) (sjcme.edu)" },
  { value: 660, label: "Saint Joseph's University (sju.edu)" },
  { value: 661, label: 'St. Lawrence University (stlawu.edu)' },
  { value: 662, label: 'St. Louis College of Pharmacy (stlcop.edu)' },
  { value: 663, label: 'Saint Louis University (slu.edu)' },
  { value: 664, label: "St. Martin's College (stmartin.edu)" },
  { value: 665, label: 'Saint Mary College (smcks.edu)' },
  { value: 666, label: "Saint Mary's College (IN) (saintmarys.edu)" },
  { value: 667, label: "Saint Mary's College of California (stmarys-ca.edu)" },
  { value: 668, label: "Saint Mary's University of Minnesota (smumn.edu)" },
  { value: 669, label: "Saint Michael's College (smcvt.edu)" },
  { value: 670, label: 'Saint Olaf College (stolaf.edu)' },
  { value: 671, label: 'St. Thomas University (FL) (stu.edu)' },
  { value: 672, label: 'Saint Vincent College (stvincent.edu)' },
  { value: 673, label: 'Saint Xavier University (sxu.edu)' },
  { value: 674, label: 'Salisbury State University (ssu.umd.edu)' },
  { value: 675, label: 'Salish Kootenai College (skc.edu)' },
  { value: 676, label: 'Sam Houston State University (shsu.edu)' },
  { value: 677, label: 'Samford University (samford.edu)' },
  { value: 678, label: 'San Diego State University (sdsu.edu)' },
  { value: 679, label: 'San Francisco State University (sfsu.edu)' },
  { value: 680, label: 'San Jose State University (sjsu.edu)' },
  { value: 681, label: 'Santa Clara University (scu.edu)' },
  { value: 682, label: 'Sarah Lawrence College (slc.edu)' },
  { value: 683, label: 'School of the Art Institute of Chicago (artic.edu)' },
  { value: 684, label: 'Seattle Pacific University (spu.edu)' },
  { value: 685, label: 'Seattle University (seattleu.edu)' },
  { value: 686, label: 'Seton Hall University (shu.edu)' },
  { value: 687, label: 'Sewanee, University of the South (sewanee.edu)' },
  { value: 688, label: 'Shawnee State University (shawnee.edu)' },
  { value: 689, label: 'Shenandoah University (su.edu)' },
  { value: 690, label: 'Shippensburg University of Pennsylvania (ship.edu)' },
  { value: 691, label: 'Shorter College (shorter.edu)' },
  { value: 692, label: 'Simmons College (simmons.edu)' },
  { value: 693, label: "Simon's Rock College (simons-rock.edu)" },
  { value: 694, label: 'Simpson College (simpson.edu)' },
  { value: 695, label: 'Skidmore College (skidmore.edu)' },
  { value: 696, label: 'Slippery Rock University of Pennsylvania (sru.edu)' },
  { value: 697, label: 'Smith College (smith.edu)' },
  { value: 698, label: 'Sonoma State University (sonoma.edu)' },
  { value: 699, label: 'South Dakota School of Mines and Technology (sdsmt.edu)' },
  { value: 700, label: 'South Dakota State University (sdstate.edu)' },
  { value: 701, label: 'Southeast Missouri State University (semo.edu)' },
  { value: 702, label: 'Southeastern Louisiana University (selu.edu)' },
  { value: 703, label: 'Southern College (southern.edu)' },
  { value: 704, label: 'Southern College of Technology (sct.edu)' },
  { value: 705, label: 'Southern Connecticut State University (scwww.ctstateu.edu)' },
  { value: 706, label: 'Southern Illinois University (siu.edu)' },
  { value: 707, label: 'Southern Illinois University-Carbondale (siu.edu)' },
  { value: 708, label: 'Southern Illinois University-Edwardsville (siue.edu)' },
  { value: 709, label: 'Southern Methodist University (smu.edu)' },
  { value: 710, label: 'Southern Nazarene University (snu.edu)' },
  { value: 711, label: 'Southern Oregon State College (sosc.osshe.edu)' },
  { value: 712, label: 'Southern University (subr.edu)' },
  { value: 713, label: 'Southern Utah University (suu.edu)' },
  { value: 714, label: 'Southampton College (liunet.edu)' },
  { value: 715, label: 'South Texas College of Law (stcl.edu)' },
  { value: 716, label: 'Southwest Baptist University (sbuniv.edu)' },
  { value: 717, label: 'Southwest Missouri State University (smsu.edu)' },
  { value: 718, label: 'Southwest State University (southwest.msus.edu)' },
  { value: 719, label: 'Southwest Texas State University (swt.edu)' },
  { value: 720, label: 'Southwestern Adventist College (swac.edu)' },
  { value: 721, label: 'Southwestern University (southwestern.edu)' },
  { value: 722, label: 'Spelman College (auc.edu)' },
  { value: 723, label: 'Spring Arbor College (arbor.edu)' },
  { value: 724, label: 'Spring Hill College (shc.edu)' },
  { value: 725, label: 'Stanford University (stanford.edu)' },
  { value: 726, label: 'State University of New York System (sunycentral.edu)' },
  { value: 727, label: 'State University of New York at Albany (albany.edu)' },
  {
    value: 728,
    label: 'State University of New York College of Technology at Alfred (alfredtech.edu)',
  },
  { value: 729, label: 'State University of New York at Binghamton (binghamton.edu)' },
  { value: 730, label: 'State University of New York College at Brockport (brockport.edu)' },
  { value: 731, label: 'State University of New York at Buffalo (buffalo.edu)' },
  {
    value: 732,
    label: 'State University of New York College at Buffalo (Buffalo State College) (snybuf.edu)',
  },
  {
    value: 733,
    label:
      'State University of New York College of Agriculture and Technology at Cobleskill (cobleskill.edu)',
  },
  { value: 734, label: 'State University of New York College at Cortland (cortland.edu)' },
  {
    value: 735,
    label: 'State University of New York College of Environmental Science and Forestry (esf.edu)',
  },
  { value: 736, label: 'State University of New York at Farmingdale (farmingdale.edu)' },
  { value: 737, label: 'State University of New York at Fredonia (fredonia.edu)' },
  { value: 738, label: 'State University of New York College at Geneseo (geneseo.edu)' },
  { value: 739, label: 'State University of New York College at New Paltz (newpaltz.edu)' },
  { value: 740, label: 'State University of New York College at Oneonta' },
  { value: 741, label: 'State University of New York at Oswego (oswego.edu)' },
  { value: 742, label: 'State University of New York at Plattsburgh (plattsburgh.edu)' },
  { value: 743, label: 'State University of New York College at Potsdam (potsdam.edu)' },
  { value: 744, label: 'State University of New York at Stony Brook (sunysb.edu)' },
  {
    value: 745,
    label: 'State University of New York Institute of Technology at Utica/Rome (sunyit.edu)',
  },
  { value: 746, label: 'Stephen F. Austin State University (sfasu.edu)' },
  { value: 747, label: 'Stephens College (stephens.edu)' },
  { value: 748, label: 'Stetson University (stetson.edu)' },
  { value: 749, label: 'Stevens Institute of Technology (stevens-tech.edu)' },
  { value: 750, label: 'Strayer College (strayer.edu)' },
  { value: 751, label: 'Suffolk University (suffolk.edu)' },
  { value: 752, label: 'Sul Ross State University (sulross.edu)' },
  { value: 753, label: 'Summit University of Louisiana (summitunivofla.edu)' },
  { value: 754, label: 'Susquehanna University (susqu.edu)' },
  { value: 755, label: 'Swarthmore College (swarthmore.edu)' },
  { value: 756, label: 'Sweet Briar College (sbc.edu)' },
  { value: 757, label: 'Syracuse University (syr.edu)T' },
  { value: 758, label: 'Tabor College (tabor.edu)' },
  { value: 759, label: 'Tarleton State University (tarleton.edu)' },
  { value: 760, label: 'Taylor University (tayloru.edu)' },
  { value: 761, label: 'Teachers College, Columbia University (tc.columbia.edu)' },
  { value: 762, label: 'Teikyo Marycrest University (mcrest.edu)' },
  { value: 763, label: 'Temple University (temple.edu)' },
  { value: 764, label: 'Tennessee State University (tnstate.edu)' },
  { value: 765, label: 'Tennessee Technological University (tntech.edu)' },
  { value: 766, label: 'Texas A&M International University (tamiu.edu)' },
  { value: 767, label: 'Texas A&M University-College Station (tamu.edu)' },
  { value: 768, label: 'Texas A&M University-Corpus Christi (tamucc.edu)' },
  { value: 769, label: 'Texas A&M University-Kingsville (taiu.edu)' },
  { value: 770, label: 'Texas Christian University (tcu.edu)' },
  { value: 771, label: 'Texas Southern University (tsu.edu)' },
  { value: 772, label: 'Texas Tech University (ttu.edu)' },
  { value: 773, label: 'Texas Tech University-Health Sciences Center (ttuhsc.edu)' },
  { value: 774, label: "Texas Woman's University (twu.edu)" },
  { value: 775, label: 'Thomas College (thomas.edu)' },
  { value: 776, label: 'Thomas Edison State College (tesc.edu)' },
  { value: 777, label: 'Thomas Jefferson University (tju.edu)' },
  { value: 778, label: 'Thomas More College (thomasmore.edu)' },
  { value: 779, label: 'Towson State University (towson.edu)' },
  { value: 780, label: 'Transylvania University (transy.edu)' },
  { value: 781, label: 'Trenton State College (trenton.edu)' },
  { value: 782, label: 'Trinity College (CT) (trincoll.edu)' },
  { value: 783, label: 'Trinity College (DC)' },
  { value: 784, label: 'Trinity University (trinity.edu)' },
  { value: 785, label: 'Troy State University (tsufl.edu)' },
  { value: 786, label: 'Truman State University (truman.edu)' },
  { value: 787, label: 'Tucson University (tucsonu.edu)' },
  { value: 788, label: 'Tufts University (tufts.edu)' },
  { value: 789, label: 'Tulane University (tulane.edu)' },
  { value: 790, label: 'Tuskegee University (tusk.edu)U' },
  { value: 791, label: 'Union College (union.edu)' },
  { value: 792, label: 'The Union Institute (tui.edu)' },
  { value: 793, label: 'Union University (uu.edu)' },
  { value: 794, label: 'United States Air Force Academy (usafa.af.mil)' },
  { value: 795, label: 'United States International University (usiu.edu)' },
  { value: 796, label: 'United States Merchant Marine Academy (usmma.edu)' },
  { value: 797, label: 'United States Military Academy (usma.edu)' },
  { value: 798, label: 'United States Naval Academy (nadn.navy.mil)' },
  { value: 799, label: 'The Uniformed Services University of the Health Sciences (usuhs.mil)' },
  { value: 800, label: 'Ursinus College (ursinus.edu)' },
  { value: 801, label: 'Ursuline CollegeUA-B' },
  { value: 802, label: 'University of Akron (uakron.edu)' },
  { value: 803, label: 'University of Alabama at Birmingham (uab.edu)' },
  { value: 804, label: 'University of Alabama at Huntsville (uah.edu)' },
  { value: 805, label: 'University of Alabama at Tuscaloosa (ua.edu)' },
  { value: 806, label: 'University of Alaska (alaska.edu)' },
  { value: 807, label: 'University of Alaska-Anchorage (uaa.alaska.edu)' },
  { value: 808, label: 'University of Alaska-Fairbanks (alaska.edu)' },
  { value: 809, label: 'University of Alaska-Southeast (alaska.edu)' },
  { value: 810, label: 'University of Arizona (arizona.edu)' },
  { value: 811, label: 'University of Arkansas - Fayetteville (uark.edu)' },
  { value: 812, label: 'University of Arkansas - Little Rock (ualr.edu)' },
  { value: 813, label: 'University of Arkansas for Medical Sciences (uams.edu)' },
  { value: 814, label: 'University of Arkansas - Monticello (uamont.edu)' },
  { value: 815, label: 'University of Baltimore (ubalt.edu)' },
  { value: 816, label: 'University of Bridgeport (bridgeport.edu)UC' },
  { value: 817, label: 'University of California, Berkeley (berkeley.edu)' },
  { value: 818, label: 'University of California, Davis (ucdavis.edu)' },
  { value: 819, label: 'University of California, Irvine (uci.edu)' },
  { value: 820, label: 'University of California, Los Angeles (ucla.edu)' },
  { value: 821, label: 'University of California, Riverside (ucr.edu)' },
  { value: 822, label: 'University of California, San Diego (ucsd.edu)' },
  { value: 823, label: 'University of California, San Francisco (ucsf.edu)' },
  { value: 824, label: 'University of California, Santa Barbara (ucsb.edu)' },
  { value: 825, label: 'University of California, Santa Cruz (ucsc.edu)' },
  { value: 826, label: 'University of Central Arkansas (uca.edu)' },
  { value: 827, label: 'University of Central Florida (ucf.edu)' },
  { value: 828, label: 'University of Central Texas' },
  { value: 829, label: 'University of Charleston (uchaswv.edu)' },
  { value: 830, label: 'University of Chicago (uchicago.edu)' },
  { value: 831, label: 'University of Cincinnati (uc.edu)' },
  { value: 832, label: 'University of Colorado at Boulder (colorado.edu)' },
  { value: 833, label: 'University of Colorado at Colorado Springs (uccs.edu)' },
  { value: 834, label: 'University of Colorado at Denver (cudenver.edu)' },
  { value: 835, label: 'University of Colorado Health Sciences Center (hsc.colorado.edu)' },
  { value: 836, label: 'University of Connecticut (uconn.edu)UD-H' },
  { value: 837, label: 'University of Dallas (udallas.edu)' },
  { value: 838, label: 'University of Dayton (udayton.edu)' },
  { value: 839, label: 'University of Delaware (udel.edu)' },
  { value: 840, label: 'University of Denver (du.edu)' },
  { value: 841, label: 'University of the District of Columbia (udc.edu)' },
  { value: 842, label: 'University of Detroit Mercy (udmercy.edu)' },
  { value: 843, label: 'University of Dubuque (dbq.edu)' },
  { value: 844, label: 'University of Evansville (evansville.edu)' },
  { value: 845, label: 'University of Florida (ufl.edu)' },
  { value: 846, label: 'University of Georgia (uga.edu)' },
  { value: 847, label: 'University of Great Falls (ugf.edu)' },
  { value: 848, label: 'University of Guam (uog.edu)' },
  { value: 849, label: 'University of Hartford (hartford.edu)' },
  { value: 850, label: 'University of Hawaii at Hilo Physics and Astronomy (uhh.hawaii.edu)' },
  { value: 851, label: 'University of Hawaii at Manoa (hawaii.edu)' },
  { value: 852, label: 'University of Houston (uh.edu)UI-L' },
  { value: 853, label: 'University of Idaho (uidaho.edu)' },
  { value: 854, label: 'University of Illinois at Chicago (uic.edu)' },
  { value: 855, label: 'University of Illinois at Springfield (sangamon.edu)' },
  { value: 856, label: 'University of Ilinois at Urbana-Champaign (uiuc.edu)' },
  { value: 857, label: 'University of Indianapolis (uindy.edu)' },
  { value: 858, label: 'University of Iowa (uiowa.edu)' },
  { value: 859, label: 'University of Kansas (ukans.edu)' },
  { value: 860, label: 'University of Kansas School of Medicine (kumc.edu)' },
  { value: 861, label: 'University of Kentucky (uky.edu)' },
  { value: 862, label: 'University of La Verne (ulaverne.edu)' },
  { value: 863, label: 'University of Louisville (louisville.edu)UM' },
  { value: 864, label: 'University of Maine System (maine.edu)' },
  { value: 865, label: 'University of Maine (ume.maine.edu)' },
  { value: 866, label: 'University of Maine at Farmington (umf.maine.edu)' },
  { value: 867, label: 'University of Maine at Fort Kent (umfk.maine.edu)' },
  { value: 868, label: 'University of Maine at Machias (umm.maine.edu)' },
  { value: 869, label: 'University of Maine at Presque Island (umpi.maine.edu)' },
  { value: 870, label: 'University of Maryland at Baltimore (ab.umd.edu)' },
  { value: 871, label: 'University of Maryland at Baltimore County (umbc.edu)' },
  { value: 872, label: 'University of Maryland at College Park (umcp.umd.edu)' },
  { value: 873, label: 'University of Maryland - University College (umuc.edu)' },
  { value: 874, label: 'University of Massachusetts System (umassp.edu)' },
  { value: 875, label: 'University of Massachusetts at Amherst (umass.edu)' },
  { value: 876, label: 'University of Massachusetts at Dartmouth (umassd.edu)' },
  { value: 877, label: 'University of Massachusetts at Lowell (uml.edu)' },
  { value: 878, label: 'University of Memphis (memphis.edu)' },
  { value: 879, label: 'University of Miami (miami.edu)' },
  { value: 880, label: 'University of Michigan-Ann Arbor (umich.edu)' },
  { value: 881, label: 'University of Michigan-Dearborn (umd.umich.edu)' },
  { value: 882, label: 'University of Minnesota (umn.edu)' },
  { value: 883, label: 'University of Minnesota-Crookston (crk.umn.edu)' },
  { value: 884, label: 'University of Minnesota-Duluth (d.umn.edu)' },
  { value: 885, label: 'University of Minnesota-Morris (mrs.umn.edu)' },
  { value: 886, label: 'University of Minnesota-Twin Cities (umn.edu)' },
  { value: 887, label: 'University of Mississippi (olemiss.edu)' },
  { value: 888, label: 'University of Mississippi Medical Center (umsmed.edu)' },
  { value: 889, label: 'University of Missouri System (missouri.edu)' },
  { value: 890, label: 'University of Missouri-Columbia (missouri.edu)' },
  { value: 891, label: 'University of Missouri-Kansas City (umkc.edu)' },
  { value: 892, label: 'University of Missouri-Rolla (umr.edu)' },
  { value: 893, label: 'University of Missouri-Saint Louis (umsl.edu)' },
  { value: 894, label: 'University of Montana (umt.edu)UN' },
  { value: 895, label: 'University of Nebraska, Kearney (unk.edu)' },
  { value: 896, label: 'University of Nebraska, Lincoln (unl.edu)' },
  { value: 897, label: 'University of Nebraska, Omaha (unomaha.edu)' },
  { value: 898, label: 'University of Nevada, Las Vegas (unlv.edu)' },
  { value: 899, label: 'University of Nevada, Reno (unr.edu)' },
  { value: 900, label: 'University of New England (une.edu)' },
  { value: 901, label: 'University of New Hampshire, Durham (unh.edu)' },
  { value: 902, label: 'University of New Haven (newhaven.edu)' },
  { value: 903, label: 'University of New Mexico (unm.edu)' },
  { value: 904, label: 'University of New Orleans (uno.edu)' },
  { value: 905, label: 'University of North Carolina at Asheville (unca.edu)' },
  { value: 906, label: 'University of North Carolina at Chapel Hill (unc.edu)' },
  { value: 907, label: 'University of North Carolina at Charlotte (uncc.edu)' },
  { value: 908, label: 'University of North Carolina at Greensboro (uncg.edu)' },
  { value: 909, label: 'University of North Carolina System (unc.edu)' },
  { value: 910, label: 'University of North Carolina at Wilmington (uncwil.edu)' },
  { value: 911, label: 'University of North Dakota (und.nodak.edu)' },
  { value: 912, label: 'University of North Florida (unf.edu)' },
  { value: 913, label: 'University of North Texas (unt.edu)' },
  { value: 914, label: 'University of North Texas Health Science Center (hsc.unt.edu)' },
  { value: 915, label: 'University of Northern Colorado (univnorthco.edu)' },
  { value: 916, label: 'University of Northern Iowa (uni.edu)' },
  { value: 917, label: 'University of Notre Dame (nd.edu)UO-S' },
  { value: 918, label: 'University of Oklahoma (uoknor.edu)' },
  { value: 919, label: 'University of Oregon (uoregon.edu)' },
  { value: 920, label: 'University of the Ozarks (ozarks.edu)' },
  { value: 921, label: 'University of the Pacific (uop.edu)' },
  { value: 922, label: 'University of Pennsylvania (upenn.edu)' },
  { value: 923, label: 'University of Phoenix (uophx.edu)' },
  { value: 924, label: 'University of Pittsburgh (pitt.edu)' },
  { value: 925, label: 'University of Pittsburgh at Johnstown (pitt.edu)' },
  { value: 926, label: 'University of Portland (uofport.edu)' },
  { value: 927, label: 'University of Puerto Rico (upr.clu.edu)' },
  { value: 928, label: 'University of Puget Sound (ups.edu)' },
  { value: 929, label: 'University of Redlands (uor.edu)' },
  { value: 930, label: 'University of Rhode Island (uri.edu)' },
  { value: 931, label: 'University of Richmond (urich.edu)' },
  { value: 932, label: 'University of Rochester (rochester.edu)' },
  { value: 933, label: 'University of San Diego (acusd.edu)' },
  { value: 934, label: 'University of San Francisco (usfca.edu)' },
  { value: 935, label: 'University of Sarasota (sarasota.fl.us)' },
  { value: 936, label: 'University of Science & Arts of Oklahoma (usao.edu)' },
  { value: 937, label: 'University of Scranton (uofs.edu)' },
  { value: 938, label: 'University of Sioux Falls (thecoo.edu)' },
  { value: 939, label: 'University of Southern California (usc.edu)' },
  { value: 940, label: 'University of South Carolina (scarolina.edu)' },
  { value: 941, label: 'University of South Carolina - Aiken (sc.edu)' },
  { value: 942, label: 'University of South Dakota (usd.edu)' },
  { value: 943, label: 'University of South Florida (usf.edu)' },
  { value: 944, label: 'University of Southern Maine (usm.maine.edu)' },
  { value: 945, label: 'University of Southern Mississippi (usm.edu)' },
  { value: 946, label: 'University of Southwestern Louisiana (usl.edu)' },
  { value: 947, label: 'University of Saint Thomas (stthom.edu)' },
  { value: 948, label: 'University of Saint Thomas (MN) (stthomas.edu)' },
  { value: 949, label: 'University of South Alabama (usouthal.edu)' },
  { value: 950, label: 'University of Southern Colorado (uscolo.edu)' },
  { value: 951, label: 'University of Southern Indiana (usi.edu)UT' },
  { value: 952, label: 'University of Tampa (utampa.edu)' },
  { value: 953, label: 'University of Tennessee, Knoxville (utk.edu)' },
  { value: 954, label: 'University of Tennessee, Martin (utm.edu)' },
  { value: 955, label: 'University of Texas System (utsystem.edu)' },
  { value: 956, label: 'University of Texas at Arlington (uta.edu)' },
  { value: 957, label: 'University of Texas at Austin (utexas.edu)' },
  { value: 958, label: 'University of Texas at Brownsville (utb.edu)' },
  { value: 959, label: 'University of Texas at Dallas (utdallas.edu)' },
  { value: 960, label: 'University of Texas at El Paso (utep.edu)' },
  { value: 961, label: 'University of Texas-Pan American (panam.edu)' },
  { value: 962, label: 'University of Texas at San Antonio (utsa.edu)' },
  { value: 963, label: 'University of Texas Health Science Center at Houston (uth.tmc.edu)' },
  { value: 964, label: 'University of Texas Health Science Center at San Antonio (uthscsa.edu)' },
  { value: 965, label: 'University of Texas at Tyler' },
  { value: 966, label: 'University of Texas Health Center at Tyler (uthct.edu)' },
  { value: 967, label: 'University of Texas M.D. Anderson Cancer Center (utmdacc.uth.tmc.edu)' },
  { value: 968, label: 'University of Texas Medical Branch (utmb.edu)' },
  { value: 969, label: 'University of Texas Southwestern Medical Center at Dallas (swmed.edu)' },
  { value: 970, label: 'University of Toledo (utoledo.edu)' },
  { value: 971, label: 'University of Tulsa (utulsa.edu)UU-W' },
  { value: 972, label: 'University of Utah (utah.edu)' },
  { value: 973, label: 'University of Vermont (uvm.edu)' },
  { value: 974, label: 'University of the Virgin Islands (uvi.edu)' },
  { value: 975, label: 'University of Virginia, Charlottesville (virginia.edu)' },
  { value: 976, label: 'University of Washington (washington.edu)' },
  { value: 977, label: 'University of West Alabama (westal.edu)' },
  { value: 978, label: 'University of West Florida (uwf.edu)' },
  { value: 979, label: 'University of Wisconsin System (uwsa.edu)' },
  { value: 980, label: 'University of Wisconsin-Eau Claire (uwec.edu)' },
  { value: 981, label: 'University of Wisconsin-Green Bay (uwgb.edu)' },
  { value: 982, label: 'University of Wisconsin-LaCrosse (uwlax.edu)' },
  { value: 983, label: 'University of Wisconsin-Madison (wisc.edu)' },
  { value: 984, label: 'University of Wisconsin-Milwaukee (uwm.edu)' },
  { value: 985, label: 'University of Wisconsin-Oshkosh (uwosh.edu)' },
  { value: 986, label: 'University of Wisconsin-Parkside (uwp.edu)' },
  { value: 987, label: 'University of Wisconsin-Platteville (uwplatt.edu)' },
  { value: 988, label: 'University of Wisconsin-River Falls (uwrf.edu)' },
  { value: 989, label: 'University of Wisconsin-Stevens Point (uwsp.edu)' },
  { value: 990, label: 'University of Wisconsin-Stout (uwstout.edu)' },
  { value: 991, label: 'University of Wisconsin-Superior (uwsuper.edu)' },
  { value: 992, label: 'University of Wisconsin-Whitewater (uww.edu)' },
  { value: 993, label: 'University of Wyoming (uwyo.edu)' },
  { value: 994, label: 'Upper Iowa University (uiu.edu)' },
  { value: 995, label: 'Utah State University (usu.edu)' },
  { value: 996, label: 'Utah Valley State College (uvsc.edu)V' },
  { value: 997, label: 'Valley City State University (vcsu.nodak.edu)' },
  { value: 998, label: 'Valdosta State University (valdosta.peachnet.edu)' },
  { value: 999, label: 'Valparaiso University (valpo.edu)' },
  { value: 1000, label: 'Vanderbilt University (vanderbilt.edu)' },
  { value: 1001, label: 'Vassar College (vassar.edu)' },
  { value: 1002, label: 'Vermont Technical College (vtc.vsc.edu)' },
  { value: 1003, label: 'Villa Julie College (vjc.edu)' },
  { value: 1004, label: 'Villanova University (vill.edu)' },
  { value: 1005, label: 'Virginia Commonwealth University (vcu.edu)' },
  { value: 1006, label: 'Virginia Intermont College (vic.edu)' },
  { value: 1007, label: 'Virginia Military Institute (vmi.edu)' },
  { value: 1008, label: 'Virginia Polytechnic Institute and State University (vt.edu)' },
  { value: 1009, label: 'Virginia State University (vsu.edu)' },
  { value: 1010, label: 'Virginia Wesleyan College (vwc.edu)W' },
  { value: 1011, label: 'Wabash College (wabash.edu)' },
  { value: 1012, label: 'Wake Forest University (wfu.edu)' },
  { value: 1013, label: 'Walden University (waldenu.edu)' },
  { value: 1014, label: 'Walla Walla College (wwc.edu)' },
  { value: 1015, label: 'Warren Wilson College (warren-wilson.edu)' },
  { value: 1016, label: 'Wartburg College (wartburg.edu)' },
  { value: 1017, label: 'Washburn University (wuacc.edu)' },
  { value: 1018, label: 'Washington Bible College/Capital Bible Seminary (bible.edu)' },
  { value: 1019, label: 'Washington & Lee University (wlu.edu)' },
  { value: 1020, label: 'Washington College (washcoll.edu)' },
  { value: 1021, label: 'Washington State University (wsu.edu)' },
  { value: 1022, label: 'Washington State University at Tri-Cities (wsu.edu)' },
  { value: 1023, label: 'Washington State University at Vancouver (vancouver.wsu.edu)' },
  { value: 1024, label: 'Washington University, Saint Louis (wustl.edu)' },
  { value: 1025, label: 'Wayne State University (wayne.edu)' },
  { value: 1026, label: 'Waynesburg College (waynesburg.edu) Weber State University (weber.edu)' },
  { value: 1027, label: 'Webster University (websteruniv.edu)' },
  { value: 1028, label: 'Wellesley College (wellesley.edu)' },
  { value: 1029, label: 'Wells College (wells.edu)' },
  { value: 1030, label: 'Wentworth Institute of Technology (wit.edu)' },
  { value: 1031, label: 'Wesley College (wesley.edu)' },
  { value: 1032, label: 'Wesleyan University (wesleyan.edu)' },
  { value: 1033, label: 'West Chester University of Pennsylvania (wcupa.edu)' },
  { value: 1034, label: 'West Coast University (wcula.edu)' },
  { value: 1035, label: 'West Georgia College (westga.edu)' },
  { value: 1036, label: 'West Liberty State College (wlsc.wvnet.edu)' },
  { value: 1037, label: 'West Texas A&M University (wtamu.edu)' },
  { value: 1038, label: 'West Virginia University (wvu.edu)' },
  { value: 1039, label: 'West Virginia University at Parkersburg (wvup.wvnet.edu)' },
  { value: 1040, label: 'Western Carolina University (wcu.edu)' },
  { value: 1041, label: 'Western Connecticut State University (wcsu.ctstateu.edu)' },
  { value: 1042, label: 'Western Illinois University (wiu.edu)' },
  { value: 1043, label: 'Western Kentucky University (wku.edu)' },
  { value: 1044, label: 'Western Maryland College (wmc.car.md.us)' },
  { value: 1045, label: 'Western Michigan University (wmich.edu)' },
  { value: 1046, label: 'Western Montana College (wmc.edu)' },
  { value: 1047, label: 'Western New England College (wnec.edu)' },
  { value: 1048, label: 'Western New Mexico University (wnmu.edu)' },
  { value: 1049, label: 'Western State College (wsc.colorado.edu)' },
  { value: 1050, label: 'Western Washington University (wwu.edu)' },
  { value: 1051, label: 'Westfield State College (wsc.mass.edu)' },
  { value: 1052, label: 'Westminster College (westminster.edu)' },
  { value: 1053, label: 'Westminster College (westminster-mo.edu)' },
  { value: 1054, label: 'Westminster College of Salt Lake City (wcslc.edu)' },
  { value: 1055, label: 'Westminster Theological Seminary (wts.edu)' },
  { value: 1056, label: 'Westmont College (westmont.edu)' },
  { value: 1057, label: 'Wheaton College (wheaton.edu)' },
  { value: 1058, label: 'Wheaton College, Norton MA (wheatonma.edu)' },
  { value: 1059, label: 'Wheeling Jesuit College (wjc.edu)' },
  { value: 1060, label: 'Whitman College (whitman.edu)' },
  { value: 1061, label: 'Whittier College (whittier.edu)' },
  { value: 1062, label: 'Whitworth College (whitworth.edu)' },
  { value: 1063, label: 'Wichita State University (twsu.edu)' },
  { value: 1064, label: 'Widener University (widener.edu)' },
  { value: 1065, label: 'Wilberforce University (wilberforce.edu)' },
  { value: 1066, label: 'Wilkes University (wilkes.edu)' },
  { value: 1067, label: 'Willamette University (willamette.edu)' },
  { value: 1068, label: 'William Howard Taft University (taftu.edu)' },
  { value: 1069, label: 'William Jewell College (jewell.edu)' },
  { value: 1070, label: 'William Mitchell College of Law (wmitchell.edu)' },
  { value: 1071, label: 'William Penn College (wmpenn.edu)' },
  { value: 1072, label: 'William Paterson College (wilpaterson.edu)' },
  { value: 1073, label: 'William Woods University (wmwoods.edu)' },
  { value: 1074, label: 'Williams College (williams.edu)' },
  { value: 1075, label: 'Wilmington College (wilmington.edu)' },
  { value: 1076, label: 'Winona State University (winona.msus.edu)' },
  { value: 1077, label: 'Winthrop University (winthrop.edu)' },
  { value: 1078, label: 'Wittenberg University (wittenberg.edu)' },
  { value: 1079, label: 'Wofford College (wofford.edu)' },
  { value: 1080, label: 'Woodbury University (woodburyu.edu)' },
  { value: 1081, label: 'Worcester Polytechnic Institute (wpi.edu)' },
  { value: 1082, label: 'Wright State University (wright.edu)X' },
  { value: 1083, label: 'Xavier University of Louisiana (xula.edu)Y' },
  { value: 1084, label: 'Yale University (yale.edu)' },
  { value: 1085, label: 'Yeshiva University (yu.edu)' },
  { value: 1086, label: 'York College of Pennsylvania (ycp.edu)' },
  { value: 1087, label: 'Youngstown State University (ysu.edu)' },
  { value: 1088, label: 'Miami University - Oxford' },
  { value: 1089, label: 'New School' },
  { value: 1090, label: 'St. John Fisher College' },
  { value: 1091, label: 'Maryville University of St. Louis' },
  { value: 1092, label: 'Immaculata University' },
  { value: 1093, label: 'Spalding University' },
  { value: 1094, label: 'Cardinal Stritch University' },
  { value: 1095, label: 'Lynn University' },
  { value: 1096, label: 'South Carolina State University' },
  { value: 1097, label: 'Trevecca Nazarene University' },
  { value: 1098, label: 'Trinity International University' },
  { value: 1099, label: 'University of Louisiana - Lafayette' },
  { value: 1100, label: 'Alliant International University' },
  { value: 1101, label: 'Argosy University' },
  { value: 1102, label: 'California Institute of Integral Studies' },
  { value: 1103, label: 'Capella University' },
  { value: 1104, label: 'Colorado Technical University' },
  { value: 1105, label: 'Northcentral University' },
  { value: 1106, label: 'Trident University International' },
  { value: 1107, label: 'Union Institute and University' },
  { value: 1108, label: 'Wilmington University' },
]);

export const workExperienceType = () => [
  { value: 0, key: 'teacher', label: 'Teacher' },
  { value: 1, key: 'department_head', label: 'Department head' },
  { value: 2, key: 'assistant_principal', label: 'Assistant principal' },
  { value: 3, key: 'principal', label: 'Principal' },
  { value: 4, key: 'assistant_teacher', label: 'Assistant teacher' },
  { value: 5, key: 'other_school_based_role', label: 'Other school-based role' },
  { value: 6, key: 'non_school_based_role', label: 'Non-school-based role' },
];

export const orgDescription = () => [
  { value: 0, key: 'competency_based', label: 'Competency-based' },
  { value: 1, key: 'emergent', label: 'Emergent' },
  { value: 2, key: 'innovative', label: 'Innovative' },
  { value: 3, key: 'traditional', label: 'Traditional' },
  { value: 4, key: 'progressive', label: 'Progressive' },
  { value: 5, key: 'established', label: 'Established' },
  { value: 6, key: 'urban', label: 'Urban' },
  { value: 7, key: 'suburban', label: 'Suburban' },
  { value: 8, key: 'rural', label: 'Rural' },
  { value: 9, key: 'highly_diverse', label: 'Highly diverse' },
  { value: 10, key: 'moderately_diverse', label: 'Moderately diverse' },
  { value: 11, key: 'minimally_diverse', label: 'Minimally diverse' },
  { value: 12, key: 'educator_driven_curriculum', label: 'Educator-driven curriculum' },
  { value: 13, key: 'administration_driven_curriculum', label: 'Administration-driven curriculum' },
  { value: 14, key: 'student_driven_curriculum', label: 'Student-driven curriculum' },
  { value: 15, key: 'high_performing', label: 'High-performing' },
  { value: 16, key: 'low_performing', label: 'Low-performing' },
  { value: 17, key: 'team_oriented', label: 'Team-oriented' },
  { value: 18, key: 'small', label: 'Small' },
  { value: 19, key: 'medium', label: 'Medium' },
  { value: 20, key: 'large', label: 'Large' },
  { value: 21, key: 'private', label: 'Private' },
  { value: 22, key: 'charter', label: 'Charter' },
  { value: 23, key: 'public', label: 'Public' },
];

export const HIRING_SEASONS = {
  0: 'N/A',
  1: 'SY 17-18',
  2: 'SY 18-19',
  3: 'SY 19-20',
  4: 'SY 20-21',
  5: 'SY 21-22',
  6: 'SY 22-23',
  7: 'SY 23-24',
  8: 'SY 24-25',
};

export const YEARS = (() => {
  const currentYear = new Date().getFullYear();
  const startYear = 2017;
  const endYear = currentYear + 10;
  const years = {};

  for (let i = 0; i <= endYear - startYear; i++) {
    years[i] = (startYear + i).toString();
  }

  return years;
})();

// TODO: This abstraction needs to be unrolled and redone
// Everytime I go to try to use it I get confused and have to look up
// everywhere else that's using it.
export const hiringSeasons = (markCurrent, newLabels) => {
  let years = [
    { value: 0, key: 'na', label: 'N/A', label_new: 'N/A' },
    { value: 1, key: '2017_2018', label: '2017-2018', label_new: 'SY 17-18' },
    { value: 2, key: '2018_2019', label: '2018-2019', label_new: 'SY 18-19' },
    { value: 3, key: '2019_2020', label: '2019-2020', label_new: 'SY 19-20' },
    { value: 4, key: '2020_2021', label: '2020-2021', label_new: 'SY 20-21' },
    { value: 5, key: '2021_2022', label: '2021-2022', label_new: 'SY 21-22' },
    { value: 6, key: '2022_2023', label: '2022-2023', label_new: 'SY 22-23' },
    { value: 7, key: '2023_2024', label: '2023-2024', label_new: 'SY 23-24' },
    { value: 8, key: '2024_2025', label: '2024-2025', label_new: 'SY 24-25' },
    { value: 9, key: '2025_2026', label: '2025-2026', label_new: 'SY 25-26' },
    { value: 10, key: '2026_2027', label: '2026-2027', label_new: 'SY 26-27' },
  ];

  if (markCurrent) {
    // Mark all as current_season: false
    years = years.map(y => {
      y.current_season = false;
      return y;
    });
    // Adds 'current' to the year it currently is
    const s = years.find(y => y.value === currentHiringSeason());
    s.current_season = true;
  }
  if (newLabels) {
    years = years.map(y => {
      y.label = y.label_new;
      return y;
    });
  }
  return years;
};

export const hiringSeasonByKey = hiringSeasons().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

export const currentHiringSeason = () => {
  return 8;
};

export const constants = {
  SMALL_TEXT_LENGTH: 2048,
  MEDIUM_TEXT_LENGTH: 32768,
};

export const monthAbbreviationToFull = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

export const monthNumberToAbbreviation = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const examType = () => [
  // { value: -1, label: 'NA' },
  { value: 0, label: 'Praxis Core' },
  { value: 1, label: 'Praxis PLT (Principles of Learning & Teaching)' },
  { value: 2, label: 'Praxis Subject Assessments' },
  { value: 3, label: 'New SAT (400 - 1600)' },
  { value: 4, label: 'Old SAT (800 - 2400)' },
  { value: 5, label: 'ACT' },
  { value: 6, label: 'GRE' },
  { value: 7, label: 'CBEST' },
  { value: 99, label: 'Other' },
];

export const examMapping = {
  // helpful information about the different exam types
  0: {
    className: 'praxis-core',
    fields: [
      {
        fieldName: 'praxis_core_reading',
        displayName: 'Reading',
        min: 100,
        max: 200,
      },
      {
        fieldName: 'praxis_core_writing',
        displayName: 'Writing',
        min: 100,
        max: 200,
      },
      {
        fieldName: 'praxis_core_math',
        displayName: 'Math',
        min: 100,
        max: 200,
      },
    ],
  },
  1: {
    className: 'praxis-plt',
    fields: [
      {
        fieldName: 'praxis_plt_score',
        displayName: 'Score',
        min: 100,
        max: 200,
      },
    ],
  },
  2: {
    className: 'praxis_subject',
    fields: [
      {
        fieldName: 'praxis_subject_score',
        displayName: 'Score',
        min: 100,
        max: 200,
      },
    ],
  },
  3: {
    className: 'sat-new',
    fields: [
      {
        fieldName: 'sat_new_ebrw',
        displayName: 'Ebrw',
        min: 200,
        max: 800,
      },
      {
        fieldName: 'sat_new_math',
        displayName: 'Math',
        min: 200,
        max: 800,
      },
      {
        fieldName: 'sat_new_composite',
        displayName: 'Composite',
        min: 400,
        max: 1600,
      },
    ],
  },
  4: {
    className: 'sat-old',
    fields: [
      {
        fieldName: 'sat_old_reading',
        displayName: 'Reading',
        min: 200,
        max: 800,
      },
      {
        fieldName: 'sat_old_writing',
        displayName: 'Writing',
        min: 200,
        max: 800,
      },
      {
        fieldName: 'sat_old_math',
        displayName: 'Math',
        min: 200,
        max: 800,
      },
      {
        fieldName: 'sat_old_composite',
        displayName: 'Composite',
        min: 600,
        max: 2400,
      },
    ],
  },
  5: {
    className: 'act',
    fields: [
      {
        fieldName: 'act_composite',
        displayName: 'Composite',
        min: 1,
        max: 36,
      },
      {
        fieldName: 'act_english',
        displayName: 'English',
        min: 1,
        max: 36,
      },
      {
        fieldName: 'act_math',
        displayName: 'Math',
        min: 1,
        max: 36,
      },
      {
        fieldName: 'act_reading',
        displayName: 'Reading',
        min: 1,
        max: 36,
      },
      {
        fieldName: 'act_science',
        displayName: 'Science',
        min: 1,
        max: 36,
      },
    ],
  },
  6: {
    className: 'gre',
    fields: [
      {
        fieldName: 'gre_composite',
        displayName: 'Composite',
        min: 260,
        max: 340,
      },
      {
        fieldName: 'gre_verbal',
        displayName: 'Verbal',
        min: 130,
        max: 170,
      },
      {
        fieldName: 'gre_quantitative',
        displayName: 'Quantitative',
        min: 130,
        max: 170,
      },
      {
        fieldName: 'gre_writing',
        displayName: 'Writing',
        min: 0,
        max: 6,
      },
    ],
  },
  7: {
    className: 'cbest',
    fields: [
      {
        fieldName: 'cbest_reading',
        displayName: 'Reading',
        min: 20,
        max: 80,
      },
      {
        fieldName: 'cbest_math',
        displayName: 'Mathematics',
        min: 20,
        max: 80,
      },
      {
        fieldName: 'cbest_writing',
        displayName: 'Writing',
        min: 20,
        max: 80,
      },
    ],
  },
  99: {
    className: 'other',
    fields: [
      {
        fieldName: 'other_exam_name',
        displayName: 'Exam name',
        fieldType: 'text',
        min: '',
        max: '',
      },
      {
        fieldName: 'other_exam_score',
        displayName: 'Exam score',
        min: '',
        max: '',
      },
      {
        fieldName: 'other_exam_possible_score',
        displayName: 'Total possible score',
        min: '',
        max: '',
      },
    ],
  },
};

export const praxisSubjects = () => [
  { value: 0, label: 'Agriculture (5701)' },
  { value: 1, label: 'Algebra I (5162)' },
  { value: 2, label: 'American Sign Language (0634)' },
  { value: 3, label: 'Art: Content and Analysis (5135)' },
  { value: 4, label: 'Art: Content Knowledge (5134)' },
  { value: 5, label: 'Audiology (5342)' },
  { value: 6, label: 'Biology: Content Knowledge (5235)' },
  { value: 7, label: 'Braille Proficiency (0633)' },
  {
    value: 8,
    label: 'Business Education: Content Knowledge (5101)',
  },
  { value: 9, label: 'Chemistry: Content Knowledge (5245)' },
  {
    value: 10,
    label: 'Chinese (Mandarin): World Language (5665)',
  },
  {
    value: 11,
    label: 'Citizenship Education: Content Knowledge (5087)',
  },
  { value: 12, label: 'Computer Science (5651)' },
  {
    value: 13,
    label: 'Core Academic Skills for Educators: Mathematics (5732)',
  },
  {
    value: 14,
    label: 'Core Academic Skills for Educators: Reading (5712)',
  },
  {
    value: 15,
    label: 'Core Academic Skills for Educators: Writing (5722)',
  },
  { value: 16, label: 'Early Childhood Education (5025)' },
  {
    value: 17,
    label: 'Earth and Space Sciences: Content Knowledge (5571)',
  },
  { value: 18, label: 'Economics (5911)' },
  { value: 19, label: 'Education of Young Children (5024)' },
  {
    value: 20,
    label: 'Educational Leadership: Administration and Supervision (5411)',
  },
  {
    value: 21,
    label: 'Elementary Education: Content Knowledge (5018)',
  },
  {
    value: 22,
    label: 'Elementary Education: Curriculum, Instruction, and Assessment (5017)',
  },
  {
    value: 23,
    label: 'Elementary Education: Instructional Practice and Applications (5019)',
  },
  {
    value: 24,
    label: 'Elementary Education: Mathematics Subtest (5003)',
  },
  {
    value: 25,
    label: 'Elementary Education: Mathematics—Applied CKT (7903)',
  },
  {
    value: 26,
    label: 'Elementary Education: Mathematics—CKT (7803)',
  },
  {
    value: 27,
    label: 'Elementary Education: Reading and Language Arts Subtest (5002)',
  },
  {
    value: 28,
    label: 'Elementary Education: Reading and Language Arts—Applied CKT (7902)',
  },
  {
    value: 29,
    label: 'Elementary Education: Reading and Language Arts—CKT (7802)',
  },
  { value: 30, label: 'Elementary Education: Science (7804)' },
  { value: 31, label: 'Elementary Education: Science (7904)' },
  {
    value: 32,
    label: 'Elementary Education: Science Subtest (5005)',
  },
  {
    value: 33,
    label: 'Elementary Education: Social Studies (7805)',
  },
  {
    value: 34,
    label: 'Elementary Education: Social Studies (7905)',
  },
  {
    value: 35,
    label: 'Elementary Education: Social Studies Subtest (5004)',
  },
  {
    value: 36,
    label: 'English Language Arts: Content and Analysis (5039)',
  },
  {
    value: 37,
    label: 'English Language Arts: Content Knowledge (5038)',
  },
  {
    value: 38,
    label: 'English to Speakers of Other Languages (5362)',
  },
  { value: 39, label: 'Environmental Education (0831)' },
  { value: 40, label: 'Family and Consumer Sciences (5122)' },
  { value: 41, label: 'French: World Language (5174)' },
  {
    value: 42,
    label: 'Fundamental Subjects: Content Knowledge (5511)',
  },
  {
    value: 43,
    label: 'General Science: Content Knowledge (5435)',
  },
  { value: 44, label: 'Geography (5921)' },
  { value: 45, label: 'German: World Language (5183)' },
  { value: 46, label: 'Gifted Education (5358)' },
  { value: 47, label: 'Government/Political Science (5931)' },
  {
    value: 48,
    label: 'Health and Physical Education: Content Knowledge (5857)',
  },
  { value: 49, label: 'Health Education (5551)' },
  {
    value: 50,
    label: 'Interdisciplinary Early Childhood Education (5023)',
  },
  { value: 51, label: 'Journalism (5223)' },
  { value: 52, label: 'Latin (5601)' },
  { value: 53, label: 'Library Media Specialist (5311)' },
  { value: 54, label: 'Marketing Education (5561)' },
  { value: 55, label: 'Mathematics: Content Knowledge (5161)' },
  {
    value: 56,
    label: 'Middle School English Language Arts (5047)',
  },
  { value: 57, label: 'Middle School Mathematics (5169)' },
  { value: 58, label: 'Middle School Science (5440)' },
  { value: 59, label: 'Middle School Social Studies (5089)' },
  { value: 60, label: 'Middle School: Content Knowledge (5146)' },
  { value: 61, label: 'Music: Content and Instruction (5114)' },
  { value: 62, label: 'Music: Content Knowledge (5113)' },
  { value: 63, label: 'ParaPro Assessment (1755)' },
  {
    value: 64,
    label:
      'Pennsylvania Grades 4-8 Core Assessment: English Language Arts and Social Studies (5154)',
  },
  {
    value: 65,
    label: 'Pennsylvania Grades 4-8 Core Assessment: Mathematics and Science (5155)',
  },
  {
    value: 66,
    label: 'Pennsylvania Grades 4-8 Core Assessment: Pedagogy (5153)',
  },
  {
    value: 67,
    label: 'Pennsylvania Grades 4-8 Subject Concentration: English Language Arts (5156)',
  },
  {
    value: 68,
    label: 'Pennsylvania Grades 4-8 Subject Concentration: Mathematics (5158)',
  },
  {
    value: 69,
    label: 'Pennsylvania Grades 4-8 Subject Concentration: Science (5159)',
  },
  {
    value: 70,
    label: 'Pennsylvania Grades 4-8 Subject Concentration: Social Studies (5157)',
  },
  {
    value: 71,
    label: 'Physical Education: Content and Design (5095)',
  },
  {
    value: 72,
    label: 'Physical Education: Content Knowledge (5091)',
  },
  { value: 73, label: 'Physics: Content Knowledge (5265)' },
  { value: 74, label: 'Pre-Kindergarten Education (5531)' },
  {
    value: 75,
    label: 'Principles of Learning and Teaching: Early Childhood (5621)',
  },
  {
    value: 76,
    label: 'Principles of Learning and Teaching: Grades 7-12 (5624)',
  },
  {
    value: 77,
    label: 'Principles of Learning and Teaching: Grades 5-9 (5623)',
  },
  {
    value: 78,
    label: 'Principles of Learning and Teaching: Grades K-6 (5622)',
  },
  { value: 79, label: 'Professional School Counselor (5421)' },
  { value: 80, label: 'Psychology (5391)' },
  {
    value: 81,
    label: 'Reading for Virginia Educators: Elementary and Special Education (5306)',
  },
  {
    value: 82,
    label: 'Reading for Virginia Educators: Reading Specialist (5304)',
  },
  { value: 83, label: 'Reading Specialist (5301)' },
  { value: 84, label: 'School Psychologist (5402)' },
  {
    value: 85,
    label: 'Social Studies: Content and Interpretation (5086)',
  },
  { value: 86, label: 'Social Studies: Content Knowledge (5081)' },
  { value: 87, label: 'Sociology (5952)' },
  { value: 88, label: 'Spanish: World Language (5195)' },
  {
    value: 89,
    label: 'Special Education: Core Knowledge and Applications (5354)',
  },
  {
    value: 90,
    label: 'Special Education: Core Knowledge and Mild to Moderate Applications (5543)',
  },
  {
    value: 91,
    label: 'Special Education: Core Knowledge and Severe to Profound Applications (5545)',
  },
  {
    value: 92,
    label: 'Special Education: Education of Deaf and Hard of Hearing Students (5272)',
  },
  {
    value: 93,
    label: 'Special Education: Preschool/Early Childhood (5691)',
  },
  {
    value: 94,
    label: 'Special Education: Teaching Speech to Students with Language Impairments (5881)',
  },
  {
    value: 95,
    label:
      'Special Education: Teaching Students with Behavioral Disorders/Emotional Disturbances (5372)',
  },
  {
    value: 96,
    label: 'Special Education: Teaching Students with Intellectual Disabilities (5322)',
  },
  {
    value: 97,
    label: 'Special Education: Teaching Students with Learning Disabilities (5383)',
  },
  {
    value: 98,
    label: 'Special Education: Teaching Students with Visual Impairments (5282)',
  },
  {
    value: 99,
    label: 'Speech Communication: Content Knowledge (5221)',
  },
  { value: 100, label: 'Speech-Language Pathology (5331)' },
  { value: 101, label: 'Teaching Reading (5204)' },
  {
    value: 102,
    label: 'Teaching Reading: Elementary Education (5203)',
  },
  { value: 103, label: 'Technology Education (5051)' },
  { value: 104, label: 'Theatre (5641)' },
  {
    value: 105,
    label: 'World and U.S. History: Content Knowledge (5941)',
  },
  { value: 106, label: 'World Languages Pedagogy (5841)' },
];

/* Sections on a role application. Can be made required or not visible */
export const roleSections = () => [
  { value: 7, label: 'Resume', key: 'resume' },
  { value: 0, label: 'Work Experience', key: 'experiences' },
  { value: 1, label: 'Education', key: 'educations' },
  { value: 2, label: 'Credentials', key: 'credentials' },
  { value: 3, label: 'Languages', key: 'languages' },
  { value: 4, label: 'Exams', key: 'exams' },
  { value: 5, label: 'Certificates', key: 'certificates' },
  { value: 6, label: 'References', key: 'references' },
];

export const examTypesMissouri = () => [
  {
    value: 0,
    label: '064 Early Childhood Education',
    key: 'a064_early_childhood_education',
  },
  {
    value: 1,
    label:
      '007 English Language Arts Subtest; 008 Mathematics Subtest; 009 Science Subtest; 010 Social Studies Subtest',
    key:
      'a007_english_language_arts_subtest__008_mathematics_subtest__009_science_subtest__010_social_studies_subtest',
  },
  {
    value: 2,
    label: '065 Elementary Mathematics Specialist',
    key: 'a065_elementary_mathematics_specialist',
  },
  {
    value: 3,
    label: '011 Middle School Education: Language Arts',
    key: 'a011_middle_school_education__language_arts',
  },
  {
    value: 4,
    label: '012 Middle School Education: Mathematics',
    key: 'a012_middle_school_education__mathematics',
  },
  {
    value: 5,
    label: '013 Middle School Education: Science',
    key: 'a013_middle_school_education__science',
  },
  {
    value: 6,
    label: '014 Middle School Education: Social Science',
    key: 'a014_middle_school_education__social_science',
  },
  { value: 7, label: '015 Agriculture', key: 'a015_agriculture' },
  { value: 8, label: '016 Biology', key: 'a016_biology' },
  { value: 9, label: '017 Business', key: 'a017_business' },
  { value: 10, label: '018 Chemistry', key: 'a018_chemistry' },
  {
    value: 11,
    label: '019 Earth Science',
    key: 'a019_earth_science',
  },
  { value: 12, label: '020 English', key: 'a020_english' },
  {
    value: 13,
    label: '021 General Science (discontinued cert after 8.25.19)',
    key: 'a021_general_science__discontinued_cert_after_8_25_19_',
  },
  { value: 14, label: '070 Journalism', key: 'a070_journalism' },
  { value: 15, label: '022 Marketing', key: 'a022_marketing' },
  { value: 16, label: '023 Mathematics', key: 'a023_mathematics' },
  { value: 17, label: '024 Physics', key: 'a024_physics' },
  {
    value: 18,
    label: '071 Social Science (formerly 025, 026, 027, 028, 029, and 030 subtests)',
    key: 'a071_social_science__formerly_025__026__027__028__029__and_030_subtests_',
  },
  {
    value: 19,
    label: '031 Speech & Theater',
    key: 'a031_speech___theater',
  },
  {
    value: 20,
    label: '046 Technology & Engineering',
    key: 'a046_technology___engineering',
  },
  { value: 21, label: '036 Art', key: 'a036_art' },
  {
    value: 22,
    label: '037 World Languages: Chinese-Mandarin',
    key: 'a037_world_languages__chinese_mandarin',
  },
  {
    value: 23,
    label: '315 Computer Science',
    key: 'a315_computer_science',
  },
  {
    value: 24,
    label: '038 Family & Consumer Sciences',
    key: 'a038_family___consumer_sciences',
  },
  {
    value: 25,
    label: '039 World Languages: French',
    key: 'a039_world_languages__french',
  },
  {
    value: 26,
    label: '040 World Languages: German',
    key: 'a040_world_languages__german',
  },
  { value: 27, label: '041 Health', key: 'a041_health' },
  {
    value: 28,
    label: '042 Library Media Specialist',
    key: 'a042_library_media_specialist',
  },
  {
    value: 29,
    label: '043 Music: Instrumental & Vocal',
    key: 'a043_music__instrumental___vocal',
  },
  {
    value: 30,
    label: '044 Physical Education',
    key: 'a044_physical_education',
  },
  {
    value: 31,
    label: '045 World Languages: Spanish',
    key: 'a045_world_languages__spanish',
  },
  {
    value: 32,
    label: '047 Blind & Low Vision',
    key: 'a047_blind___low_vision',
  },
  {
    value: 33,
    label: '048 Deaf & Hard of Hearing',
    key: 'a048_deaf___hard_of_hearing',
  },
  {
    value: 34,
    label: '049 Early Childhood Special Education',
    key: 'a049_early_childhood_special_education',
  },
  {
    value: 35,
    label: '050 Mild/Moderate Cross Categorical Special Education',
    key: 'a050_mild_moderate_cross_categorical_special_education',
  },
  {
    value: 36,
    label: '051 Severely Developmentally Disabled',
    key: 'a051_severely_developmentally_disabled',
  },
];

/* Colors for Custom_Tag labels
 *
 * Spec: color representing the tag in dropdown/color-picker is different
 * from the actual color (interesting gotcha).
 */
export const tagColors = () => [
  {
    label: 'Green',
    key: 0,
    hexCode: '#00B88D',
    bgColor: '#61D5BB',
  },
  {
    label: 'Yellow',
    key: 1,
    hexCode: '#FFA601',
    bgColor: '#FFCB5E',
  },
  {
    label: 'Purple',
    key: 2,
    hexCode: '#7B5196',
    bgColor: '#B096C1',
  },
  {
    label: 'Red',
    key: 3,
    hexCode: '#EF5675',
    bgColor: '#F788AB',
  },
  {
    label: 'Blue',
    key: 4,
    hexCode: '#304B7D',
    bgColor: '#8292B2',
  },
];

export const emailVariableKeywords = [
  '{Candidate first name}',
  '{Candidate last name}',
  '{Candidate phone}',
  '{Job title}',
  '{Sender first name}',
  '{Sender last name}',
  '{School name}',
  '{District name}',
  '{Interview date &amp; time}',
];

export const templateCategoriesById = {
  start: 1,
  assignments: 2,
  details: 3,
  questions: 4,
  scorecards: 5,
};
export const templateSubcategoriesById = {
  roleType: 10,
  visibility: 11,
  applicationSettings: 50,
  statusWorkflow: 12,
  category: 13,
  grades: 32,
  locations: 43,
  hrbpAssignments: 44,
  owners: 45,
  description: 30,
  benefits: 31,
  salary: 33,
  fulltime: 34,
  deadline: 35,
  hiringSeason: 36,
  contact: 37,
  startDate: 38,
  individualItems: 41, // question subcategory
  questionSets: 40, // question subcategory
  districtUsers: 46,
};

import { Component } from 'react';
import axios from 'axios';
import _, { update } from 'lodash';
import Loadable from 'react-loading-overlay';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ErrorText from 'components/errortext';
import JobsList from 'components/JobsList';
import PageNotFound from 'components/PageNotFound';
import auth from 'utils/auth';
import { grades, startDateType } from 'utils/enums';
import { showTotalFailure } from 'utils/message';
import { checkInternal } from 'utils/util';
import { JobAlerts } from './JobAlerts';

import jobboardsAPI from 'api/jobboardsAPI';
import { ATSDistrictHomeDataTestIds, ATSDistrictJobListDataTestIds } from 'data-testids/ATS';

let startDateOrder = {
  [startDateType['Immediate']]: 1,
  [startDateType['Specific date']]: 2,
  [startDateType['Next school year']]: 3,
  [startDateType['Not specified']]: 4,
};

class JobsListContainer extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    let districtSlug = this.props.match.params.id.toLowerCase();
    // Forward to `gestalt` to help our struggling users
    if (districtSlug === 'gesalt') {
      districtSlug = 'gestalt';
    }
    if (districtSlug === 'sjusd') {
      districtSlug = 'SJUSD';
    }
    if (districtSlug === 'gla') {
      districtSlug = 'GLA';
    }
    if (districtSlug === 'tfadc') {
      districtSlug = 'TFADC';
    }

    // Allow letters, numbers, underscores, and dashes.
    this.jobBoardSlug = String(districtSlug).replace(/[^A-Za-z0-9_-]/g, '');

    this.state = {
      allRoles: [],
      roles: [],
      query: null,
      loading: false, // When true, pop a modal for the whole page.
      subjects: [],
      schools: [],
      grades: [],
      categories: [],
      fulltime: [],
      sortBy: '',
      descending: true,
      display404Page: false,
      awaitingRefresh: false,
      jobBoard: {
        title: '...',
        district_id: null,
        logo: '',
        has_internal_roles: false,
      },
      passwordInputOpen: false,
      passwordInputText: '',
      passwordError: '',
      isInternalCandidate: false,
    };

    this.checkInternal = checkInternal.bind(this);
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');
    const editPreferences = searchParams.get('edit_preferences');
    this.state.token = token;
    this.state.editPreferences = editPreferences;
  }

  componentDidMount() {
    document.body.classList.add('external-jobs-page');

    jobboardsAPI
      .getExternalJobboard(this.jobBoardSlug)
      .then(async jobBoard => {
        this.setState({ jobBoard }, this.fetchCategories);
        const user = await auth.getUserAsync();
        // Set in motion events to fetch internal jobs
        if (this.checkInternal(user, jobBoard.district_id)) {
          this.submitPassword(false, true);
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.setState({ display404Page: true });
        } else if (err.message === 'Network Error') {
          // when a media file returns 404, the page crashes.
          // so for now i've disabled this
          console.log(err);
        } else {
          showTotalFailure(err.response && err.response.statusText);
          throw new Error(err);
        }
      });
  }

  controlledRefresh = _.debounce(() => {
    if (this.state.awaitingRefresh) {
      this.refresh();
    }
  }, 2000);

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('external-jobs-page');
  }

  togglePassword = () => {
    let passwordInputOpen = !this.state.passwordInputOpen;
    this.setState({
      passwordInputOpen,
      passwordError: '',
    });
  };

  handleChange = e => {
    this.setState({
      passwordInputText: e.target.value,
      passwordError: '',
    });
  };

  submitPassword = (e, overrideForInternalUser = false) => {
    e && e.preventDefault();
    // we'll hash and store on the backend when password is set by the districts. For
    // now, they're hardcoded to be the district slug so no need to check the backend.
    // San jose has a special password
    // Passwords should be case insensitive
    if (
      (this.state.jobBoard.district_id === 10 && this.state.passwordInputText === 'SJUSDapply') ||
      (this.state.jobBoard.district_id !== 10 &&
        this.state.passwordInputText.toLowerCase() === this.jobBoardSlug.toLowerCase()) ||
      overrideForInternalUser
    ) {
      let encodedData = window.btoa('true'); // encode a string

      const { search } = this.props.location;
      const nextSearch = new URLSearchParams(search);
      nextSearch.set('internal', encodedData);

      const nextLocation = {
        ...this.props.location,
        search: nextSearch.toString(),
      };

      this.props.history.push(nextLocation);

      this.setState(
        {
          loading: true,
          isInternalCandidate: true,
          passwordInputOpen: false,
          passwordError: '',
        },
        this.filterRoles
      );
    } else {
      this.setState({ passwordError: 'Incorrect password.' });
    }
  };

  fetchCategories = () => {
    axios
      .get('/api/categories/', { params: { district_id: this.state.jobBoard.district_id } })
      .then(r => {
        this.setCategoriesAndGrades(r.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.setState({ display404Page: true });
        } else {
          showTotalFailure(err.response && err.response.statusText);
        }
      });
  };

  setCategoriesAndGrades = categoriesList => {
    /** Set categories from db instead of using enum as done previously
     */
    const allSubjects = [];
    categoriesList.forEach(c => {
      c.subcategories.forEach(s => {
        allSubjects.push(s.id);
      });
    });

    const allGrades = [];
    grades().forEach(item => {
      allGrades.push(item.value);
    });

    if (!this.ignoreLastFetch) {
      this.setState(
        {
          subjects: allSubjects,
          grades: allGrades,
          categories: categoriesList,
        },
        this.refresh
      );
    }
  };

  refresh = () => {
    let query = this.state.query;
    // Change awaitingRefresh so the debounced refresh would fire
    this.setState({ loading: true, awaitingRefresh: false });
    axios
      .get(`/api/search/roles/`, {
        params: {
          query: query,
          district: this.state.jobBoard.district_id,
          jobboard: this.state.jobBoard.id,
          subjects: this.state.subjects,
          schools: this.state.schools,
          grades: this.state.grades,
          fulltime: this.state.fulltime,
        },
      })
      .then(r => {
        let allRoles = r.data;
        this.setState({ allRoles }, this.filterRoles);
      })
      .catch(err => {
        showTotalFailure(err);
      });
  };
  // Clears ALL search conditions: query, job category, grades
  resetRoles = textOnly => {
    if (textOnly) {
      this.setState(
        {
          query: null,
        },
        () => {
          this.refresh();
        }
      );
    } else {
      this.setState(
        {
          query: null,
          subjects: [],
          schools: [],
          grades: [],
          fulltime: [],
          sortBy: '',
          descending: true,
        },
        () => {
          this.refresh();
        }
      );
    }
  };

  updateMultiSelect = (values, fieldName) => {
    var updateField = this.state[fieldName];
    updateField = values.map(value => value.value);
    this.setState({ [fieldName]: updateField, awaitingRefresh: true }, () => {
      this.controlledRefresh();
    });
  };

  filterRoles = () => {
    let allRoles = this.state.allRoles;
    if (!this.state.isInternalCandidate) {
      let roles = allRoles.filter(r => r.internal_only === false);
      this.setState({ roles: roles, loading: false });
    } else {
      this.setState({ roles: allRoles, loading: false });
    }
  };

  filterByGrades = () => {
    const finalRoles = [];
    this.state.roles.forEach(role => {
      role.grades.forEach(roleGrade => {
        if (this.state.grades.indexOf(roleGrade) !== -1) {
          finalRoles.push(role);
        }
      });
    });
    this.setState({
      roles: finalRoles,
    });
  };

  orderRoles = sortBy => {
    // If user clicked on same sort, switch ascending/descending
    let direction = true;
    let multiplier = -1;

    if (this.state.sortBy === sortBy) {
      direction = !this.state.descending;
      multiplier = direction ? -1 : 1;
    }
    // Order the roles
    let newOrder = this.state.roles.sort((a, b) => {
      if (sortBy === 'start_date') {
        if (
          a.start_date_type === startDateType['Specific date'] &&
          b.start_date_type === startDateType['Specific date']
        ) {
          // if both are specific dates, sort by date
          if (new Date(a.start_date) > new Date(b.start_date)) return -1 * multiplier;
          if (new Date(a.start_date) < new Date(b.start_date)) return 1 * multiplier;
          return 0;
        } else {
          // otherwise sort as follows, using start_date_type:
          // immediate -> specific date -> next school year -> not specified
          // mapping found at top of this page (startDateOrder).
          if (startDateOrder[a.start_date_type] > startDateOrder[b.start_date_type])
            return -1 * multiplier;
          if (startDateOrder[a.start_date_type] < startDateOrder[b.start_date_type])
            return 1 * multiplier;
          return 0;
        }
      } else if (sortBy === 'deadline') {
        // Sort the dates; order by having a start_date, then closest start_date
        if (a[sortBy] === null && b[sortBy] !== null) return 1;
        if (a[sortBy] !== null && b[sortBy] === null) return -1;
        if (new Date(a[sortBy]) > new Date(b[sortBy])) return -1 * multiplier;
        if (new Date(a[sortBy]) < new Date(b[sortBy])) return 1 * multiplier;
        return 0;
      } else if (sortBy === 'subjects') {
        // No subject roles appear last
        if ((a[sortBy] === null || a[sortBy].length === 0) && b[sortBy] !== null) return 1;
        if (a[sortBy] !== null && (b[sortBy] === null || b[sortBy].length === 0)) return -1;
        if (
          (a[sortBy] === null || a[sortBy].length === 0) &&
          (b[sortBy] === null || b[sortBy].length === 0)
        ) {
          return 0;
        }

        let resultSubjectA;
        let resultSubjectB;
        // loop through subcategories to find label.
        this.state.categories.forEach(category => {
          category.subcategories.forEach(subcategory => {
            if (subcategory.id === a.subjects[0]) {
              resultSubjectA = subcategory;
            } else if (subcategory.id === b.subjects[0]) {
              resultSubjectB = subcategory;
            }
          });
        });

        if (resultSubjectA && resultSubjectB) {
          if (resultSubjectA.label < resultSubjectB.label) return 1 * multiplier;
          if (resultSubjectA.label > resultSubjectB.label) return -1 * multiplier;
        }
      } else if (sortBy === 'schools') {
        const isEmptyOrInvalid = schools =>
          !schools || !Array.isArray(schools) || schools.length === 0;
        const schoolsA = a[sortBy];
        const schoolsB = b[sortBy];

        if (isEmptyOrInvalid(schoolsA) && isEmptyOrInvalid(schoolsB)) return 0;
        if (isEmptyOrInvalid(schoolsA)) return 1;
        if (isEmptyOrInvalid(schoolsB)) return -1;

        if (!schoolsA[0].hasOwnProperty('name') || !schoolsB[0].hasOwnProperty('name')) {
          throw new Error("Missing 'name' property in school object");
        }

        const schoolA = schoolsA[0].name.toLowerCase();
        const schoolB = schoolsB[0].name.toLowerCase();

        return direction === true ? schoolA.localeCompare(schoolB) : schoolB.localeCompare(schoolA);
      } else {
        // Sorts everything else
        const valueA =
          a[sortBy] && typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
        const valueB =
          b[sortBy] && typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];
        if (valueA < valueB) return -1 * multiplier;
        if (valueA > valueB) return 1 * multiplier;
        return 0;
      }
      return 0;
    });
    // Set state to match
    this.setState(prevState => ({
      sortBy: sortBy,
      roles: newOrder,
      descending: direction,
    }));
  };

  districtIntroText = () => (
    <div
      className="external-jobslist-intro-container"
      data-testid={ATSDistrictJobListDataTestIds.INTRO_TEXT}
    >
      {this.state.jobBoard.district_id === 7 ? (
        <p className="intro-text gestalt">
          Welcome to the Gestalt Community Schools jobs page! Below, you'll find a list of our open
          positions. Hopefully you'll join our growing number of family members who choose Gestalt!
          For more information about our Gestalt Community, please visit gestaltcs.org. If you have
          questions about the application process, please reach out to{' '}
          <a href="mailto:hrteam@gestaltcs.org">hrteam@gestaltcs.org</a>.
        </p>
      ) : (
        <p className="intro-text">
          Thank you for your interest in working with {` ${this.state.jobBoard.title}`}. To begin
          your application, click any of the positions below.
        </p>
      )}
      <br />
      <p>
        Want to edit an existing application?{' '}
        <Link to="/login" rel="noopener noreferrer" target="_blank">
          <u className="pointer">Log in</u>
        </Link>
      </p>
      {/* only allow password input if there exists any internal only roles and
      the candidate isn't already known as internal. */}
      {!this.state.isInternalCandidate && this.state.jobBoard.has_internal_roles && (
        <>
          <br />
          <p>
            Looking for internal roles? (current staff only){' '}
            <u className="pointer" onClick={this.togglePassword}>
              Click here
            </u>
          </p>
        </>
      )}
      {this.state.passwordInputOpen && (
        <>
          <div className="password-input">
            <StyledInput
              type="text"
              placeholder="Enter organization password"
              value={this.state.passwordInputText}
              onChange={this.handleChange}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.submitPassword(event);
                }
              }}
            />
            <button className="pw-submit" onClick={this.submitPassword}>
              Submit
            </button>
          </div>
          <p className="subtext">
            If you are not sure of your organization’s internal password, please reach out to the
            Human Resources team.
          </p>
        </>
      )}
      {this.state.isInternalCandidate && (
        <>
          <br />
          <i>
            <b>You are currently viewing jobs as an internal employee</b>
          </i>
        </>
      )}
      {this.state.passwordError && <ErrorText message={this.state.passwordError} />}
    </div>
  );

  render() {
    if (this.state.display404Page) {
      return <PageNotFound />;
    }
    const district_organization = this.state.jobBoard.district_parent_organization;

    return (
      <Loadable
        active={this.state.loading}
        animate={true}
        spinner={true}
        text="Loading..."
        className="jobslist-container"
      >
        {this.state.jobBoard.id && (
          <JobAlerts
            categories={this.state.categories}
            jobboardName={this.state.jobBoard.title}
            jobboardSlug={this.jobBoardSlug}
            emailToken={this.state.token ? this.state.token : null}
            editPreferences={this.state.editPreferences && this.state.editPreferences === 'true'}
          />
        )}
        <div
          className={`${
            district_organization === 'IDOE' ? 'header-section' : 'header-section-oecosl'
          } ${this.state.jobBoard.title.replace(/\s+/g, '-').toLowerCase()}`}
        >
          <div className="header-section-content">
            <div className="logo-container-outer">
              <Logo src={this.state.jobBoard.logo} alt="" />
            </div>
            <div className="header-section-title-subtext">
              {this.state.jobBoard.title !== '...' && this.districtIntroText()}
            </div>
          </div>
        </div>
        <JobsList
          name={this.state.jobBoard.title}
          district={this.state.jobBoard.district}
          district_id={this.state.jobBoard.district_id}
          roles={this.state.roles}
          query={this.state.query}
          queryUpdate={query => this.setState(query, this.refresh)}
          subjects={this.state.subjects}
          schoolsIds={this.state.schools}
          categories={this.state.categories}
          fulltime={this.state.fulltime}
          orderRoles={this.orderRoles}
          sortBy={this.state.sortBy}
          resetRoles={this.resetRoles}
          updateMultiSelect={this.updateMultiSelect}
          grades={this.state.grades}
          awaitingRefresh={this.state.awaitingRefresh}
          refresh={this.refresh}
          isInternalCandidate={this.state.isInternalCandidate}
        />
      </Loadable>
    );
  }
}

export default withRouter(JobsListContainer);

const StyledInput = styled.input`
  height: 42px;
  width: 234px;
  padding: 3px 8px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  margin-right: 8px;
  flex: 1;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

/**
 * The max height keeps the logo from overwhelming the jobs page.
 * Auto height and width keep the aspect ratio constant.
 */
const Logo = styled.img`
  max-height: 150px;
  height: auto;
  width: auto;
`;

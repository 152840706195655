import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { appStatusType } from 'utils/enums';
import { Box } from '@mui/material';
import { calculateStatusColor, getStatusText, sortJobSearchResults } from './utils';
import { ComboBox } from 'sharedComponents/ComboBox';
import { CreateJobOrTemplateModalProps } from './types';
import { emptyTemplate } from './defaults';
import { InputAndLabel } from 'sharedComponents/InputAndLabel';
import { Label } from 'sharedComponents/Label';
import { Modal } from 'sharedComponents/Modal';
import { PrimaryButton, SplitButton } from 'sharedComponents/Buttons';
import applicationStatusesAPI from 'api/applicationStatusesAPI';
import auth from 'utils/auth';
import axios from 'axios';
import RolesAPI from 'api/rolesAPI';
import roleSearchAPI from 'api/roleSearchAPI';
import { CloseButton, TextButton } from 'sharedComponents/Buttons';
import { ATSNewJobTemplateModalTestIds } from 'data-testids/ATS';

export default function CreateJobOrTemplateModal({
  handleModalClose,
  handleSetNetworkErrorAlert,
  handleSetWasSuccessful,
  selectedItemToDuplicate,
  requestId,
  shouldShowModal,
  variant,
  updateJobStatusAfterConverting,
}: CreateJobOrTemplateModalProps): JSX.Element {
  const [error, setError] = useState(null);
  const [searchResults, setsSearchResults] = useState([]);
  const [itemToDuplicate, setItemToDuplicate] = useState(selectedItemToDuplicate || null);
  const [name, setName] = useState('');
  const history = useHistory();
  const [isLoadingPreviousData, setIsLoadingPreviousItems] = useState(true);
  const [inputValue, setInputValue] = useState(
    selectedItemToDuplicate ? selectedItemToDuplicate.title : ''
  );
  const shouldShowStatus = variant === 'template';
  const [applicationStatuses, setApplicationStatuses] = useState([]);

  useEffect(() => {
    applicationStatusesAPI
      .getAll({
        districtId: auth.getUser()?.profile.district.id,
        statusTypes: [
          appStatusType.pre_hiring_pool,
          appStatusType.hiring_pool,
          appStatusType.post_hiring_pool,
          appStatusType.hired,
          appStatusType.archived,
          appStatusType.onboarding,
        ],
      })
      .then(data => {
        setApplicationStatuses(data);
      });
  }, []);

  useEffect(() => {
    let isUnmounted = false;

    if (inputValue) {
      const timeoutId = setTimeout(() => {
        setsSearchResults([]);
        setIsLoadingPreviousItems(true);

        if (variant === 'template') {
          roleSearchAPI
            .searchDistrictRoles(inputValue)
            .then(roles => {
              if (isUnmounted) return;
              const sorted = sortJobSearchResults(roles.results);
              setsSearchResults(sorted);
              setIsLoadingPreviousItems(false);
            })
            .catch(error => {
              console.log(error);
              setIsLoadingPreviousItems(false);
            });
        } else if (variant === 'job' || variant === 'request') {
          roleSearchAPI
            .searchTemplates(inputValue)
            .then(templates => {
              if (isUnmounted) return;
              setsSearchResults(templates.results);
              setIsLoadingPreviousItems(false);
            })
            .catch(error => {
              console.log(error);
              setIsLoadingPreviousItems(false);
            });
        }
      }, 250);

      return () => clearTimeout(timeoutId);
    }

    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleSetSearchInputValue = text => {
    setInputValue(text);
  };

  const handleNameChange = event => {
    const name = event.target.value;
    setError(null);
    setName(name);
  };

  const handleSetExistingJobOrTemplate = job => {
    setItemToDuplicate(job);
  };

  const saveTemplateFromScratch = async willEdit => {
    if (error) return;

    if (!name) {
      setError(`Template title is a required field`);
      return;
    }

    const newObject = { ...emptyTemplate };
    newObject.title = name;
    newObject.statuses_available = applicationStatuses;

    await axios
      .post('/api/role/', newObject)
      .then(r => {
        handleModalClose();

        if (willEdit) {
          history.push(`/district/template-edit/${r.data.id}`);
        }

        handleSetWasSuccessful();
      })
      .catch(r => {
        const duplicateTitleError = r.response?.data?.title;
        if (duplicateTitleError) {
          setError(duplicateTitleError);
        } else {
          handleModalClose();
          handleSetNetworkErrorAlert();
        }
      });
  };

  const saveTemplateFromJob = willEdit => {
    if (error) return;

    if (!name) {
      setError('Template title is a required field');
      return;
    }

    RolesAPI.createTemplateFromRole(itemToDuplicate.id, name)
      .then(r => {
        handleModalClose();

        if (willEdit) {
          history.push(`/district/template-edit/${r.id}`);
        }

        handleSetWasSuccessful();
      })
      .catch(r => {
        const duplicateTitleError = r.response.data.title;

        if (duplicateTitleError) {
          setError(duplicateTitleError);
        } else {
          handleModalClose();
          handleSetNetworkErrorAlert();
        }
      });
  };

  const saveJob = () => {
    if (error) return;

    if (!name) {
      setError('Job name is a required field');
      return;
    }

    const jobIdToUse = itemToDuplicate ? itemToDuplicate?.id : null;

    RolesAPI.createRoleFromTemplate(jobIdToUse, name)
      .then(r => {
        handleModalClose();
        history.push(`/district/jobedit/${r.id}`);
      })
      .catch(r => {
        const duplicateTitleError = r.response.data.title;

        if (duplicateTitleError) {
          setError(duplicateTitleError);
        } else {
          handleModalClose();
          handleSetNetworkErrorAlert();
        }
      });
  };

  const convertRequestToJob = () => {
    if (error) return;

    if (!name) {
      setError('Job name is a required field');
      return;
    }

    const jobIdToUse = itemToDuplicate ? itemToDuplicate.id : null;

    RolesAPI.createRoleFromRequest(name, requestId, jobIdToUse)
      .then(() => {
        const draftStatus = 0;
        const newStatus = itemToDuplicate ? itemToDuplicate.status : draftStatus;
        handleModalClose();
        updateJobStatusAfterConverting(newStatus);
        return;
      })
      .catch(r => {
        const duplicateTitleError = r.response?.data.title;

        if (duplicateTitleError) {
          setError(duplicateTitleError);
        } else {
          handleModalClose();
          handleSetNetworkErrorAlert();
        }
      });
  };

  const handleSubmitClick = text => {
    if (variant === 'job') {
      saveJob();
    } else if (variant === 'request') {
      convertRequestToJob();
    } else if (variant === 'template') {
      const hasItemToDuplicate = Boolean(itemToDuplicate);

      if (hasItemToDuplicate) {
        return text === 'Create & Edit' ? saveTemplateFromJob(true) : saveTemplateFromJob(false);
      } else {
        return text === 'Create & Edit'
          ? saveTemplateFromScratch(true)
          : saveTemplateFromScratch(false);
      }
    }
  };

  const getCTAButtons = () => {
    return (
      <CTAContainer>
        <TextButton onClick={handleModalClose} sx={{ marginRight: '24px' }}>
          Cancel
        </TextButton>
        {variant === 'template' ? (
          <SplitButton hasError={Boolean(error)} handleSubmitButtonClick={handleSubmitClick} />
        ) : (
          <PrimaryButton
            onClick={handleSubmitClick}
            dataTestId={
              variant === 'job'
                ? ATSNewJobTemplateModalTestIds.CREATE_JOB_BUTTON
                : ATSNewJobTemplateModalTestIds.CONVERT_JOB_BUTTON
            }
          >
            {variant === 'job' ? 'Create Job' : 'Convert'}
          </PrimaryButton>
        )}
      </CTAContainer>
    );
  };

  const modalTitle = variant === 'template' ? 'Create Template' : 'Create New Job';
  const inputLabel = variant === 'template' ? 'Template Name' : 'Job Name';
  const comboBoxLabel =
    variant === 'template' ? 'Create template from existing job (optional)' : 'Template (optional)';

  return (
    <Modal
      onClose={handleModalClose}
      open={shouldShowModal}
      title={modalTitle}
      ctaButtons={getCTAButtons()}
      closeButton={<CloseButton onClick={handleModalClose} />}
    >
      <InputAndLabel
        hasError={Boolean(error)}
        label={inputLabel}
        value={name}
        onChange={handleNameChange}
        placeholder="eg. Full Time Teacher"
        testid={ATSNewJobTemplateModalTestIds.INPUT}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Bottom>
        <Label labelText={comboBoxLabel} />
        <ComboBox
          inputValue={inputValue}
          getOptionLabel={option => option.title || ''}
          handleSetSearchInputValue={handleSetSearchInputValue}
          loading={isLoadingPreviousData}
          onChange={handleSetExistingJobOrTemplate}
          options={searchResults}
          placeholder="Start typing to search"
          defaultValue={selectedItemToDuplicate ? selectedItemToDuplicate?.title : ''}
          dataTestId={ATSNewJobTemplateModalTestIds.COMBO_BOX}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              sx={{
                '.status': {
                  color: calculateStatusColor(option.status, option.active_status),
                },
                display: 'flex',
              }}
              key={option.id}
            >
              <Title>{option.title}</Title>
              {shouldShowStatus && (
                <Right className="status">
                  {getStatusText(option.status, option.active_status)}
                </Right>
              )}
            </Box>
          )}
        />
        {variant === 'request' && (
          <NoteText>
            Note: This job request will take on characteristics of the template selected. Only
            location and internal notes will be saved.
          </NoteText>
        )}
      </Bottom>
    </Modal>
  );
}

const Bottom = styled(Box)({
  padding: '32px 0',
});

export const CTAContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

export const ErrorText = styled('div')({
  color: '#D32F2F',
  fontSize: '12px',
});

export const Title = styled(Box)({
  width: '50%',
});

export const Right = styled(Title)({
  display: 'flex',
  justifyContent: 'end',
});

const NoteText = styled.div({
  paddingTop: '24px',
  fontSize: '14px',
});

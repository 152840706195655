import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';

import { nimbleTheme } from 'theme';

import ExpressInterestAPI from 'features/MarketplaceV2/api/expressInterestAPI';
import UserSavedSchoolAPI from 'features/MarketplaceV2/api/userSavedSchoolAPI';
import auth from 'utils/auth';

import { ConnectGatedActions } from 'features/MarketplaceV2/utils/connectEnums';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import RadioCardToggle from './SharedComponents/RadioCardToggle';
import { getGatedActionDisplayInfo, updateConnectFirstProfileSetupDateTime } from './utils';
import { ConnectCandidatePreferencesDataTestIds } from 'data-testids/ConnectDataTestIds';

export function CreateAndUpdateProfileForm(): React.ReactElement {
  const user = auth.getUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { created, modified, open_to_connect_opportunities } = user.preference;
  const createdTime = new Date(created);
  const modifiedTime = new Date(modified);
  // we're using seconds because the created and modified timestamp milliseconds are slighly off when a user is created
  const createdTimeInSeconds = Math.floor(createdTime.getTime() / 1000);
  const modifiedInSeconds = Math.floor(modifiedTime.getTime() / 1000);
  const differenceInSeconds = Math.abs(createdTimeInSeconds - modifiedInSeconds);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action');
  const gatedActionSchool = searchParams.get('school');

  const getInitialSelectedOpportunity = () => {
    if (differenceInSeconds <= 2) {
      return 'yes';
    } else {
      return open_to_connect_opportunities ? 'yes' : 'no';
    }
  };

  const [userFormData, setUserFormData] = useState({
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    phoneNumber: user.profile.phone_cell || '',
    selectedOpportunity: getInitialSelectedOpportunity(),
  });
  const [error, setError] = useState<string>('');
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const getFormTitle = () => {
    return differenceInSeconds <= 2 ? 'Create your profile' : 'Update your profile';
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');

    if (event.target.name === 'controlled-radio-buttons-group') {
      setUserFormData({
        ...userFormData,
        selectedOpportunity: event.target.value,
      });
      return;
    }
    setUserFormData({
      ...userFormData,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    const { firstName, lastName, phoneNumber, selectedOpportunity } = userFormData;
    if (!firstName || !lastName || !selectedOpportunity) {
      setError('Please provide valid answers for all required fields');
      return false;
    }
    // TODO: validate phone number like we do in ATS
    if (phoneNumber && phoneNumber.length < 10) {
      setError('Please provide a valid phone number or leave it blank');
      return false;
    }
    setError('');
    return true;
  };

  useEffect(() => {
    const saveSchool = async () => {
      const savedSchools = await UserSavedSchoolAPI.getSchoolsUserHasSaved();
      const savedSchoolIds = savedSchools.map(s => s.school_nces_id);
      const isSchoolSaved = savedSchoolIds.includes(gatedActionSchool);
      if (!isSchoolSaved) {
        await UserSavedSchoolAPI.saveSchool(gatedActionSchool);
      }
    };
    if (gatedAction === ConnectGatedActions.SAVE_SCHOOL && gatedActionSchool) saveSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  const handleSaveAndContinue = async () => {
    const { firstName, lastName, phoneNumber, selectedOpportunity } = userFormData;

    if (!validateForm()) return;

    const userTalentProfile = {
      userId: user.id,
      firstName,
      lastName,
      phoneCell: phoneNumber,
      openToOpportunities: selectedOpportunity === 'yes',
    };

    try {
      setFormIsSubmitting(true);
      await ExpressInterestAPI.updateTalentProfile(userTalentProfile);
      const updatedUser = await auth.getUserAsync();
      auth.setUser(updatedUser);
      history.push(pageDisplayInformation.continueToUrl);
    } catch (error) {
      console.error('Error updating user profile', error);
    } finally {
      setFormIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 620,
        margin: {
          xs: '14px',
          sm: '22px auto',
        },
      }}
    >
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <Paper
        elevation={4}
        sx={{
          borderRadius: '12px',
        }}
      >
        <Box
          component="form"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            paddingTop: '40px',
            marginTop: '24px',
            borderRadius: '12px',
          }}
        >
          <Typography
            data-testid={ConnectCandidatePreferencesDataTestIds.COMPLETE_PROFILE_TEXT}
            variant="h1"
            color="#101828"
            fontSize={28}
            fontWeight={400}
            fontFamily={nimbleTheme.typography.fontFamily['Aeonik TRIAL']}
            textAlign="center"
          >
            {pageDisplayInformation.headerText || getFormTitle()}
          </Typography>
          <Stack spacing={4} px={{ xs: 2, sm: 8 }} py={{ xs: 1, sm: 3 }}>
            <div>
              <QuestionLabel
                htmlFor="first-name"
                required={pageDisplayInformation.requiredFields.includes('firstName')}
                aria-required
              >
                First Name
              </QuestionLabel>
              <TextField
                hiddenLabel
                id="first-name"
                name="firstName"
                placeholder="Enter your first name"
                fullWidth
                size="small"
                defaultValue={userFormData.firstName}
                onChange={handleFormChange}
              />
            </div>
            <div>
              <QuestionLabel
                htmlFor="last-name"
                required={pageDisplayInformation.requiredFields.includes('lastName')}
                aria-required
              >
                Last Name
              </QuestionLabel>
              <TextField
                hiddenLabel
                id="last-name"
                name="lastName"
                placeholder="Enter your last name"
                fullWidth
                size="small"
                defaultValue={userFormData.lastName}
                onChange={handleFormChange}
              />
            </div>
            <div>
              <QuestionLabel htmlFor="last-name">Phone Number</QuestionLabel>
              <TextField
                hiddenLabel
                id="phone-number"
                name="phoneNumber"
                placeholder="+1 (555) 000-0000"
                fullWidth
                inputProps={{
                  minLength: 10,
                }}
                size="small"
                defaultValue={userFormData.phoneNumber}
                onChange={handleFormChange}
              />
            </div>
            <Typography component="p" variant="body1" height={10}>
              Where are you in the job search process?
            </Typography>
            <RadioGroup
              aria-labelledby="open-to-opportunities-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={userFormData.selectedOpportunity}
              onChange={handleFormChange}
            >
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={{
                  xs: 1,
                  sm: 2,
                }}
              >
                <RadioCardToggle
                  width={isMobile ? '100%' : '50%'}
                  height={isMobile ? 53 : undefined}
                  text="Open to principals reaching out to me."
                  radioValue="yes"
                  selectedValue={userFormData.selectedOpportunity}
                />
                <RadioCardToggle
                  width={isMobile ? '100%' : '50%'}
                  height={isMobile ? 53 : undefined}
                  text="Don't allow principals to contact me yet."
                  radioValue="no"
                  selectedValue={userFormData.selectedOpportunity}
                />
              </Stack>
            </RadioGroup>
          </Stack>
          <Box mt={2} p={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
            {error && (
              <Typography color="error" textAlign="center" gutterBottom>
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
      <ConnectProfileFlowButtonGroup
        dataTestId=""
        primaryButton={{
          dataTestId: '',
          primaryButtonLabel: isMobile
            ? pageDisplayInformation?.primaryButtonLabelMobile
            : pageDisplayInformation?.primaryButtonLabel,
          primaryAction: handleSaveAndContinue,
          disabled: !!error,
          isLoading: formIsSubmitting,
        }}
        hasError={!!error}
      />
    </Box>
  );
}

const QuestionLabel = styled(InputLabel)({
  textWrap: 'wrap',
});

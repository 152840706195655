import { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RoleTypeSelector from './RoleTypeSelector';
import VisibilitySelector from './VisibilitySelector';
import JobEditGrades from './jobeditgrades';
import DeleteModal from './DeleteModal';
import LocationsModal from './LocationsModal';
import JobSectionSelection from './jobsectionselection';
import { JobQuestionModal } from './JobQuestionModal';
import EditJobQuestionModal from './EditJobQuestionModal';
import AddJobQuestionModal from './AddJobQuestionModal';
import QuestionSetModal from '../../features/ApplicationQuestionSets/QuestionSetModal';
import { JobDetails } from './JobDetails';
import AddQuestionSet from './AddQuestionSet';
import InternalRoleNotes from './InternalRoleNotes';
import HRBPAssignment from './HRBPAssignment';
import QuestionsForCandidate from './QuestionsForCandidate';
import EditJobSelectScorecard from './EditJobSelectScorecard';
import {
  salarytype,
  roleStatuses,
  questionType,
  questionSource,
  appStatusType,
} from '../../utils/enums';
import { updateDate, generateId, scrollToElementById } from 'utils/util';
import ErrorText from '../errortext';

import Tooltip from '../../assets/tooltip.svg';

import ReactTooltip from 'react-tooltip';
import { setSubcategories } from '../../utils/statusviewutils';
import { sortQuestionsAndAttachments, getStatusesDisplayText } from '../../utils/roleutils';
import AddIndividualItems from './AddIndividualItems';
import { fetchAdmins } from '../../utils/statusviewutils';
import CandidatesListFilterDrop from 'ui-kit/ListFilterDropdown';
import { MergeRoleModal } from '../DistrictJobsList/MergeRoleModal';
import { Input, MultiSelect } from 'ui-kit';

import auth from '../../utils/auth';
import questionSetsAPI from 'api/questionSetsAPI';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import Editor from 'components/Editor';

import MuiMergeIcon from '@mui/icons-material/Merge';
import { PrimaryButton, SecondaryButton, TextButton } from 'sharedComponents/Buttons';
import AddIcon from '@mui/icons-material/Add';
import CreateJobOrTemplateModal from 'components/CreateJobOrTemplateModal';
import { ErrorSnackbar, SuccessSnackbar } from 'sharedComponents/Snackbar';
import { BackButton } from 'sharedComponents/Buttons';
import { withJobClosureNotificationsCache } from 'hoc/withJobClosureNotificationsCache';
import { EMPLOYMENT_TYPE } from 'utils/constants';
import { ATSJobEditTestIds } from '../../data-testids/ATS';

const SALARYTYPE = salarytype().reduce((obj, item) => {
  obj[item.label] = item.value;
  return obj;
}, {});

const ROLESTATUS = roleStatuses().reduce((obj, item) => {
  obj[item.label] = item.key;
  return obj;
}, {});

const TEACHING = 'teaching';
const NON_TEACHING = 'non-teaching';

let unsubmittedInternalNote = false;

class JobEdit extends Component {
  constructor(props) {
    super(props);
    // Determines whether parent container is jobcreate or jobedit
    // TODO: jobedit container should probably be refactored to not use same `job` in memory
    // and instead pass the final job from this component to the container
    const job = props.job?.id ? props.job : JSON.parse(JSON.stringify(props.job));
    this.state = {
      job: job,
      errors: {},
      descriptionText: '',
      benefitsText: '',
      deleteModal: false,
      locationsModal: false,
      subcategories: [],
      questionModalOpen: false,
      questionIdBeingEdited: null,
      addQuestionModalOpen: false,
      editQuestionModalOpen: false,
      questionSetModalOpen: false,
      questionSelectionType: null,
      questionSetUUIDToEdit: null,
      isCreatingRoleQuestionSet: false,
      jobQuestionsAndAttachmentsAndSets: [],
      shouldShowTagModal: false,
      mergeRoleModalOpen: false,
      questionSets: [],
      selectedQuestionSet: {},
      adminUsers: [],
      hiddenHRBPs: [],
      requisition_number: null,
      shouldShowTagModal: false,
      hasNetworkError: false,
      // ONET codes starting with '25' are teaching roles
      roleType: job.onet_occupation_code?.startsWith('25') ? TEACHING : NON_TEACHING,
    };

    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.district = auth.getUser().profile.district;
    this.setSubcategories = setSubcategories.bind(this);
    this.fetchAdmins = fetchAdmins.bind(this);
    this.closeQuestionModal = this.closeQuestionModal.bind(this);
  }

  static propTypes = {
    job: PropTypes.object.isRequired,
    statusColor: PropTypes.string,
    questionBank: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    schools: PropTypes.arrayOf(PropTypes.object),
    schoolOptions: PropTypes.arrayOf(PropTypes.object),
    onSaveLabel: PropTypes.string,
    onSubmitLabel: PropTypes.string,
    pageTitle: PropTypes.string,
    activelySubmitting: PropTypes.bool,
    districtUsers: PropTypes.arrayOf(PropTypes.object),
    districtAndSuperAdmins: PropTypes.arrayOf(PropTypes.object),
    jobBoardOptions: PropTypes.arrayOf(PropTypes.object),
    apiResponseErrors: PropTypes.objectOf(PropTypes.string).isRequired,
    clearAPIResponseErrors: PropTypes.func.isRequired,
    setCustomScorecardsSelected: PropTypes.func.isRequired,
    updateJobStatusAfterConverting: PropTypes.func,
    wasSuccessful: PropTypes.bool,
  };

  static defaultProps = {
    onSaveLabel: 'Save as Draft',
    onSubmitLabel: 'Publish',
    pageTitle: 'Edit Job',
  };

  componentDidMount() {
    this.setSubcategories(this.district.id);
    this.fetchAdmins();
    questionSetsAPI.list().then(questionSets => this.setState({ questionSets }));

    const combinedQuestionsAndAttachmentsAndSets = [
      ...(this.state.job.questions ?? []),
      ...(this.state.job.custom_questions ?? []),
      ...(this.state.job.requiredapplicationattachment_set ?? []),
      ...(this.state.job.question_sets
        ? this.state.job.question_sets.map(
            q =>
              (q = {
                ...q,
                order: this.state.job.question_sets_order.find(qs => qs.uuid === q.uuid)?.order,
                isShowingEditOptions: false,
              })
          )
        : []),
      ...(this.state.job.role_question_sets
        ? this.state.job.role_question_sets.map(
            q =>
              (q = {
                ...q,
                order: this.state.job.question_sets_order.find(qs => qs.uuid === q.uuid)?.order,
                isShowingEditOptions: false,
              })
          )
        : []),
    ];
    if (this.state.job.school_preferences_question) {
      combinedQuestionsAndAttachmentsAndSets.push(this.state.job.school_preferences_question);
    }
    const jobQuestionsAndAttachmentsAndSets = sortQuestionsAndAttachments(
      combinedQuestionsAndAttachmentsAndSets
    );
    // Generate a local id so we can later find update targets
    jobQuestionsAndAttachmentsAndSets.forEach(question => (question.localId = generateId()));

    this.setState({ jobQuestionsAndAttachmentsAndSets });

    // Add the default jobboard to the role if creating a role
    if (this.isDistrictAdmin && !this.props.job.hasOwnProperty('id')) {
      if (this.props.jobBoardOptions.length > 1) {
        const defaultJobboard = this.props.jobBoardOptions.find(
          jb => jb.slug === this.district.slug
        );
        const updateJob = this.state.job;
        updateJob.jobboards.push({ id: defaultJobboard.id });
        this.setState({ job: updateJob });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.schoolOptions?.length !== prevProps.schoolOptions?.length) {
      if (this.state.job.hrbp_assignments) this.hideHRBPAssignments();
    }
    if (this.props.districtAndSuperAdmins?.length !== prevProps.districtAndSuperAdmins?.length) {
      if (this.state.job.hrbp_assignments) this.hideHRBPAssignments();
    }
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  openModal = modal => {
    this.setState({ [modal]: true });
  };

  setDefaults = () => {
    const updateJob = this.state.job;
    if (updateJob.salary_min || updateJob.salary_max) {
      if (!updateJob.salary_type) {
        updateJob.salary_type = SALARYTYPE['per year'];
      }
    }
    if (!updateJob.fulltime) {
      updateJob.fulltime = EMPLOYMENT_TYPE.fullTime;
    }
    this.setState({ job: updateJob });
  };

  saveDraft = () => {
    this.checkForErrorsDraft();
    this.setDefaults();
    // also send up jobQuestionsAndAttachments, which contains the questions and attachments.
    // also send any internal_role_notes that were not saved
    const internalRoleNotesWithDraft = this.state.job.internal_role_notes || [];
    const blankNote = '<p><br></p>';

    if (
      unsubmittedInternalNote &&
      unsubmittedInternalNote.text !== blankNote &&
      !this.isDistrictAdmin
    ) {
      internalRoleNotesWithDraft.push(unsubmittedInternalNote);
    }

    const filteredHrbps = this.state.job.hrbp_assignments?.filter(
      hrbp => hrbp.admin_users.length > 0
    );

    this.setState(
      {
        job: {
          ...this.state.job,
          internal_role_notes: internalRoleNotesWithDraft,
          hrbp_assignments: filteredHrbps,
        },
      },
      () => this.props.onSave(this.state.job, this.state.jobQuestionsAndAttachmentsAndSets)
    );
  };

  publishJob = () => {
    this.checkForErrorsPublish();
    this.showGeneralErrors();
    this.setDefaults();
    // also send up jobQuestionsAndAttachmentsAndSets, which contains the questions and attachments and question sets.
    const internalRoleNotesWithDraft = this.state.job.internal_role_notes || [];
    const blankNote = '<p><br></p>';
    if (
      unsubmittedInternalNote &&
      unsubmittedInternalNote.text !== blankNote &&
      !this.isDistrictAdmin
    ) {
      internalRoleNotesWithDraft.push(unsubmittedInternalNote);
    }

    const filteredHrbps = this.state.job.hrbp_assignments?.filter(
      hrbp => hrbp.admin_users.length > 0
    );

    this.setState(
      {
        job: {
          ...this.state.job,
          internal_role_notes: internalRoleNotesWithDraft,
          hrbp_assignments: filteredHrbps,
        },
      },
      () => this.props.onSubmit(this.state.job, this.state.jobQuestionsAndAttachmentsAndSets)
    );
  };

  checkJobTitle = () => {
    if (!this.state.job.title.trim()) {
      throw new Error('Must provide a job title.');
    }
  };

  checkHRBPAssignment = () => {
    // If the user is a districtAdmin and there are school roles + assignments including empty values, throw error.
    if (
      this.isDistrictAdmin &&
      this.props.schools.length > 0 &&
      this.state.job.hrbp_assignments.length > 0
    ) {
      this.state.job.hrbp_assignments.forEach(assignment => {
        if (
          this.state.job.hrbp_assignments.length === 1 &&
          (assignment.admin_users.length === 0 || assignment.schools.length === 0) &&
          assignment.admin_users.length !== assignment.schools.length
        ) {
          throw new Error('Must provide user and school in HRBP Assignment section.');
        }
        if (
          this.state.job.hrbp_assignments.length > 1 &&
          (assignment.admin_users.length === 0 || assignment.schools.length === 0)
        ) {
          throw new Error('Must provide user and school in HRBP Assignment section.');
        }
      });
    }
    this.setState({ errors: { hrbp_assignments: false } });
  };

  checkJobDescription = () => {
    if (this.isDistrictAdmin && !this.state.job.description) {
      throw new Error('Please add a job description.');
    }
  };

  checkRoleType = () => {
    if (this.state.roleType === TEACHING && !this.state.job.onet_occupation_code) {
      throw new Error('Please select a Role Type from the dropdown.');
    }
  };

  checkLinkToATS = () => {
    if (!this.state.job.link_to_ats) {
      throw new Error('Please add a link to the ATS or email address.');
    }
  };

  checkForErrorsDraft = () => {
    try {
      this.checkJobTitle();
    } catch (error) {
      this.setState({ errors: { title: error.message } });
      throw new Error(error);
    }
    if (!auth.inPostingOnlyDistrict()) {
      try {
        this.checkRoleType();
      } catch (error) {
        this.setState({ errors: { role_type: error.message } });
        throw new Error(error);
      }
      try {
        this.checkHRBPAssignment();
      } catch (error) {
        this.setState({ errors: { hrbp_assignments: error.message } });
        throw new Error(error);
      }
    }
  };

  checkForErrorsPublish = () => {
    // check all required field for draft are present,
    // since we require those fields plus extra ones
    // for publishing a job
    this.checkForErrorsDraft();
    try {
      this.checkJobDescription();
    } catch (error) {
      this.setState({ errors: { job_description: error.message } });
      throw new Error(error);
    }
    if (auth.inPostingOnlyDistrict()) {
      try {
        this.checkLinkToATS();
      } catch (error) {
        this.setState({ errors: { link_to_ats: error.message } }, () =>
          scrollToElementById('jobedit_link_to_ats')
        );
        throw new Error(error);
      }
    }
  };

  onSaveDeletion = () => {
    this.props.onDelete();
  };

  onSaveLocations = (updatedLocations, district_role_ftes) => {
    /** TODO: editing props directly here, very sad indeed. Should be refactored at some point.
     * #techdebt */
    if (updatedLocations.length === 0) {
      // Add the district vacancies count
      if (this.props.job.id) {
        // If editing
        this.props.job.ftes_total = Number(district_role_ftes);
      } else {
        // If creating (this is dumb and should be changed)
        const updateJob = this.state.job;
        updateJob.ftes_total = Number(district_role_ftes);
        this.setState({ job: updateJob });
      }

      // TODO: this is real smelly but I don't have time to refactor this whole fn();
      // spec: if district vacancies exist, school vacancies should be cleared
      this.props.schools.length = 0;
      // when schools removed, reset hrbps to remove schools + multiple lines
      // TODO: this is mutating props and needs to be rewritten
      const updateJob = {
        ...this.state.job,
        hrbp_assignments: [
          {
            admin_users: [...this.state.job.hrbp_assignments[0].admin_users],
            schools: [],
            role: this.state.job?.id,
          },
        ],
      };
      // also update any hrbp-related errors
      const updateErrors = {
        ...this.state.errors,
        hrbp_assignments: false,
      };
      this.setState({ job: updateJob, errors: updateErrors });
    } else {
      for (let [schoolName, school] of Object.entries(updatedLocations)) {
        // update schoolrole's open positions if the schoolrole exists
        let findSchool = this.props.schools.find(s => s.name === schoolName);

        if (findSchool) {
          const originalFtes = findSchool.ftes_total;
          findSchool.ftes_total = Number(school.vacancies);
          findSchool.visible_to_school_admin = school.visibleToAdmins;

          if (!findSchool.has_unfilled_vacancies) {
            // It didn't have an unfilled vacancy, but ftes have increased.
            findSchool.has_unfilled_vacancies = originalFtes < findSchool.ftes_total;
          }
        } else {
          // if it doesn't exist, grab the school from schoolOptions and add open positions to it
          let newSchool = this.props.schoolOptions.find(option => option.name === schoolName);
          newSchool.ftes_total = Number(school.vacancies);
          newSchool.visible_to_school_admin = school.visibleToAdmins;
          newSchool.has_unfilled_vacancies = true;
          this.props.schools.push(newSchool);
        }
      }
      // Add the district vacancies count
      if (this.props.job.id) {
        // If editing
        this.props.job.ftes_total = Number(district_role_ftes);
      } else {
        // If creating (this is dumb and should be changed)
        const updateJob = this.state.job;
        updateJob.ftes_total = Number(district_role_ftes);
        this.setState({ job: updateJob });
      }

      // remove schoolroles with an ftes_total property of 0 or undefined
      // NEW 7-12-2018: If the school has zero positions but is checked, we still want to create the schoolrole
      let i = this.props.schools.length;
      while (i--) {
        if (
          (this.props.schools[i].ftes_total === 0 ||
            this.props.schools[i].ftes_total === undefined) &&
          !updatedLocations[this.props.schools[i].name].checked
        ) {
          this.props.schools.splice(i, 1);
        }
      }
    }

    this.setState({ locationsModal: false });
  };

  addNote = newNote => {
    //if a new note is added, add it locally
    if (!newNote.hasOwnProperty('id')) {
      this.setState({
        job: {
          ...this.state.job,
          internal_role_notes: [
            ...(this.state.job.internal_role_notes ? this.state.job.internal_role_notes : []),
            newNote,
          ],
        },
      });
    }
  };

  updateNote = (note, index) => {
    let internalRoleNotesCopy = this.state.job.internal_role_notes;
    // if new note has "is_deleted" flag, splice it out locally
    // if the deleted note has an "id" meaning it has already been posted, patch delete as well.
    if (note?.is_deleted) {
      internalRoleNotesCopy.splice(+index, 1);
      if (note.hasOwnProperty('id')) {
        this.deleteNote(note);
      }
    } else {
      internalRoleNotesCopy[+index] = note;
    }
    this.setState({
      job: { ...this.state.job, internal_role_notes: internalRoleNotesCopy },
    });
  };

  deleteNote = note => {
    axios
      .patch(`/api/role/${this.state.job.id}/`, {
        internal_role_notes: [note],
        statuses_available: this.state.job.statuses_available,
      })
      .catch(err => console.log(err));
  };
  /* Updating the various input types */
  updateField = e => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState(prevState => {
      const job = { ...prevState.job };
      job[name] = value;

      // when changing start date type away from specific date, clear the date.
      if (name === 'start_date_type' && job.start_date !== null) {
        job.start_date = null;
      }

      return {
        job,
        errors: {},
      };
    });

    // clear any API-related errors present in the container
    this.props.clearAPIResponseErrors();
  };

  updateJobBoard = e => {
    // Remove other options
    const value = Number(e.target.value);
    const addValue = !this.state.job.jobboards.find(j => j.id === value);
    const updateJob = this.state.job;
    let updateJobBoards = [];
    // Add the option checked
    if (addValue) {
      updateJobBoards.push({ id: value });
    } else {
      // If no others are checked, the default jobboard must be checked
      const defaultJobboard = this.props.jobBoardOptions.find(j => j.slug === this.district.slug);
      updateJobBoards.push({ id: defaultJobboard.id });
    }
    updateJob.jobboards = updateJobBoards;
    this.setState({ job: updateJob });
  };

  updateMultiSelect = (values, fieldName) => {
    let updateJob = Object.assign({}, this.state.job);
    updateJob[fieldName] = values.map(value => value.value);
    this.setState({ job: updateJob });
  };

  updateStatusesAvailable = statusId => {
    const updatedJob = { ...this.state.job };
    const existing_statuses_available = [...this.state.job.statuses_available];

    if (this.state.job.statuses_available.map(s => s.id).includes(statusId)) {
      // if statuses_available already includes the selected status, the user is
      // removing that status, so filter it out.
      updatedJob.statuses_available = existing_statuses_available.filter(s => s.id !== statusId);
    } else {
      // Otherwise the user is adding the status. Find it in applicationStatuses and add.
      const newStatus = this.props.applicationStatuses.find(s => s.id === statusId);
      updatedJob.statuses_available = [...existing_statuses_available, newStatus];
    }
    this.setState({ job: updatedJob });
  };

  selectAllStatuses = () => {
    var updateJob = this.state.job;
    updateJob['statuses_available'] = this.props.applicationStatuses;
    this.setState({ job: updateJob });
  };

  clearAllStatuses = () => {
    var updateJob = this.state.job;
    // Remove all except Hired status
    updateJob['statuses_available'] = [
      this.props.applicationStatuses.find(a => a.status_type === 4),
    ];
    this.setState({ job: updateJob });
  };

  updateJobSectionSettings = (event, sectionValue, setting) => {
    let updateJob = { ...this.state.job };
    const value = event.target.checked;
    const updateSection = updateJob['role_sections'].find(r => r.section === sectionValue);
    updateSection[setting] = value;
    // If required is on, visible must also be on
    if (value && setting.indexOf('required') !== -1) {
      if (setting === 'required_internal') {
        updateSection['visible_internal'] = value;
      } else {
        updateSection['visible'] = value;
      }
    }
    // If visible is turned off, required must also be off
    if (!value && setting.indexOf('visible') !== -1) {
      if (setting === 'visible_internal') {
        updateSection['required_internal'] = value;
      } else {
        updateSection['required'] = value;
      }
    }
    this.setState({ job: updateJob });
  };

  getQuestionBeingEdited = () => {
    if (this.state.questionIdBeingEdited) {
      return this.state.jobQuestionsAndAttachmentsAndSets.find(
        q => q.localId === this.state.questionIdBeingEdited
      );
    } else {
      return null;
    }
  };

  openQuestionModal = (type, obj = null) => {
    if (obj && type === 'question_type/direction_text') {
      this.setState({
        questionSelectionType: type,
        questionIdBeingEdited: obj && obj.localId,
        questionModalOpen: false,
        editQuestionModalOpen: true,
      });
    } else if (type === 'question_type/direction_text') {
      this.setState({
        questionSelectionType: type,
        questionIdBeingEdited: obj && obj.localId,
        questionModalOpen: false,
        editQuestionModalOpen: false,
        addQuestionModalOpen: true,
      });
    } else {
      this.setState({
        questionSelectionType: type,
        questionIdBeingEdited: obj && obj.localId,
        questionModalOpen: true,
      });
    }
  };

  closeQuestionModal = () => {
    this.setState({
      questionSelectionType: null,
      questionIdBeingEdited: null,
      addQuestionModalOpen: false,
      editQuestionModalOpen: false,
      questionSetModalOpen: false,
      isCreatingRoleQuestionSet: false,
    });
  };

  // openQuestionSetModal triggers QuestionSetModal with the specified question_set
  openQuestionSetModal = (questionSetUUID, createRoleQuestionSet = false) => {
    if (questionSetUUID && createRoleQuestionSet) {
      // notify modal that the user intends to create a RoleQuestionSet
      this.setState({
        questionSetUUIDToEdit: questionSetUUID,
        questionSetModalOpen: true,
        isCreatingRoleQuestionSet: true,
      });
    } else {
      this.setState({
        questionSetUUIDToEdit: questionSetUUID,
        questionSetModalOpen: true,
      });
    }
  };

  // closes QuestionSetModal and resets relevant state
  closeQuestionSetModal = () => {
    this.setState({
      questionSetModalOpen: false,
      questionSetUUIDToEdit: null,
      isCreatingRoleQuestionSet: false,
    });
  };

  // handler that controls the visibility of a question set's edit options list
  handleQuestionSetEditOptions = questionSetUUID => {
    const newList = this.state.jobQuestionsAndAttachmentsAndSets.map(q => {
      if (q.uuid === questionSetUUID) {
        // toggle edit options list visibility
        return { ...q, isShowingEditOptions: !q.isShowingEditOptions };
      }
      // ensure other option lists are closed
      return { ...q, isShowingEditOptions: false };
    });

    // update list
    this.setState({ jobQuestionsAndAttachmentsAndSets: newList });
  };

  // handles edits and deletions of a given question set
  handleUpdateQuestionSetData = (updatedQuestionSet, isDeleted = false) => {
    let updatedList = [];

    if (isDeleted) {
      // if a QuestionSet was deleted we remove it from the list
      updatedList = this.state.jobQuestionsAndAttachmentsAndSets.filter(
        qs => qs.uuid !== updatedQuestionSet.uuid
      );
    } else if (this.state.isCreatingRoleQuestionSet) {
      // convert global question set to local one
      updatedList = this.state.jobQuestionsAndAttachmentsAndSets.map(qs =>
        qs.uuid === updatedQuestionSet.uuid
          ? { ...updatedQuestionSet, isShowingEditOptions: false }
          : qs
      );
    } else {
      // update global QuestionSet
      updatedList = this.state.jobQuestionsAndAttachmentsAndSets.map(qs =>
        qs.uuid === updatedQuestionSet.uuid
          ? {
              ...updatedQuestionSet,
              localId: qs.localId,
              isShowingEditOptions: false,
            }
          : qs
      );
    }
    this.setState({ jobQuestionsAndAttachmentsAndSets: updatedList });
  };

  showGeneralErrors = () => {
    if (!_.isEmpty(this.state.errors)) {
      if (this.state.job.title === '') {
        return <ErrorText message="Job Post Title is a required field" />;
      }
      if (this.isDistrictAdmin && !this.state.job.description) {
        return <ErrorText message="Job Description is a required field" />;
      }
      if (this.state.errors.role_type) {
        return <ErrorText message="Please select a role type from the dropdown" />;
      }
      if (this.state.errors.non_field_errors) {
        return <ErrorText message={this.state.errors.non_field_errors} />;
      }
    }
  };

  handleTextareaChange = (value, fieldName) => {
    const { job } = this.state;
    job[fieldName] = value;
    this.setState({ job });
  };

  hideHRBPAssignments = () => {
    /**
     * This function handles the case when schools or admin assigned to an HRBP are archived or deactivated.
     * If there are assignments where all users/schools or archived, this function removes those hrbp assignments.
     * These assignments persist unaltered in in the backend and will show up again if the user or school is activated again.
     * If there are assigments where some of the users/schools are archived, this functions removes those schools or users.
     * In the backend, these archived users are added back upon update + similarly will show up again if user or school is activated again.
     **/
    if (
      this.state.job &&
      this.state.job.hrbp_assignments &&
      this.props.schoolOptions.length > 0 &&
      this.props.districtAndSuperAdmins.length > 0
    ) {
      // SchoolOptions and DistrictAndSuperAdmin are source of truth for 'active' schools and users.
      const schoolIds = this.props.schoolOptions.filter(s => !s.isGroupHeader).map(s => s.id);
      const adminIds = this.props.districtAndSuperAdmins?.map(a => a.id);

      let hrbpCopy = JSON.parse(JSON.stringify(this.state.job.hrbp_assignments)); // Deep clone required
      hrbpCopy.forEach(i => {
        i.schools = i.schools.filter(s => schoolIds.includes(s));
        i.admin_users = i.admin_users.filter(a => adminIds.includes(a));
        return i;
      });

      const nonHiddenHRBPs = hrbpCopy.filter(
        i =>
          (this.props.schools.length === 0 && i.admin_users.length > 0) ||
          (this.props.schools.length > 0 && i.admin_users.length > 0 && i.schools.length > 0)
      );

      if (nonHiddenHRBPs.length > 0) {
        this.saveHRBPAssignment(nonHiddenHRBPs);
      } else {
        // if there are no non-hidden hrbps, add a blank row
        this.saveHRBPAssignment([{ admin_users: [], schools: [], role: this.state.job.id }]);
      }
    }
  };

  renderSchoolLocations = () => {
    /**
     * return a string with the associated schools, and something like "+ 3 more" if
     * the string is too long (> 90 characters).
     */
    if (this.props.schools.length === 0) {
      return '';
    }
    let locationString = '';
    let stringLength = 0;
    let additionalSchools = 0;
    for (let school of this.props.schools) {
      stringLength += school.name.length;
      if (stringLength < 90) {
        locationString += school.name + ', ';
      } else {
        additionalSchools += 1;
      }
    }
    let additionalSchoolsString = additionalSchools > 0 ? ` + ${additionalSchools} more` : '';
    let finalString = `${locationString.slice(0, -2)}${additionalSchoolsString}`;
    return finalString;
  };

  locationsObject = () => {
    // Locations will be an object of locations represented by:
    // {name:{ vacancies, checked, visibleToAdmins}}
    let locations = {};

    // Get existing vacancies from schools:
    this.props.schools.forEach(s => {
      locations[s.name] = {
        vacancies: s.ftes_total,
        checked: true,
        visibleToAdmins: s.visible_to_school_admin,
      };
    });

    // schoolOptions has the full list of school names, so use it to fill the rest with 0.
    this.props.schoolOptions.forEach(so => {
      if (!so.isGroupHeader) {
        if (!locations.hasOwnProperty(so.name)) {
          locations[so.name] = {
            vacancies: 0,
            checked: false,
            visibleToAdmins: false,
          };
        }
        if (!locations[so.name].checked) {
          locations[so.name].visibleToAdmins = false;
        }
        locations[so.name].groups = so.school_groups || [];
      }
    });

    return locations;
  };

  renderDraftButton = () => {
    /** determine whether to show the 'Save as Draft' button.
     * return boolean.
     */
    const { status } = this.state.job;
    // Hacky...but District user does not use 'JobCreate' component and all new roles are initializing as "Pending Request".
    // This line ensures new District roles show "Save as Draft" Button
    const isDistrictUserAndCreatingRole =
      document.location.href.indexOf('districtuser/jobcreate') > -1;
    return (
      status === undefined ||
      status === ROLESTATUS['Draft'] ||
      (status === ROLESTATUS['Pending approval'] && auth.isDistrictAdmin()) ||
      isDistrictUserAndCreatingRole
    );
  };

  resetDate = field => {
    const job = Object.assign({}, this.state.job);
    job[field] = null;
    this.setState({ job });
  };

  districtUserOptions = () => {
    return this.props.districtUsers.map(u => {
      return {
        label: u.name,
        value: u.id,
      };
    });
  };

  districtAdminOptions = () => {
    return this.props.districtAndSuperAdmins.map(u => {
      return {
        label: u.name,
        value: u.id,
      };
    });
  };

  saveQuestion = (obj, type) => {
    /** take the provided object and type and save it to the job in state */
    const finalObj = this.buildQuestion(obj, type);

    const jobQuestionsAndAttachmentsAndSets = [...this.state.jobQuestionsAndAttachmentsAndSets];

    const index = jobQuestionsAndAttachmentsAndSets.findIndex(
      question => question.localId === finalObj.localId
    );

    if (index !== -1) {
      jobQuestionsAndAttachmentsAndSets[index] = finalObj;
    } else {
      jobQuestionsAndAttachmentsAndSets.push(finalObj);
    }

    this.setState({
      jobQuestionsAndAttachmentsAndSets,
      questionModalOpen: false,
      editQuestionModalOpen: false,
      addQuestionModalOpen: false,
    });
  };

  buildQuestion = (obj, type) => {
    let finalObj = {
      is_visible_external: obj.is_visible_external,
      is_required_external: obj.is_required_external,
      is_visible_internal: obj.is_visible_internal,
      is_required_internal: obj.is_required_internal,
    };

    if (obj.id) {
      finalObj.id = obj.id;
    }

    if (obj.localId) {
      finalObj.localId = obj.localId;
    } else {
      finalObj.localId = generateId();
    }
    //TODO: Delete this custom code for direction text.
    let direction_copy = obj;
    if (type === 'question_type/direction_text') {
      if (obj?.text) {
        direction_copy.prompt = obj.text;
      }
    }

    switch (type) {
      case questionType.direction_text:
        finalObj.prompt = direction_copy.prompt;
        finalObj.question_type = questionType.direction_text;
        break;
      case questionType.nimble:
        finalObj.nimblequestion_id = obj.nimblequestion_id;
        finalObj.question = obj.text;
        finalObj.question_type = questionType.nimble;
        finalObj.source = questionSource.nimble;
        break;
      case questionType.text_question_model:
        // these questions will look exactly the same as regular 'text' questions to the user,
        // but they're different models so we have to deal with that here.
        finalObj.question = obj.text;
        finalObj.question_type = questionType.nimble;
        break;
      case questionType.open_response:
        finalObj.prompt = obj.text;
        finalObj.question_type = questionType.open_response;
        finalObj.district = this.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        break;
      case questionType.yes_no:
        finalObj.prompt = obj.text;
        finalObj.question_type = questionType.yes_no;
        finalObj.district = this.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        finalObj.autotags = obj.autotags;
        break;
      case questionType.multiple_choice:
        finalObj.prompt = obj.text;
        finalObj.question_type = questionType.multiple_choice;
        finalObj.district = this.district.id;
        finalObj.is_legal = false;
        finalObj.is_district_default = false;
        finalObj.hint = '';
        finalObj.mc_options = obj.mc_options;
        finalObj.multi_select = obj.multi_select;
        break;
      case questionType.attachment:
        finalObj.question_type = questionType.attachment;
        finalObj.title = obj.text;
        finalObj.description = obj.description;
        break;
      case questionType.videoLink:
        finalObj.question_type = questionType.videoLink;
        finalObj.title = obj.text;
        finalObj.description = obj.description;
        break;
      case questionType.schoolPreferences:
        finalObj.question_type = questionType.schoolPreferences;
        finalObj.is_automatic_list = obj.is_automatic_list;
        finalObj.school_choices = obj.school_choices;
        break;
      case questionType.statementCheckbox:
        finalObj.question_type = questionType.statementCheckbox;
        finalObj.prompt = obj.text;
        finalObj.autotags = obj.autotags;
        break;
      default:
        // should never get here
        break;
    }

    return finalObj;
  };

  /** update the row. This function is also used to delete a row.*/
  updateQuestionRow = (localId, value, updateField) => {
    const jobQuestionsAndAttachmentsAndSets = [...this.state.jobQuestionsAndAttachmentsAndSets];

    let index = jobQuestionsAndAttachmentsAndSets.findIndex(
      question => question.localId === localId
    );

    if (index >= 0) {
      jobQuestionsAndAttachmentsAndSets[index][updateField] = value;
      this.setState({ jobQuestionsAndAttachmentsAndSets });
    }
  };

  deleteQuestionRow = localId => {
    const jobQuestionsAndAttachmentsAndSets = [...this.state.jobQuestionsAndAttachmentsAndSets];

    let index = jobQuestionsAndAttachmentsAndSets.findIndex(
      question => question.localId === localId
    );

    if (index >= 0) {
      jobQuestionsAndAttachmentsAndSets.splice(index, 1);
      this.setState({ jobQuestionsAndAttachmentsAndSets });
    }
  };

  shortenTitles = jb => {
    const title = jb.title;
    if (title === 'American Leadership Academy') {
      return 'ALA';
    } else if (title === 'Signature Preparatory') {
      return 'Signature Prep';
    } else if (title === 'Wake Preparatory Academy') {
      return 'Wake Preparatory';
    } else {
      return jb.internal_title ? jb.internal_title : jb.title;
    }
  };

  openMergeRoleModal = () => {
    this.setState({ mergeRoleModalOpen: true });
  };

  updateJobDate = (field, value) => {
    updateDate(this, 'job', field, value);
  };

  handleQuestionSetSelection = questionSetUUID => {
    const questionSet = this.state.questionSets.find(qs => qs.uuid === questionSetUUID);
    this.setState({ selectedQuestionSet: questionSet });
  };

  addQuestionSet = () => {
    /**
     * 1) for each item in question set, make a matching item for the role
     * 2) add each one to end of current jobQuestionsAndAttachmentsAndSets
     * 3) don't add item if:
     *    - it's a school preferences question and one is already on the role
     *    - it's a nimble question that is already on the role
     */
    if (!this.state.selectedQuestionSet?.items) {
      return;
    }

    const jobQuestionsAndAttachmentsAndSets = [...this.state.jobQuestionsAndAttachmentsAndSets];
    jobQuestionsAndAttachmentsAndSets.push({
      ...this.state.selectedQuestionSet,
      is_role_qs: false, //default is global qs
      localId: generateId(),
      is_qs: true,
    });

    this.setState({
      jobQuestionsAndAttachmentsAndSets,
      selectedQuestionSet: {},
    });
  };

  getItemText = item => {
    if (item.question_type === questionType.videoLink) {
      return item.video_url;
    } else if (item.question_type === questionType.nimble) {
      const question = this.props.questionBank.find(({ id }) => id === item.nimblequestion_id);
      return question?.question ?? '';
    } else {
      return item.prompt;
    }
  };

  setRoleType = roleType => {
    this.setState({
      roleType,
      errors: {},
    });
  };

  saveSelectedScorecards = customScorecardsSelected => {
    this.props.setCustomScorecardsSelected(customScorecardsSelected);
  };

  saveHRBPAssignment = hrbpAssignments => {
    const job = this.state.job;
    job.hrbp_assignments = hrbpAssignments;
    this.setState({ job });
  };

  handleSaveDraftClick = () => {
    this.saveDraft().then(() => {
      const myColor = { background: '#333333', text: '#F2F2F2' };

      if (this.state.job.description) {
        notify.show(`Draft saved`, 'success', 5000, myColor);
      }
    });
  };

  handleTagModalOpen = () => {
    this.setState({ shouldShowTagModal: true });
  };

  handleTagModalClose = () => {
    this.setState({ shouldShowTagModal: false });
  };

  handleUpdateJobWithTags = tags => {
    this.setState(previousState => ({
      ...previousState,
      job: {
        ...previousState.job,
        autotags: tags,
      },
    }));
  };

  handleOpenConvertModal = () => {
    this.setState({ isConvertModalOpen: true });
  };

  handleCloseConvertModal = () => {
    this.setState({ isConvertModalOpen: false });
  };

  handleOpenErrorSnackbar = () => {
    this.setState({ hasNetworkError: true });
  };

  handleCloseErrorSnackbar = () => {
    this.setState({ hasNetworkError: false });
  };

  render() {
    let { job } = this.state;
    // Schoolroles do not have statuses_available, so we have to check that they exist before
    // filtering on them. If they do not exist, nonDraftJobStatuses will remain undefined.
    let nonDraftJobStatuses;
    if (job.statuses_available) {
      nonDraftJobStatuses = job.statuses_available.filter(
        status => status.status_type !== appStatusType.draft
      );
    }
    let inSubmittedStatus = job.status === ROLESTATUS['Pending approval'];
    let showBackButton = inSubmittedStatus && auth.isDistrictAdmin();

    const unchosenNimbleQuestions = this.props.questionBank.filter(
      question =>
        !this.state.jobQuestionsAndAttachmentsAndSets
          .filter(chosenQuestion => chosenQuestion.question_type === questionType.nimble)
          .map(chosenQuestion => chosenQuestion.nimblequestion_id)
          .includes(question.id)
    );

    const hasAuthorizationToSeeTemplates = this.isDistrictAdmin && auth.hasTemplates();
    const inMergeState = inSubmittedStatus;
    const shouldShowBottomCTA = this.state.job.status !== ROLESTATUS['Pending approval'];
    const queryCacheAlerts = this.props?.queryCache ? this.props?.queryCache[0]?.alerts : [];

    const roleNotificationStatuses = queryCacheAlerts?.map(
      alert => alert.context.application_status_id
    );

    return (
      <div>
        <div className={`card mt2 jobedit-header-card ${showBackButton ? 'less-padding' : ''}`}>
          {showBackButton && <BackButton href="/district/jobslist">Back to jobs</BackButton>}
          <div className="flex" style={{ height: '48px' }}>
            <span className={'mt1 status-circle ' + this.props.statusColor + ' inline-block'} />
            <h3 className="mr1 flex-3">{this.props.pageTitle}</h3>
            <h4 className="mr1">{this.showGeneralErrors()}</h4>

            {auth.inPostingOnlyDistrict() && (
              <Link to={'/district/jobslist'} style={{ paddingRight: '19px' }}>
                <CancelButton disabled={this.props.activelySubmitting}>Cancel changes</CancelButton>
              </Link>
            )}
            {hasAuthorizationToSeeTemplates && (
              <>
                {inMergeState && (
                  <>
                    <SecondaryButton
                      onClick={this.openMergeRoleModal}
                      disabled={this.props.activelySubmitting}
                      sx={{ marginRight: '19px' }}
                    >
                      <MuiMergeIcon color="primary" />
                      Merge
                    </SecondaryButton>
                    <PrimaryButton onClick={this.handleOpenConvertModal}>
                      <AddIcon color="white" />
                      Convert to Job
                    </PrimaryButton>
                  </>
                )}
                {!inMergeState && (
                  <>
                    {this.renderDraftButton && (
                      <SecondaryButton
                        onClick={this.saveDraft}
                        disabled={this.props.activelySubmitting}
                        sx={{ marginRight: '19px' }}
                        size="large"
                      >
                        {this.props.onSaveLabel}
                      </SecondaryButton>
                    )}
                    <PrimaryButton
                      onClick={this.publishJob}
                      disabled={this.props.activelySubmitting}
                      dataTestId={ATSJobEditTestIds.SAVE_PREVIEW_BUTTON}
                    >
                      {this.props.onSubmitLabel}
                    </PrimaryButton>
                  </>
                )}
              </>
            )}
            {!hasAuthorizationToSeeTemplates && (
              <>
                {inSubmittedStatus && this.isDistrictAdmin && (
                  <GreyActionButton
                    onClick={this.openMergeRoleModal}
                    disabled={this.props.activelySubmitting}
                  >
                    <MuiMergeIcon color="white" />
                    &nbsp; Merge
                  </GreyActionButton>
                )}
                {this.renderDraftButton && (
                  <>
                    <GreyActionButton
                      onClick={this.saveDraft}
                      disabled={this.props.activelySubmitting}
                    >
                      {this.props.onSaveLabel}
                    </GreyActionButton>
                    <SubmitButton
                      onClick={this.publishJob}
                      disabled={this.props.activelySubmitting}
                    >
                      {this.props.onSubmitLabel}
                    </SubmitButton>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className="jobedit-container-padding">
          <Label>Job Title*</Label>
          <Input
            name="title"
            type="text"
            placeholder="eg. 2nd Grade Teacher"
            defaultValue={job.title || ''}
            onChange={this.updateField}
            hasError={this.state.errors.title}
            width={1}
            required
            autoFocus
          />
          <ErrorText message={this.state.errors.title} />

          {this.isDistrictAdmin && !auth.inPostingOnlyDistrict() && (
            <>
              <RoleTypeSelector
                job={this.state.job}
                updateField={this.updateField}
                roleType={this.state.roleType}
                setRoleType={this.setRoleType}
                hasError={!!this.state.errors.role_type}
              />
              <VisibilitySelector
                job={this.state.job}
                updateField={this.updateField}
                district={this.district}
              />
            </>
          )}

          {this.isDistrictAdmin && !auth.inPostingOnlyDistrict() && (
            <div className="statuses-available-section">
              <Label className="inline-block mr1">Status Workflow</Label>
              <CandidatesListFilterDrop
                displayName={getStatusesDisplayText(
                  nonDraftJobStatuses,
                  this.props.applicationStatuses
                )}
                options={this.props.applicationStatuses}
                values={job.statuses_available}
                placeholder="Candidate Statuses"
                updateMultiSelectFilter={this.updateStatusesAvailable}
                fieldName="statuses_available"
                selectAll={this.selectAllStatuses}
                clearAll={this.clearAllStatuses}
                isTemplateOrRolePage={true}
                queryCache={roleNotificationStatuses}
              />
              <ErrorText message={this.state.errors.subjects} />
            </div>
          )}

          {this.props.schools && this.isDistrictAdmin && (
            <div>
              <Label>Location(s)</Label>
              <LocationsGridContainer onClick={() => this.openModal('locationsModal')}>
                <Input
                  width={1}
                  style={{ backgroundColor: '#fff' }}
                  type="text"
                  placeholder="No locations selected"
                  value={this.renderSchoolLocations() || 'District Level'}
                  disabled
                  autoFocus
                />
                <SubmitButton
                  data-testid="locations-modal-open-button"
                  onClick={() => this.openModal('locationsModal')}
                >
                  Edit Locations
                </SubmitButton>
              </LocationsGridContainer>
              {this.state.locationsModal && (
                <LocationsModal
                  show={this.state.locationsModal}
                  onHide={() => this.setState({ locationsModal: false })}
                  onSave={this.onSaveLocations}
                  schoolOptions={this.props.schoolOptions}
                  locations={this.locationsObject()}
                  districtVacancies={job.ftes_total}
                />
              )}
            </div>
          )}
          {this.isDistrictAdmin && !auth.inPostingOnlyDistrict() && (
            <div>
              <Label className="inline-block mr1">HR/Onboarding Partner</Label>
              <img
                className="mbhalf"
                src={Tooltip}
                alt="tooltip"
                data-tip={`
                  Individual applications can be assigned to <br/>
                  Super/District Admin. These assignments are used to<br/>
                  filter and view individual team member portfolios.<br/>
                `}
                data-for="hrbp-assignments"
              />
              <ReactTooltip id="hrbp-assignments" effect="solid" multiline />
              <HRBPAssignment
                schools={this.props.schools}
                districtAndSuperAdmins={this.props.districtAndSuperAdmins}
                schoolOptions={this.props.schoolOptions || []}
                job={this.state.job}
                hasError={this.state.errors.hrbp_assignments || false}
                hrbpAssignments={this.state.job.hrbp_assignments}
                onSave={this.saveHRBPAssignment}
              />
            </div>
          )}
          {this.props.districtUsers &&
            this.props.districtUsers.length > 0 &&
            !auth.inPostingOnlyDistrict() && (
              <div>
                <Label>District Users</Label>
                <MultiSelect
                  border="0"
                  boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                  options={this.districtUserOptions()}
                  defaultValue={this.districtUserOptions().filter(district_user =>
                    job.district_users.includes(district_user.value)
                  )}
                  onChange={values => this.updateMultiSelect(values ?? [], 'district_users')}
                  placeholder="Start typing to add screeners to this role"
                />
              </div>
            )}
          {!auth.inPostingOnlyDistrict() &&
            this.props.districtAndSuperAdmins &&
            this.props.districtAndSuperAdmins.length > 0 && (
              <div>
                <Label className="inline-block mr1">Job Owners</Label>
                <img
                  className="mbhalf"
                  src={Tooltip}
                  alt="tooltip"
                  data-tip={`
                  Although Super Admins and District Admins can see<br />
                  all candidates for all jobs, you can optionally add<br />
                  them as owners for their core jobs to allow them to<br />
                  opt into notifications for the jobs they own.
                `}
                  data-for="job-owners"
                />
                <ReactTooltip id="job-owners" effect="solid" multiline />
                <MultiSelect
                  border="0"
                  boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                  options={this.districtAdminOptions()}
                  defaultValue={this.districtAdminOptions().filter(admin =>
                    job.owners.includes(admin.value)
                  )}
                  onChange={values => this.updateMultiSelect(values ?? [], 'owners')}
                  placeholder="Start typing to add owners to this role"
                />
              </div>
            )}

          {this.state.subcategories?.length > 0 && (
            <>
              <Label className="inline-block mr1">Category</Label>
              <img
                className="mbhalf"
                src={Tooltip}
                alt="tooltip"
                data-tip={`
                  These tags will inform the way your jobs are categorized<br />
                  for candidates on the external job posting list. District admins<br />
                  can also filter candidates by job category on the candidate list.
                `}
                data-for="category"
              />
              <ReactTooltip id="category" effect="solid" multiline />
              <MultiSelect
                border="0"
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                options={this.state.subcategories}
                defaultValue={this.state.subcategories.filter(subcategory =>
                  job.subjects?.includes(subcategory.value)
                )}
                onChange={values => this.updateMultiSelect(values ?? [], 'subjects')}
              />
              <ErrorText message={this.state.errors.subjects} />
            </>
          )}

          <Label>Grades</Label>
          <JobEditGrades
            grades={job.grades}
            onChange={values => this.updateMultiSelect(values, 'grades')}
          />
          <ErrorText message={this.state.errors.grades} />

          <JobDetails
            job={job}
            isDistrictAdmin={this.isDistrictAdmin}
            updateJob={job => this.setState({ job })}
            updateField={this.updateField}
            updateJobDate={this.updateJobDate}
            resetDate={this.resetDate}
            errors={this.state.errors}
            districtAndSuperAdmins={this.props.districtAndSuperAdmins}
          />

          {this.isDistrictAdmin && (
            <div>
              <Label>Job Description*</Label>
              <div className="form-field jobedit-textarea">
                <Editor
                  defaultValue={job.description || ''}
                  onChange={value => {
                    this.handleTextareaChange(value, 'description');
                  }}
                  placeholder={'What will they be doing?'}
                />
              </div>
              <ErrorText className="mt1" message={this.state.errors.job_description} />
              <Label>Benefits</Label>
              <div className="form-field jobedit-textarea mb4">
                <Editor
                  defaultValue={job.benefits || ''}
                  onChange={value => this.handleTextareaChange(value, 'benefits')}
                  placeholder={'Describe the benefits you offer.'}
                />
              </div>

              {!auth.inPostingOnlyDistrict() && (
                <>
                  <div className="job-section-component-outside mb4">
                    <JobSectionSelection
                      job={this.state.job}
                      updateJobSectionSettings={this.updateJobSectionSettings}
                      updateField={this.updateField}
                    />
                  </div>
                  <AddQuestionSet
                    selectedQuestionSet={this.state.selectedQuestionSet}
                    questionSets={this.state.questionSets}
                    handleQuestionSetSelection={this.handleQuestionSetSelection}
                    addQuestionSet={this.addQuestionSet}
                    jobQuestionsAndAttachmentsAndSets={this.state.jobQuestionsAndAttachmentsAndSets}
                  />
                  <QuestionsForCandidate
                    job={job}
                    jobQuestionsAndAttachmentsAndSets={this.state.jobQuestionsAndAttachmentsAndSets}
                    deleteQuestionRow={this.deleteQuestionRow}
                    openQuestionModal={this.openQuestionModal}
                    openQuestionSetModal={this.openQuestionSetModal}
                    handleQuestionSetEditOptions={this.handleQuestionSetEditOptions}
                    setItemOrder={jobQuestionsAndAttachmentsAndSets =>
                      this.setState({ jobQuestionsAndAttachmentsAndSets })
                    }
                  />
                  <AddIndividualItems
                    unchosenNimbleQuestions={unchosenNimbleQuestions}
                    openQuestionModal={this.openQuestionModal}
                    jobQuestionsAndAttachmentsAndSets={this.state.jobQuestionsAndAttachmentsAndSets}
                  />
                  {this.state.addQuestionModalOpen && (
                    <AddJobQuestionModal
                      show={this.state.addQuestionModalOpen}
                      onHide={() => this.closeQuestionModal()}
                      type={this.state.questionSelectionType}
                      addQuestion={this.saveQuestion}
                      internal_requirements_specified={
                        this.state.job.internal_requirements_specified
                      }
                    />
                  )}
                  {this.state.editQuestionModalOpen && (
                    <EditJobQuestionModal
                      show={this.state.editQuestionModalOpen}
                      onHide={() => this.closeQuestionModal()}
                      saveQuestion={this.saveQuestion}
                      question={this.getQuestionBeingEdited()}
                      internal_requirements_specified={
                        this.state.job.internal_requirements_specified
                      }
                    />
                  )}
                  {this.state.questionModalOpen && (
                    <JobQuestionModal
                      show={this.state.questionModalOpen}
                      // very strange, but without calling this way, onHide not recognized as being called by child.
                      onHide={() => this.closeQuestionModal()}
                      type={this.state.questionSelectionType}
                      questionData={this.getQuestionBeingEdited()}
                      questionBank={unchosenNimbleQuestions}
                      onSaveQuestion={this.saveQuestion}
                      internal_requirements_specified={
                        this.state.job.internal_requirements_specified
                      }
                      schools={this.props.schools}
                      schoolOptions={this.props.schoolOptions}
                      applicationStatuses={this.props.applicationStatuses}
                      handleTagModalOpen={this.handleTagModalOpen}
                      handleTagModalClose={this.handleTagModalClose}
                      shouldShowTagModal={this.state.shouldShowTagModal}
                    />
                  )}
                  {this.state.questionSetModalOpen && (
                    <QuestionSetModal
                      questionSets={this.state.questionSets}
                      setQuestionSets={updatedQs => this.setState({ questionSets: updatedQs })}
                      isOpen={this.state.questionSetModalOpen}
                      onClose={this.closeQuestionSetModal}
                      internalRequirementsSpecified={this.state.job.internal_requirements_specified}
                      questionSetToEdit={
                        this.state.questionSetUUIDToEdit &&
                        this.state.jobQuestionsAndAttachmentsAndSets.find(
                          q => q.uuid === this.state.questionSetUUIDToEdit
                        )
                      }
                      updateQuestionSetData={this.handleUpdateQuestionSetData}
                      // values below are used to specify RoleQuestionSet related changes
                      createLocalQuestionSet={this.state.isCreatingRoleQuestionSet}
                      roleID={this.state.isCreatingRoleQuestionSet && this.state.job.id}
                      roleTitle={this.state.job.title || ''}
                    />
                  )}
                </>
              )}
            </div>
          )}

          {/* Select Scorecards */}
          {!auth.inPostingOnlyDistrict() && this.isDistrictAdmin && (
            <EditJobSelectScorecard
              customScorecards={this.props.customScorecards}
              customScorecardsSelected={this.props.customScorecardsSelected}
              onSave={this.saveSelectedScorecards}
            />
          )}

          <Label>Internal Notes</Label>
          <div className="form-field jobedit-textarea" id="internal_notes">
            <InternalRoleNotes
              notes={this.state.job.internal_role_notes || []}
              setUnsubmittedInternalNote={newNote => (unsubmittedInternalNote = newNote)}
              addNote={this.addNote}
              updateNote={this.updateNote}
              adminUsers={this.state.adminUsers}
            />
          </div>

          {auth.inPostingOnlyDistrict() && (
            <>
              <Label>Where to apply*</Label>
              <SubLabelText>
                In the field below, provide the candidate with the URL to this job posting{' '}
                <strong>or to your careers page, or an email address</strong> where they can learn
                more and apply for this job
              </SubLabelText>
              <Input
                id="jobedit_link_to_ats"
                name="link_to_ats"
                type="text"
                placeholder="Where the candidate will apply"
                value={job.link_to_ats}
                onChange={this.updateField}
                width={1}
                hasError={
                  !!(this.state.errors.link_to_ats || this.props.apiResponseErrors.link_to_ats)
                }
                required
              />
              <ErrorText
                message={this.state.errors.link_to_ats || this.props.apiResponseErrors.link_to_ats}
              />
            </>
          )}
          {this.isDistrictAdmin && this.props.jobBoardOptions.length > 1 && (
            <div>
              <Label>Publish to Job Board</Label>
              <div className="publish-externally jobboards">
                <div className="flex">
                  {this.props.jobBoardOptions.map(jb => {
                    return (
                      <label className="mr1" key={jb.id}>
                        <input
                          type="checkbox"
                          name="jobboard"
                          value={Number(jb.id)}
                          checked={!!this.state.job.jobboards.find(j => j.id === jb.id)}
                          onChange={this.updateJobBoard}
                        />
                        <span>{this.shortenTitles(jb)}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* required uploads goes here TODO: backend*/}
        {shouldShowBottomCTA && (
          <div className="card mt2 jobedit-header-card bottom flex">
            {/* only add delete button if job posting is a draft */}
            {this.props.job.status === 0 && (
              <DeleteButton onClick={() => this.openModal('deleteModal')}>
                Delete Draft
              </DeleteButton>
            )}
            {this.state.deleteModal && (
              <DeleteModal
                show={this.state.deleteModal}
                onHide={() => this.setState({ deleteModal: false })}
                onSave={this.onSaveDeletion}
                title={'Confirm'}
                message={'Are you sure you want to delete this posting?'}
                cancelLabel={'Cancel'}
                submitLabel={'Delete'}
              />
            )}
            <div className="flex-3" />
            <h4 className="mr1">{this.showGeneralErrors()}</h4>
            {auth.inPostingOnlyDistrict() && (
              <Link to={'/district/jobslist'}>
                <TextButton disabled={this.props.activelySubmitting} sx={{ height: '100%' }}>
                  Cancel changes
                </TextButton>
              </Link>
            )}
            {this.renderDraftButton() && (
              <GreyActionButton onClick={this.saveDraft}>{this.props.onSaveLabel}</GreyActionButton>
            )}
            <SubmitButton onClick={this.publishJob}>{this.props.onSubmitLabel}</SubmitButton>
          </div>
        )}
        {this.state.mergeRoleModalOpen && (
          <MergeRoleModal
            shouldShow={this.state.mergeRoleModalOpen}
            onHide={() => this.setState({ mergeRoleModalOpen: false })}
            roleBeingMerged={job}
          />
        )}
        {this.state.isConvertModalOpen && (
          <CreateJobOrTemplateModal
            handleModalClose={this.handleCloseConvertModal}
            shouldShowModal={this.state.isConvertModalOpen}
            handleSetNetworkErrorAlert={this.handleOpenErrorSnackbar}
            variant="request"
            requestId={this.state.job.id}
            updateJobStatusAfterConverting={this.props.updateJobStatusAfterConverting}
          />
        )}
        <SuccessSnackbar
          message="Success! Job was created"
          open={this.props.wasSuccessful}
          onClose={this.props.handleCloseSuccessToast}
        />
        <ErrorSnackbar
          message="Something went wrong"
          open={this.state.hasNetworkError}
          onClose={this.handleCloseErrorSnackbar}
        />
      </div>
    );
  }
}

export default withJobClosureNotificationsCache(JobEdit);

export const GreyActionButton = styled.button`
  background-color: #cacaca;
  font-size: 14px;
  margin-right: 16px;
  float: right;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0 20px;
  height: 50px;
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  ${props => (props.disabled ? 'opacity: 0.7' : '')};
`;

const CancelButton = styled(GreyActionButton)`
  background-color: #ffffff;
  color: #777777;
  padding: 0;
`;

export const SubmitButton = styled(GreyActionButton)`
  margin-right: 0;
  background-color: #00b88d;
`;

const DeleteButton = styled(GreyActionButton)`
  margin-right: 0;
  background-color: #ef5675;
`;

const Label = styled.h5`
  margin: 3rem 0 0.8em;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;

const SubLabelText = styled.div`
  margin: 0 0 0.8em;
  font-size: 14px;
  color: #444;
`;

const LocationsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 139px;
  grid-gap: 16px;
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: #9c9da4;
  display: flex;
  font-size: 16px;
`;

import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import iconPlus from '../../../assets/icon-plus.svg';

import { momentOr, isCardBlank } from '../../../utils/util';
import ExpandedInput from './ExpandedInput';
import { ATSCandidateExperienceDataTestIds } from '../../../data-testids/ATS';

export default class WorkExperienceInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experiences: props.experiences,
      isAdding: false,
    };

    this.isCardBlank = isCardBlank.bind(this);
  }

  static propTypes = {
    experiences: PropTypes.object.isRequired,
    freshObject: PropTypes.func.isRequired,
    autosave: PropTypes.func.isRequired,
    setTotalWorkExperienceYears: PropTypes.func.isRequired,
    setWorkExperienceEdited: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ experiences: nextProps.experiences });
  }

  handleAddRow = (experienceObject, index) => {
    let copyExperiences = Object.assign({}, this.state.experiences);
    experienceObject.isEditing = false;

    if (index !== null) {
      copyExperiences.list.splice(index, 1, experienceObject);
    } else {
      copyExperiences.list.push(experienceObject);
    }

    this.setState(
      {
        experiences: copyExperiences,
        isAdding: false,
      },
      () => {
        this.props.setWorkExperienceEdited();
        this.props.setTotalWorkExperienceYears();
        this.props.autosave();
      }
    );
  };

  handleEditRow = index => {
    const experiences = { ...this.state.experiences };
    experiences.list[index].isEditing = true;
    this.setState({ experiences }, this.props.setWorkExperienceEdited);
  };

  handleRemoveRow = (i, e) => {
    e.stopPropagation();
    let copyExperiences = Object.assign({}, this.state.experiences);
    copyExperiences.list.splice(i, 1);
    this.setState({ experiences: copyExperiences }, () => {
      this.props.setWorkExperienceEdited();
      this.props.setTotalWorkExperienceYears();
      this.props.autosave();
    });
  };

  handleClick = index => {
    this.handleEditRow(index);
  };

  onCancel = index => {
    const experiences = { ...this.state.experiences };
    experiences.list[index].isEditing = false;
    this.setState({ experiences });
  };

  render() {
    return (
      <div>
        {this.state.experiences.list.map((row, i) => {
          if (row.isEditing) {
            return (
              <ExpandedInput
                key={i}
                index={i}
                row={row}
                handleAddRow={this.handleAddRow}
                onCancel={this.onCancel}
              />
            );
          } else {
            const presentlyEmployedOrBlank = row.presently_employed ? 'Present' : '';
            return (
              <AddedRow key={i} onClick={() => this.handleClick(i)} hasError={row.hasError}>
                <span className="block flex-1 bold">
                  <h4>{row['organization'] || ''}</h4>
                  <p>{row['title'] || ''}</p>
                </span>
                <span className="flex-1">
                  {row['start_date']
                    ? `${momentOr(row['start_date'], 'M/D/YYYY', '')} - ${momentOr(
                        row['end_date'],
                        'M/D/YYYY',
                        presentlyEmployedOrBlank
                      )}`
                    : `${momentOr(row['end_date'], 'M/D/YYYY', presentlyEmployedOrBlank)}`}

                  {row['reason_for_leaving'] ? (
                    <div className="truncate-text">
                      Reason for leaving job: {row['reason_for_leaving']}
                    </div>
                  ) : null}
                </span>
                <div className="pointer">
                  <span
                    className="mr1"
                    data-testid={ATSCandidateExperienceDataTestIds.EDIT_EXPERIENCE_BUTTON}
                  >
                    Edit
                  </span>
                  <span onClick={e => this.handleRemoveRow(i, e)}>Delete</span>
                </div>
              </AddedRow>
            );
          }
        })}
        {this.state.isAdding ? (
          <ExpandedInput
            index={null}
            row={this.props.freshObject()}
            handleAddRow={this.handleAddRow}
            onCancel={() => this.setState({ isAdding: false })}
          />
        ) : (
          <div
            className="add-btn"
            id="work-experience-add-button"
            data-testid={ATSCandidateExperienceDataTestIds.ADD_WORK_EXPERIENCE_BUTTON}
            onClick={() => this.setState({ isAdding: true })}
          >
            <img src={iconPlus} alt="Icon" />
            Add Entry
          </div>
        )}
      </div>
    );
  }
}

const AddedRow = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
  height: 100%;
  padding: 15px 20px;
  background-color: #fff;
  background-color: ${({ hasError }) => (hasError ? 'rgba(239, 86, 117, 0.1)' : '#fff')};
  border: 1px solid ${({ hasError }) => (hasError ? '#cc0033' : '#d7d7d7')};
  color: #909090;
  border-radius: 3px;
  margin-top: 1em;
`;

AddedRow.displayName = 'AddedRow';

import Stack from '@mui/material/Stack';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { nimbleTheme } from 'theme';
import { ConnectProfileFlowButtonGroupProps } from 'types/marketplaceV2Types';

export function ConnectProfileFlowButtonGroup({
  secondaryButton,
  primaryButton,
  hasError,
  dataTestId,
}: ConnectProfileFlowButtonGroupProps): React.ReactElement {
  return (
    <>
      {secondaryButton ? (
        <Stack direction="row" sx={{ margin: '0px 15px' }}>
          <SecondaryButton
            startIcon={<ArrowBackIcon fontSize="large" />}
            onClick={secondaryButton.secondaryAction}
            dataTestId={`${dataTestId}-secondary-button`}
            sx={{
              width: '50%',
              padding: '16px 28px',
              marginTop: 4,
              marginRight: 4,
              color: 'text.main',
              border: '1px solid #D0D5DD',
              borderRadius: '12px',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {secondaryButton.secondaryButtonLabel}
          </SecondaryButton>
          <PrimaryButton
            dataTestId={`${dataTestId}-primary-button`}
            onClick={primaryButton.primaryAction}
            isLoading={primaryButton.isLoading ? true : false}
            disabled={primaryButton.disabled}
            endIcon={<ArrowForwardIcon fontSize="large" />}
            loadingSpinnerColor="secondary"
            sx={{
              width: '50%',
              padding: '16px 28px',
              marginTop: 4,
              borderRadius: '12px',
              backgroundColor: nimbleTheme.palette.primary.main,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {primaryButton.primaryButtonLabel}
          </PrimaryButton>
        </Stack>
      ) : (
        <PrimaryButton
          dataTestId={`${dataTestId}-primary-button`}
          disabled={primaryButton.disabled}
          isLoading={primaryButton.isLoading ? true : false}
          size="large"
          loadingSpinnerColor="secondary"
          sx={{
            backgroundColor: hasError ? '#BDBDBD' : nimbleTheme.palette.primary.main,
            width: '100%',
            marginTop: 4,
            padding: '12px 28px',
            borderRadius: '12px',
            fontFamily: nimbleTheme.typography.fontFamily['Inter'],
            fontWeight: 600,
            fontSize: 16,
          }}
          endIcon={<ArrowRightAltIcon fontSize="large" />}
          onClick={primaryButton.primaryAction}
        >
          {primaryButton.primaryButtonLabel}
        </PrimaryButton>
      )}
    </>
  );
}
